//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WiderImplicationsPov from './elements/WiderImplicationsPov.vue';
import WiderImplicationsIncl from './elements/WiderImplicationsIncl.vue';
import WiderImplicationsViolence from './elements/WiderImplicationsViolence.vue';
import WiderImplicationsPovIncl from './elements/WiderImplicationsPovIncl.vue';
import WiderImplicationsPovInclViolence from './elements/WiderImplicationsPovInclViolence.vue';
import WiderImplicationsPovViolence from './elements/WiderImplicationsPovViolence.vue';
import WiderImplicationsInclViolence from './elements/WiderImplicationsInclViolence.vue';
import WiderImplicationsNone from './elements/WiderImplicationsNone.vue';

export default {
    name: 'WiderImplications',
    components: {
        'wider-implications-pov': WiderImplicationsPov,
        'wider-implications-incl': WiderImplicationsIncl,
        'wider-implications-violence': WiderImplicationsViolence,
        'wider-implications-pov-incl': WiderImplicationsPovIncl,
        'wider-implications-pov-incl-violence': WiderImplicationsPovInclViolence,
        'wider-implications-pov-violence': WiderImplicationsPovViolence,
        'wider-implications-incl-violence': WiderImplicationsInclViolence,
        'wider-implications-none': WiderImplicationsNone
    },
    data(){
        return {
            risks: this.$store.state.multipliers_risks,
        };
    },
    computed: {
        prevSection(){
            if (this.$store.state.proposalCategorisation.isCompleted === false){
                return false;
            } else {
                return true;
            }
        }
    }
};
