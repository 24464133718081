//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios';
import SolutionItem from './SolutionItem';

export default {
    name: 'SolutionsList',
    components: {
        SolutionItem
    },
    props: {
        proposals: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            page: 1,
            loading: false,
            local_proposals: [],
            page_total: 999
        };
    },
    computed: {
        hasMore: function() {
            if (
                (this.proposals.current_page >= this.proposals.last_page) // If inital props are at last page
                || (this.page >= this.page_total) // If API response is at last page
            ) {
                return false;
            }

            return true;
        }
    },
    mounted() {
        this.local_proposals = this.proposals.data;
    },
    methods: {
        loadMore() {
            this.loading = true;
            this.page = this.page + 1;
            let query = '?';

            if (this.$route.query) {

                const queryEntries = Object.entries(this.$route.query);

                for (const [key, val] of queryEntries) {
                    query += `${key}=${val}&`;
                }
            }

            query += `page=${this.page}`;

            axios.get(`/api/proposal${query}`)
                .then(res => {
                    this.loading = false;
                    this.page_total = res.data.last_page;
                    this.local_proposals = [
                        ...this.local_proposals,
                        ...res.data.data
                    ];
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err);
                });
        }
    }
};
