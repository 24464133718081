import Vue from 'vue';
import Vuex from 'vuex';
import * as mutations from './mutations';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isPopupVisible: false,
        isFormSubmitted: false,
        aboutYou: {
            isCompleted: false,
        },
        proposal: {
            isCompleted: false,
        },
        proposalCategorisation: {
            isCompleted: false,
        },
        theoryOfChange: {
            isCompleted: false,
        },
        mitigatingRisks: {
            isCompleted: false,
        },
        widerImplications: {
            isCompleted: false,
        },
        proposalInDetail: {
            isCompleted: false,
        },
        start_submitting_form: false,
        email: '',
        is_anonymous: false,
        first_name: '',
        last_name: '',
        middle_name: '',
        is_organisation: true,
        organisation_name: '',
        title: '',
        description: '',
        catastrophic_risks: {
            climate_change: false,
            eco_system_collapse: false,
            pandemics: false,
            weapons: false,
            emerging_technology: false,
            unknown_risks: false
        },
        multipliers_risks: {
            conflict: false,
            institutions: false,
            poverty: false,
        },
        term: '',
        theory_of_change: {
            implementation_strategy: '',
            political_will:'',
            political_will_does_not_exist:'',
            existing_road_maps: '',
            decision_makers: '',
            why_long_terms: ''
        },
        mitigating: {
            climate_change: '',
            eco_system_collapse: '',
            pandemics: '',
            weapons: '',
            emerging_technology: '',
            unknown_risks: ''
        },
        wider_implications: {
            alleviate_poverty_and_inequality:'',
            reducing_inclusivity_and_accountability_in_national:'',
            increasing_conflict_and_political_violence:'',
            enhance_inclusivity_and_accountability_in_national: '',
            increasing_poverty_and_inequality: '',
            reduce_conflict_and_political_violencey: ''
        },
        details:'',
        is_confirm: false,
        shoutbox: '',
        shoutbox_name: '',
        shoutbox_location: '',
    },
    mutations,
    getters: {}
});
