var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "textarea__block" }, [
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.textareaValue,
          expression: "textareaValue"
        }
      ],
      staticClass: "form__textarea__value",
      class: { "max-limit": _vm.isOverMax },
      attrs: { rows: _vm.rows },
      domProps: { value: _vm.textareaValue },
      on: {
        input: [
          function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.textareaValue = $event.target.value
          },
          function($event) {
            return _vm.$emit("input", _vm.textareaValue)
          }
        ],
        keydown: _vm.onKeyDown
      }
    }),
    _vm._v(" "),
    _c("span", { staticClass: "form__input__count" }, [
      _vm._v("You have " + _vm._s(_vm.remaining) + " words remaining")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }