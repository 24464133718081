//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        categoryList: {
            type: Array,
            required: true,
        },
        timeList: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            filter: {
                category: '',
                time: '',
            },
            overlay_visible: false
        };
    },
    computed: {
        filterContent: function() {
            return {
                categories: [
                    {
                        label: 'All categories',
                        value: ''
                    },
                    ...this.categoryList
                ],
                times: [
                    {
                        label: 'All',
                        value: ''
                    },
                    ...this.timeList
                ]
            };
        },
        queryString: function() {
            let query = {};

            if (this.filter.category) {
                query.category = this.filter.category;
            } else {
                delete query.category;
            }

            if (this.filter.time) {
                query.time = this.filter.time;
            } else {
                delete query.time;
            }

            // build query string
            if (Object.keys(query).length > 0) {
                let queryString = '?';
                for (const [key, val] of Object.entries(query)) {
                    queryString += `${key}=${val}&`;
                }

                return queryString;
            }

            return '';
        }
    },
    mounted() {
        if (this.$route.query) {
            const queryEntries = Object.entries(this.$route.query);

            for (const [key, val] of queryEntries) {
                if (key === 'category') {
                    let categoryVal = this.categoryList.find(category => category.value === val);

                    // Set the category value
                    this.filter.category = categoryVal.value;
                }

                if (key === 'time') {
                    let timeVal = this.timeList.find(time => time.value === val);

                    // Set the category value
                    this.filter.time = timeVal.value;
                }
            }
        }
    },
    methods: {
        overlayToggle: function() {
            document.body.classList.toggle('menu-open');
            this.overlay_visible = !this.overlay_visible;
        },
        explore: function() {
            if (this.queryString.length > 0) {
                window.location = `/proposals${this.queryString}`;
            } else {
                window.location = '/proposals';
            }
        },
        cancelFilters: function() {
            this.overlayToggle();
            if (this.queryString.length > 0) {
                window.location = '/proposals';
            }
        }
    }
};
