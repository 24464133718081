//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from 'vform';
import toastr from 'toastr';
export default {
    props: {
        buttonText: {
            type: String,
            default: 'Sign me up'
        }
    },
    data() {
        return {
            form: new Form({
                name: '',
                email: '',
            })
        };
    },
    methods: {
        subscribe() {
            this.form.post('/api/subscribe')
                .then(({ data }) => {
                    if (data && data.success) {
                        toastr.success('You have subscribed successfully.');
                        this.form.reset();
                    } else {
                        toastr.error('Something went wrong.');
                    }
                });
        }
    }
};
