var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Group_2",
        "data-name": "Group 2",
        xmlns: "http://www.w3.org/2000/svg",
        width: "59.089",
        height: "59.092",
        viewBox: "0 0 59.089 59.092"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "Fill_190",
          "data-name": "Fill 190",
          d:
            "M15.065,13.969,6.67,3.479V0H4.764V2.858H1.9V4.764H5.261l6.359,7.952-3.138.626H6.175L2.649,8.935,1.162,10.126l2.574,3.216H0v1.906H8.672Z",
          transform: "translate(1.907 7.627)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Fill_192",
          "data-name": "Fill 192",
          d:
            "M3.431,12.786,9.668,19.02l1.18-4.723h.309l.91,8.2,4.708,2.824v2.32H18.68V14.3l-3.5-12.389h2.816l3.258,9.774,4.333-6.5,2.322,1.74-5.414,9.018V27.245l-1.628,1.628,1.348,1.348,7.9-7.9V15.25H28.211v6.276L24.4,25.339V16.467L30.424,6.425l-5.3-3.98L21.829,7.383,19.367,0H12.652l4.122,14.431V23.1l-2.916-1.751-1-8.957H9.36l-.728,2.9-3.295-3.3V5.943l1.806-3.61L5.438,1.48l-1.4,2.789L1.144,2.1,0,3.622,3.431,6.2Z",
          transform: "translate(8.005 0)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Fill_194",
          "data-name": "Fill 194",
          d:
            "M.8,14.787,1.979,14.2A10.657,10.657,0,0,0,7.453,7.6,20.06,20.06,0,0,0,8.224,2.09l0-2.09H6.321V2.09a18.2,18.2,0,0,1-.7,4.986A8.764,8.764,0,0,1,2.3,11.787l-.2-1.361a19.06,19.06,0,0,1,.377-7.335L.628,2.629A20.95,20.95,0,0,0,.212,10.7Z",
          transform: "translate(39.426 8.579)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Fill_196",
          "data-name": "Fill 196",
          d:
            "M0,11.08H6.114l4.091-4.091L8.857,5.64,5.322,9.174H1.942A12.7,12.7,0,0,1,4.672,2.235l.836-1.044L4.021,0,3.185,1.044A14.591,14.591,0,0,0,0,10.127Z",
          transform: "translate(47.654 10.843)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Fill_198",
          "data-name": "Fill 198",
          d:
            "M49.56,2.411l2.7.451.313-1.88L46.7,0,40.551,1.025,30.5,10.074V28.96l4.6,5.366H19.07a21.554,21.554,0,0,0,3.8-12.209V6.687H20.968v1.3L14.726.966H8.233L2.249,5.951,3.47,7.417,5.12,6.04l.61,3.66,1.88-.311L6.817,4.626,8.923,2.874h4.944l7.1,7.986V22.116a19.625,19.625,0,0,1-4.283,12.209H0v1.906H51.467V34.325H37.609l-5.2-6.071V10.923l9.009-8.109,5.288-.88.952.161V5.258L51.848,8.4,52.991,6.88,49.561,4.3Z",
          transform: "translate(3.812 22.861)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Fill_200",
          "data-name": "Fill 200",
          d: "M0,1.907H1.906V0H0Z",
          transform: "translate(57.184 57.185)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Fill_202",
          "data-name": "Fill 202",
          d: "M0,1.907H1.906V0H0Z",
          transform: "translate(0 57.185)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Fill_203",
          "data-name": "Fill 203",
          d: "M0,5.719H1.906V0H0Z",
          transform: "translate(30.498 36.218)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Fill_204",
          "data-name": "Fill 204",
          d: "M0,1.906H1.906V0H0Z",
          transform: "translate(30.498 43.845)"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Fill_205",
          "data-name": "Fill 205",
          d: "M0,5.718H1.906V0H0Z",
          transform: "translate(27.638 49.561)"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }