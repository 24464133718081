var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form__section" }, [
    _c("h3", { staticClass: "form__section__title main-heading" }, [
      _vm._v("\n        Mitigating global catastrophic risks\n    ")
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.prevSection === false,
            expression: "prevSection === false"
          }
        ],
        staticClass: "form__section__empty margin-b-74"
      },
      [
        _c(
          "p",
          { staticClass: "form__section__tip" },
          [
            _vm._v(
              "\n            These questions are dependent upon your answers to the questions within the "
            ),
            _c(
              "router-link",
              {
                staticClass: "form-link",
                attrs: { to: "/proposal/form/proposal-categorisation" }
              },
              [
                _vm._v(
                  "\n                Proposal categorisation\n            "
                )
              ]
            ),
            _vm._v(" section.\n        ")
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0)
      ]
    ),
    _vm._v(" "),
    _vm.prevSection
      ? _c("div", { staticClass: "form__section__mitigating-risks" }, [
          _vm.$store.state.catastrophic_risks.climate_change
            ? _c("div", { staticClass: "form_mitigating" }, [
                _c("h4", { staticClass: "form__section__title" }, [
                  _vm._v(
                    "\n                How would this proposal mitigate climate change?\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "form__label margin-b-74",
                    attrs: { for: "mitigating-risk-climate" }
                  },
                  [
                    _c("textarea-block", {
                      attrs: {
                        id: "mitigating-risk-climate",
                        "max-words": 300,
                        rows: 10
                      },
                      model: {
                        value: _vm.mitigatingRiskClimate,
                        callback: function($$v) {
                          _vm.mitigatingRiskClimate = $$v
                        },
                        expression: "mitigatingRiskClimate"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.catastrophic_risks.pandemics
            ? _c("div", { staticClass: "form_mitigating" }, [
                _c("h4", { staticClass: "form__section__title" }, [
                  _vm._v(
                    "\n                How would this proposal mitigate pandemics and anti-microbial resistance?\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "form__label margin-b-74",
                    attrs: { for: "mitigating-risk-pandemics" }
                  },
                  [
                    _c("textarea-block", {
                      attrs: {
                        id: "mitigating-risk-pandemics",
                        "max-words": 300,
                        rows: 10
                      },
                      model: {
                        value: _vm.mitigatingRiskPandemics,
                        callback: function($$v) {
                          _vm.mitigatingRiskPandemics = $$v
                        },
                        expression: "mitigatingRiskPandemics"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.catastrophic_risks.eco_system_collapse
            ? _c("div", { staticClass: "form_mitigating" }, [
                _c("h4", { staticClass: "form__section__title" }, [
                  _vm._v(
                    "\n                How would this proposal mitigate eco-system collapse?\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "form__label margin-b-74",
                    attrs: { for: "mitigating-risk-ecosystem" }
                  },
                  [
                    _c("textarea-block", {
                      attrs: {
                        id: "mitigating-risk-ecosystem",
                        "max-words": 300,
                        rows: 10
                      },
                      model: {
                        value: _vm.mitigatingRiskEcosystem,
                        callback: function($$v) {
                          _vm.mitigatingRiskEcosystem = $$v
                        },
                        expression: "mitigatingRiskEcosystem"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.catastrophic_risks.weapons
            ? _c("div", { staticClass: "form_mitigating" }, [
                _c("h4", { staticClass: "form__section__title" }, [
                  _vm._v(
                    "\n                How would this proposal mitigate weapons of mass destruction?\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "form__label margin-b-74",
                    attrs: { for: "mitigating-risk-weapons" }
                  },
                  [
                    _c("textarea-block", {
                      attrs: {
                        id: "mitigating-risk-weapons",
                        "max-words": 300,
                        rows: 10
                      },
                      model: {
                        value: _vm.mitigatingRiskWeapons,
                        callback: function($$v) {
                          _vm.mitigatingRiskWeapons = $$v
                        },
                        expression: "mitigatingRiskWeapons"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.catastrophic_risks.emerging_technology
            ? _c("div", { staticClass: "form_mitigating" }, [
                _c("h4", { staticClass: "form__section__title" }, [
                  _vm._v(
                    "\n                How would this proposal mitigate the threat from new and emerging technology?\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "form__label margin-b-74",
                    attrs: { for: "mitigating-risk-technology" }
                  },
                  [
                    _c("textarea-block", {
                      attrs: {
                        id: "mitigating-risk-technology",
                        "max-words": 300,
                        rows: 10
                      },
                      model: {
                        value: _vm.mitigatingRiskTechnology,
                        callback: function($$v) {
                          _vm.mitigatingRiskTechnology = $$v
                        },
                        expression: "mitigatingRiskTechnology"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.catastrophic_risks.unknown_risks
            ? _c("div", { staticClass: "form_mitigating" }, [
                _c("h4", { staticClass: "form__section__title" }, [
                  _vm._v(
                    "\n                How would this proposal contribute to creating resilient, capable and 'on alert' multilateral system of global governance\n                institutions able and willing to act promptly and effectively in response to the emergence of new (currently unknown) risks?\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "form__label margin-b-74",
                    attrs: { for: "mitigating-risk-unknown-inner" }
                  },
                  [
                    _c("textarea-block", {
                      attrs: {
                        id: "mitigating-risk-unknown-inner",
                        "max-words": 300,
                        rows: 10
                      },
                      model: {
                        value: _vm.mitigatingRiskUnknown,
                        callback: function($$v) {
                          _vm.mitigatingRiskUnknown = $$v
                        },
                        expression: "mitigatingRiskUnknown"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "form__section__button",
              class: { inactive: _vm.isDisabledMR },
              on: {
                click: function($event) {
                  return _vm.completeStarterSection()
                }
              }
            },
            [_vm._v("\n            Continue "), _c("arrow-right")],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "form__section__tip--attention" }, [
      _c("span", { staticClass: "icon--form-attention" }),
      _vm._v(
        "\n            Please complete all questions within Proposal categorisation\n        "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }