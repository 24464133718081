//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Modal',
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
