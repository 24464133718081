class Tabs {
    constructor() {
        window.addEventListener('load', function() {
            // store tabs variable
            var myTabs = document.querySelectorAll('ul.tabs-primary > li > a');
            function myTabClicks(tabClickEvent){
                for (let i = 0; i < myTabs.length; i++) {
                    myTabs[i].classList.remove('tabs-primary__active');
                }

                var clickedTab = tabClickEvent.currentTarget;
                clickedTab.classList.add('tabs-primary__active');
                tabClickEvent.preventDefault();
                var myContentPanes = document.querySelectorAll('.tab-pane');

                for (let i = 0; i < myContentPanes.length; i++) {
                    myContentPanes[i].classList.remove('active-tab');
                }

                var anchorReference = tabClickEvent.target;
                var activePaneId = anchorReference.getAttribute('href');
                var activePane = document.querySelector(activePaneId);
                activePane.classList.add('active-tab');

            }

            for (let i = 0; i < myTabs.length; i++) {
                myTabs[i].addEventListener('click', myTabClicks);
            }
        });



    }
}

export default Tabs;
