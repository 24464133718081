class MainMenu {
    constructor() {
        const menu = document.querySelector('.nav__list');
        const menuToggle = document.querySelector('.nav-toggle');
        const btnLang = document.querySelector('.open-lang');
        const menuLang = document.querySelector('.js-lang-list');
        const btnAbout = document.querySelector('.js-about');
        const menuAbout = document.querySelector('.js-about-menu');

        window.addEventListener('load', () => {
            toggleMobileMenu();
            toggleMobileLang();
            toggleMenuAbout();
        });

        function toggleMobileMenu() {
            if (menuToggle){
                menuToggle.addEventListener('click', (event) => {
                    event.preventDefault();
                    menuToggle.classList.toggle('menu-open');
                    menu.classList.toggle('menu-opened');

                    if (menu.classList.contains('menu-opened')) {
                        document.body.classList.add('menu-open');
                    } else {
                        document.body.classList.remove('menu-open');
                    }
                });
            }
        }

        function toggleMobileLang() {
            if (btnLang){
                btnLang.addEventListener('click', (event) => {
                    event.preventDefault();
                    btnLang.classList.toggle('menu-open');
                    menuLang.classList.toggle('menu-opened');
                    menuAbout.classList.remove('menu-opened');
                });
            }
        }

        function toggleMenuAbout() {
            if (btnAbout) {
                btnAbout.addEventListener('click', () => {
                    menuLang.classList.remove('menu-opened');
                    menuAbout.classList.toggle('menu-opened');
                });
            }
        }
    }
}

export default MainMenu;
