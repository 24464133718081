import Glide from '@glidejs/glide/dist/glide.esm';
import ArrowDisabler from './ArrowDisabler';

export default class LinkedProposalsSlider {
    constructor() {
        this.classes = {
            'controls': 'linked-proposals__navs',
            'backArrow': 'linked-proposals__arrow--left',
            'nextArrow': 'linked-proposals__arrow--right',
        };
        this.init();
    }

    init() {
        let slider = document.querySelector('.js-linked-proposals');
        if (slider) {
            let startAt = slider.dataset.startIndex;
            let linkedProposals = new Glide('.js-linked-proposals', {
                type: 'slider',
                startAt: 0 + startAt,
                perView: 3,
                rewind: false,
                breakpoints: {
                    768: { perView: 2 },
                    520: { perView: 1 },
                },
            });

            linkedProposals.mount({ArrowDisabler});
        }
    }
}
