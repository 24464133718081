//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArrowRight from './assets/ArrowRight.vue';
import TextareaBlock from './elements/TextareaBlock.vue';
import axios from 'axios';

export default {
    name: 'ProposalInDetail',
    components: {
        'arrow-right': ArrowRight,
        'textarea-block': TextareaBlock
    },
    data(){
        return {
            id_upload_empty: false,
            is_submit_visible: true,
            name: '',
            description: '',
            output: '',
            inactive_submit: false,
            files: '',
            fd: new FormData(),
            invalid_pid: false
        };
    },
    computed: {
        legalStatementState: {
            get(){
                return this.$store.state.is_confirm;
            },
            set(value){
                this.$store.commit('IN_DETAIL_LEGAL_STATEMENT', value);
            }
        },
        proposalInDetailFull: {
            get() {
                return this.$store.state.details;
            },
            set(value) {
                this.$store.commit('IN_DETAIL_FULL_PROPOSAL', value);
            }
        },
        isDisabled(){
            let state = this.$store.state;
            let aboutYou = state.aboutYou.isCompleted;
            let proposal = state.proposal.isCompleted;
            let proposalCategorisation = state.proposalCategorisation.isCompleted;
            let theoryOfChange = state.theoryOfChange.isCompleted;
            let mitigating = state.mitigatingRisks.isCompleted;
            let implications = state.widerImplications.isCompleted;

            if (this.legalStatementState === false){
                return true;
            } else if (state.details === '' || this.invalid_pid){
                return true;
            } else if (aboutYou === false ||
                proposal === false ||
                proposalCategorisation === false ||
                theoryOfChange === false ||
                mitigating === false ||
                implications === false){
                return true;
            } else {
                return false;
            }
        }
    },
    mounted() {
        this.chooseUpload();
    },
    methods: {
        chooseUpload(){
            var inputs = document.querySelectorAll( '.inputfile' );
            let self = this;
            Array.prototype.forEach.call( inputs, function( input ) {
                var label	 = input.nextElementSibling,
                    labelVal = label.innerHTML;

                input.addEventListener( 'change', function( e ) {
                    var fileName = '';
                    self.id_upload_empty = true;
                    for ( var i = 0; i < this.files.length; i++ ){
                        let file = this.files[i];
                        self.fd.append('files[' + i + ']', file);
                    }
                    if ( this.files && this.files.length > 1 ){
                        fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
                    } else {
                        fileName = e.target.value.split('\\').pop();
                    }
                    if ( fileName ) {
                        label.querySelector( 'span.choose-file-name' ).innerHTML = fileName;
                    } else {
                        label.innerHTML = labelVal;
                    }
                });
            });
        },
        removeUpload(){
            // eslint-disable-next-line no-unused-vars
            let fileUploadInput = document.getElementById('input-file-pdf').value = '';
            // eslint-disable-next-line no-unused-vars
            let fileNameText = document.querySelector( 'span.choose-file-name' ).innerHTML = '';
            this.id_upload_empty = false;
        },
        submitProposal(){
            if (this.isDisabled === false){
                let obj = this.$store.state;
                let state = this.$store;
                this.inactive_submit = true;

                this.fd.append('email', obj.email);
                this.fd.append('first_name', obj.first_name,);
                this.fd.append('last_name', obj.last_name);
                this.fd.append('middle_name', obj.middle_name);
                this.fd.append('organisation_name', obj.organisation_name);
                this.fd.append('title', obj.title);
                this.fd.append('description', obj.description);
                this.fd.append('term', obj.term);
                this.fd.append('theory_of_change[implementation_strategy]', obj.theory_of_change.implementation_strategy);
                this.fd.append('theory_of_change[political_will]', obj.theory_of_change.political_will);
                this.fd.append('theory_of_change[political_will_does_not_exist]', obj.theory_of_change.political_will_does_not_exist);
                this.fd.append('theory_of_change[existing_road_maps]', obj.theory_of_change.existing_road_maps);
                this.fd.append('theory_of_change[decision_makers]', obj.theory_of_change.decision_makers);
                this.fd.append('theory_of_change[why_long_terms]', obj.theory_of_change.why_long_terms);
                this.fd.append('mitigating[climate_change]', obj.mitigating.climate_change);
                this.fd.append('mitigating[pandemics]',  obj.mitigating.pandemics);
                this.fd.append('mitigating[weapons]', obj.mitigating.weapons);
                this.fd.append('mitigating[eco_system_collapse]', obj.mitigating.eco_system_collapse);
                this.fd.append('mitigating[emerging_technology]', obj.mitigating.emerging_technology);
                this.fd.append('mitigating[unknown_risks]', obj.mitigating.unknown_risks);
                this.fd.append('wider_implications[alleviate_poverty_and_inequality]', obj.wider_implications.alleviate_poverty_and_inequality);
                this.fd.append('wider_implications[reducing_inclusivity_and_accountability_in_national]', obj.wider_implications.reducing_inclusivity_and_accountability_in_national);
                this.fd.append('wider_implications[increasing_conflict_and_political_violence]', obj.wider_implications.increasing_conflict_and_political_violence);
                this.fd.append('wider_implications[enhance_inclusivity_and_accountability_in_national]', obj.wider_implications.enhance_inclusivity_and_accountability_in_national);
                this.fd.append('wider_implications[increasing_poverty_and_inequality]', obj.wider_implications.increasing_poverty_and_inequality);
                this.fd.append('wider_implications[reduce_conflict_and_political_violencey]', obj.wider_implications.reduce_conflict_and_political_violencey);
                this.fd.append('details', obj.details);
                if ( obj.catastrophic_risks.climate_change === true){
                    this.fd.append('catastrophic_risks[climate_change]', 1);
                } else {
                    this.fd.append('catastrophic_risks[climate_change]', 0);
                }
                if ( obj.catastrophic_risks.eco_system_collapse === true){
                    this.fd.append('catastrophic_risks[eco_system_collapse]', 1);
                } else {
                    this.fd.append('catastrophic_risks[eco_system_collapse]', 0);
                }
                if ( obj.catastrophic_risks.pandemics === true){
                    this.fd.append('catastrophic_risks[pandemics]', 1);
                } else {
                    this.fd.append('catastrophic_risks[pandemics]', 0);
                }
                if ( obj.catastrophic_risks.weapons === true){
                    this.fd.append('catastrophic_risks[weapons]', 1);
                } else {
                    this.fd.append('catastrophic_risks[weapons]', 0);
                }
                if ( obj.catastrophic_risks.emerging_technology === true){
                    this.fd.append('catastrophic_risks[emerging_technology]', 1);
                } else {
                    this.fd.append('catastrophic_risks[emerging_technology]', 0);
                }
                if ( obj.catastrophic_risks.unknown_risks === true){
                    this.fd.append('catastrophic_risks[unknown_risks]', 1);
                } else {
                    this.fd.append('catastrophic_risks[unknown_risks]', 0);
                }
                if ( obj.multipliers_risks.conflict === true){
                    this.fd.append('multipliers_risks[conflict]', 1);
                } else {
                    this.fd.append('multipliers_risks[conflict]', 0);
                }
                if ( obj.multipliers_risks.institutions === true){
                    this.fd.append('multipliers_risks[institutions]', 1);
                } else {
                    this.fd.append('multipliers_risks[institutions]', 0);
                }
                if ( obj.multipliers_risks.poverty === true){
                    this.fd.append('multipliers_risks[poverty]', 1);
                } else {
                    this.fd.append('multipliers_risks[poverty]', 0);
                }
                if ( obj.is_organisation === true){
                    this.fd.append('is_organisation', 1);
                } else {
                    this.fd.append('is_organisation', 0);
                }
                if ( obj.is_anonymous === true){
                    this.fd.append('is_anonymous', 1);
                } else {
                    this.fd.append('is_anonymous', 0);
                }
                if ( obj.is_confirm === true){
                    this.fd.append('is_confirm', 1);
                } else {
                    this.fd.append('is_confirm', 0);
                }
                this.fd.append('is_form_complete', 1);

                axios.post('/api/proposal', this.fd, { headers: {  'Content-Type': 'multipart/form-data' } })
                    .then(function(response) {
                        if (response.status >= 200 && response.status < 300){
                            state.commit('IN_DETAIL_COMPLETE', true);
                            state.commit('SUBMIT_FORM', true);
                        }
                        console.log(response);
                    }).catch(function(error) {
                        console.log(error);
                    });
            }
        },
    }
};
