export default class Accordion {
    constructor(opt) {
        let defaultOptions = {
            classSelector: '.js-accordion-button'
        };
        let options = Object.assign({}, opt, defaultOptions);
        this.init(options.classSelector);
    }

    init(classSelector) {
        let accBtn = document.querySelectorAll(classSelector);

        accBtn.forEach(element => {
            element.addEventListener('click', (evt) => {
                let el = evt.target;
                el.classList.toggle('is-active');
                let panel = el.nextElementSibling;
                this.toggleElement(panel);
            });
        });
    }

    toggleElement(panel) {
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + 'px';
        }
    }
}
