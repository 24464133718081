//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Odometer from 'odometer/odometer.min.js';
export default {
    props: {
        orgsCount: {

            required: true,
        }
    },
    methods: {
        visibilityChanged(isVisible) {
            if (isVisible) {
                this.runOdometer();
            }
        },
        runOdometer() {
            let orgsCount = parseInt(this.orgsCount);
            setTimeout(function(){
                const el = document.getElementById('odometer');
                const od = new Odometer({
                    el: el,
                    value: 0,
                    auto: false,
                    duration: 12000,
                    animation: 'count'
                });
                od.update(orgsCount);
            }, 1000);
        }
    }
};

