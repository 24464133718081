var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "section-form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.subscribe($event)
        },
        keydown: function($event) {
          return _vm.form.onKeydown($event)
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-lg-5 mb-3 col-xs-12" },
          [
            _c("div", { staticClass: "section-form__block" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.name,
                    expression: "form.name"
                  }
                ],
                staticClass: "section-form__input",
                attrs: { type: "text", name: "name", placeholder: "Name" },
                domProps: { value: _vm.form.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "name", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("has-error", { attrs: { form: _vm.form, field: "name" } })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-lg-5 mb-3 col-xs-12" },
          [
            _c("div", { staticClass: "section-form__block" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.email,
                    expression: "form.email"
                  }
                ],
                staticClass: "section-form__input",
                attrs: { type: "email", name: "email", placeholder: "Email" },
                domProps: { value: _vm.form.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "email", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("has-error", { attrs: { form: _vm.form, field: "email" } })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-2 text-center" }, [
          _c(
            "button",
            {
              staticClass: "btn btn--orange btn--large",
              class: _vm.form.busy ? "btn--disabled" : "",
              attrs: { disabled: _vm.form.busy }
            },
            [
              _vm._v(
                "\n                " + _vm._s(_vm.buttonText) + "\n            "
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }