//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios';
export default {
    name: 'SearchModal',
    data() {
        return {
            formShow: false,
            searchInput: '',
            headerElement: '',
            bodyOverflowInit: document.body.style.overflow,
            searchResults: null,
            loading: false,
            header_transparent: false
        };
    },
    created() {
        document.body.addEventListener('keyup', e => {
            if (e.key === 'Escape') {
                this.formShow = false;

                document.body.style.overflow = this.bodyOverflowInit;
                if (this.header_transparent) {
                    this.headerElement.classList.add('header--transparent');
                }

                this.headerElement.classList.remove('fade-in');
            } else if (e.key === 'Enter') {
                this.sendRequest();
            }
        });

        this.$bus.$on('open-search', () => {
            document.body.style.overflow = 'hidden';
            if (this.header_transparent) {
                this.headerElement.classList.remove('header--transparent');
            }
            this.headerElement.classList.add('fade-in');

            this.formShow = true;

            this.$nextTick(() => {
                this.$refs.searchInput.focus();
            });
        });
    },

    mounted() {
        this.headerElement = document.querySelector('header');
        if (this.headerElement.classList.contains('header--transparent')) {
            this.header_transparent = true;
        }
    },

    beforeDestroy() {
        this.$bus.off('open-search');
    },

    methods: {
        resetForm() {
            if (this.searchInput) {
                this.searchInput = '';
                this.searchResults = null;
            } else {
                this.formShow = false;

                document.body.style.overflow = this.bodyOverflowInit;
                if (this.header_transparent) {
                    this.headerElement.classList.add('header--transparent');
                }
                this.headerElement.classList.remove('fade-in');
            }
        },

        sendRequest() {
            try {
                this.searchResults = null;
                this.loading = true;
                axios.get('/api/search', {
                    params: {
                        query: this.searchInput
                    }
                }).then(response => {
                    this.searchResults = response.data.data;
                    this.loading = false;
                }).catch(error => {
                    console.error(error);
                    this.loading = false;
                });
            } catch (e) {
                console.error(e);
                this.loading = false;
            }
        }
    }
};
