//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from 'vform';
import Multiselect from 'vue-multiselect';
import swal from 'sweetalert2';

export default {
    components: {
        Multiselect
    },
    data() {
        return {
            options: [
                { name: 'Western Europe, North America or Australasia' },
                { name: 'Eastern Europe'},
                { name: 'Africa'},
                { name: 'Asia and Middle East'},
                { name: 'Latin America or Caribbean '}
            ],
            form: new Form({
                is_organisation: false,
                name: '',
                email: '',
                location:'',
                subscribe_together_first: false,
                subscribe_una_uk: false
            })
        };
    },
    methods: {
        subscribe() {
            this.form.post('/api/join-us')
                .then(({ data }) => {
                    if (data && data.success) {
                        this.form.reset();
                        swal.fire({
                            title: 'Thank you!',
                            text: 'Thanks for making a difference and signing up to our mission',
                            type: 'success',
                            confirmButtonText: 'Close'
                        });
                    }
                });
        }
    }
};
