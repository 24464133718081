import throttle from 'lodash/throttle';

class StickyHeader {
    constructor() {
        let scrollpos = window.scrollY ||
                        window.pageYOffset ||
                        document.body.scrollTop +
                        ((document.getElementById('app') &&
                            document.getElementById('app').scrollTop) ||
                            0);

        const header = document.querySelector('.header');

        if (header){
            const add_class_on_scroll = () => header.classList.add('fade-in');
            const remove_class_on_scroll = () => header.classList.remove('fade-in');
            window.addEventListener('scroll', throttle(() =>{
                scrollpos = window.scrollY ||
                    window.pageYOffset ||
                    document.body.scrollTop +
                    ((document.getElementById('app') &&
                        document.getElementById('app').scrollTop) ||
                        0);
                if (scrollpos >= 10){
                    add_class_on_scroll();
                } else {
                    remove_class_on_scroll();
                }
            }, 50));
        }
    }
}
export default StickyHeader;
