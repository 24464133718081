//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClimateIcon from './assets/ClimateIcon.vue';
import EcoSystem from './assets/EcoSystem.vue';
import ArrowRight from './assets/ArrowRight.vue';
import TechnoThreat from './assets/TechnoThreat.vue';
import Unknown from './assets/Unknown.vue';
import Pandemics from './assets/Pandemics.vue';
import Weapons from './assets/Weapons.vue';
// Casing fix lol

export default {
    name: 'ProposalCategorisation',
    components: {
        'climate-icon': ClimateIcon,
        'arrow-right': ArrowRight,
        'eco-system': EcoSystem,
        'techno-threat': TechnoThreat,
        'unknown-image': Unknown,
        'pandemics': Pandemics,
        'weapons': Weapons
    },
    computed: {
        // Section one
        climateChange: {
            get() {
                return this.$store.state.catastrophic_risks.climate_change;
            },
            set(value) {
                this.$store.commit('CLIMATE_CHANGE', value);
            }
        },
        ecoCollapse: {
            get() {
                return this.$store.state.catastrophic_risks.eco_system_collapse;
            },
            set(value) {
                this.$store.commit('ECO_COLLAPSE', value);
            }
        },
        pandemicsResistance: {
            get() {
                return this.$store.state.catastrophic_risks.pandemics;
            },
            set(value) {
                this.$store.commit('PANDEMICS_RESISTANCE', value);
            }
        },
        massWeapons: {
            get() {
                return this.$store.state.catastrophic_risks.weapons;
            },
            set(value) {
                this.$store.commit('MASS_WEAPONS', value);
            }
        },
        technologyThreat: {
            get() {
                return this.$store.state.catastrophic_risks.emerging_technology;
            },
            set(value) {
                this.$store.commit('TECHNOLOGY_THREAT', value);
            }
        },
        unknownRisks: {
            get() {
                return this.$store.state.catastrophic_risks.unknown_risks;
            },
            set(value) {
                this.$store.commit('UNKNOWN_RISKS', value);
            }
        },
        // Section two
        multiplyConflict: {
            get() {
                return this.$store.state.multipliers_risks.conflict;
            },
            set(value) {
                this.$store.commit('MULTIPLY_CONFLICT', value);
            }
        },
        multiplyInstitutions: {
            get() {
                return this.$store.state.multipliers_risks.institutions;
            },
            set(value) {
                this.$store.commit('MULTIPLY_INSTITUTIONS', value);
            }
        },
        multiplyPoverty: {
            get() {
                return this.$store.state.multipliers_risks.poverty;
            },
            set(value) {
                this.$store.commit('MULTIPLY_POVERTY', value);
            }
        },
        //Section three
        termImplementation: {
            get() {
                return this.$store.state.term;
            },
            set(value) {
                this.$store.commit('TERM', value);
            }
        },
        isDisabled() {
            if ((this.termImplementation === 'short' || this.termImplementation === 'medium' || this.termImplementation === 'long')
                && (this.unknownRisks === true || this.technologyThreat === true || this.massWeapons === true || this.pandemicsResistance === true || this.ecoCollapse === true || this.climateChange === true)){
                return false;
            } else {
                return true;
            }
        }
    },
    methods: {
        completeProposal(){
            if (this.isDisabled === false){
                this.$store.commit('PROPOSAL_CAT_COMPLETED', true);
                this.$router.push('/proposal/form/theory-of-change');
            }
        }
    }
};
