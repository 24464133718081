var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "66.243",
        height: "66.148",
        viewBox: "0 0 66.243 66.148"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Group_1",
            "data-name": "Group 1",
            transform: "translate(-960.982 -2286.586)"
          }
        },
        [
          _c("path", {
            attrs: {
              id: "Path",
              d:
                "M9.565,0h0C-3.416,13.151-.146,20.865,2.061,23.072s9.922,5.476,23.071-7.5",
              transform: "translate(962 2326.58)",
              fill: "none",
              stroke: "#000",
              "stroke-miterlimit": "10",
              "stroke-width": "2"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Stroke_126",
              "data-name": "Stroke 126",
              d:
                "M15.537,48.5h0A41.474,41.474,0,0,0,8.77,39.735,41.793,41.793,0,0,0,0,32.964,111.679,111.679,0,0,1,16.34,20.672L12.66,7.79,20.452,0l11.8,11.8c3.33-1.579,5.81-2.522,6.633-2.522a.379.379,0,0,1,.265.07c.34.341-.6,2.982-2.455,6.892L48.5,28.049l-7.79,7.792L27.83,32.162A111.405,111.405,0,0,1,15.538,48.5ZM34.015,13.863c-1.45,0-5.19,2.688-8.892,6.39-3.64,3.642-6.323,7.358-6.378,8.835a.663.663,0,0,0,.145.517.652.652,0,0,0,.463.145h.05c1.482-.058,5.2-2.74,8.838-6.378,4.3-4.294,7.092-8.489,6.234-9.352A.627.627,0,0,0,34.015,13.863Z",
              transform: "translate(971.218 2294)",
              fill: "none",
              stroke: "#000",
              "stroke-miterlimit": "10",
              "stroke-width": "2"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Line",
              d: "M.5,10.5,10.566.434",
              transform: "translate(1014.533 2288.533)",
              fill: "none",
              stroke: "#000",
              "stroke-linecap": "round",
              "stroke-miterlimit": "10",
              "stroke-width": "2"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Line_Copy",
              "data-name": "Line Copy",
              d: "M0,7.778,7.778,0",
              transform: "translate(1018.033 2298.255)",
              fill: "none",
              stroke: "#000",
              "stroke-linecap": "round",
              "stroke-miterlimit": "10",
              "stroke-width": "2"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Line_Copy_2",
              "data-name": "Line Copy 2",
              d: "M0,7.778,7.778,0",
              transform: "translate(1008 2288)",
              fill: "none",
              stroke: "#000",
              "stroke-linecap": "round",
              "stroke-miterlimit": "10",
              "stroke-width": "2"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }