var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alphabetic-list-block__wrap" },
    _vm._l(_vm.transformed_array, function(item) {
      return _c(
        "div",
        { key: item.index, staticClass: "alphabetic-list-block__list" },
        [
          _c("span", { staticClass: "alphabetic-list-block__letter" }, [
            _vm._v("\n            " + _vm._s(item.title) + "\n        ")
          ]),
          _vm._v(" "),
          _vm._l(item.data, function(el) {
            return _c(
              "p",
              {
                key: el.index,
                staticClass: "alphabetic-list-block__organizations"
              },
              [_vm._v("\n            " + _vm._s(el) + "\n        ")]
            )
          })
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }