var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "searchForm" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.formShow,
            expression: "formShow"
          }
        ],
        staticClass: "search-modal"
      },
      [
        _c("div", { staticClass: "search-modal__container container" }, [
          _c("div", { staticClass: "search-modal__field" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchInput,
                  expression: "searchInput"
                }
              ],
              ref: "searchInput",
              staticClass: "search-modal__input",
              attrs: { type: "text", placeholder: "Type to search" },
              domProps: { value: _vm.searchInput },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchInput = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "search-modal__form-reset",
                attrs: { type: "button" },
                on: { click: _vm.resetForm }
              },
              [_c("span"), _vm._v(" "), _c("span")]
            )
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "search-modal__search btn btn--orange",
              attrs: { type: "button" },
              on: { click: _vm.sendRequest }
            },
            [_vm._v("\n                Search\n            ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "search-modal__bottom" }, [
            _vm.searchResults && _vm.searchResults.length
              ? _c("div", { staticClass: "search-modal__counter" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.searchResults && _vm.searchResults.length) +
                      " results for "
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.searchInput))])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "search-modal__caption" }, [
              _vm._v("\n                    Hit "),
              _c("b", [_vm._v("Enter")]),
              _vm._v(" to search or "),
              _c("b", [_vm._v("ESC")]),
              _vm._v(" to close\n                ")
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass:
              "search-modal__container search-modal__results container"
          },
          [
            _c(
              "div",
              { staticClass: "search-modal__results-inner" },
              [
                _vm.searchResults && _vm.searchResults.length
                  ? _vm._l(_vm.searchResults, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "row mb-4 mb-sm-5" },
                        [
                          _c("div", { staticClass: "col-lg-3" }, [
                            _c(
                              "div",
                              { staticClass: "search-modal__results-category" },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(item.short_class) +
                                    "\n                            "
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-lg-8" }, [
                            _c("div", { staticClass: "search-item" }, [
                              _c("a", {
                                staticClass: "search-item__title",
                                attrs: { href: item.url },
                                domProps: {
                                  innerHTML: _vm._s(
                                    item.highlight.title
                                      ? item.highlight.title.join(" ... ")
                                      : item.title
                                  )
                                }
                              }),
                              _vm._v(" "),
                              item.highlight.description
                                ? _c("div", {
                                    staticClass: "search-item__excerpt",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        item.highlight.description.join(" ... ")
                                      )
                                    }
                                  })
                                : _vm._e()
                            ])
                          ])
                        ]
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.searchResults && !_vm.searchResults.length
                  ? [_c("h3", [_vm._v("No matching results found.")])]
                  : _vm._e()
              ],
              2
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }