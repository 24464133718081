//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios';
import moment from 'moment';
export default {
    data() {
        return {
            feed: []
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            axios.get('/api/twitter-feed').then(({data}) => {
                this.feed = data;
            });
        },
        parseDate(date) {
            return moment(date, 'ddd MMM DD HH:mm:ss ZZ YYYY').format('MMM D');
        },
        parseText(string) {
            // Parse any links found in our tweet
            return this.parseHashTag(this.parseUsername(this.parseURL(string)));
        },
        // Auto-link URLs in a string
        parseURL(string) {
            return string.replace(/[A-Za-z]+:\/\/[A-Za-z0-9-_]+\.[A-Za-z0-9-_:%&~?/.=]+/g, function( url ) {
                return '<a href="' + url + '" target="_blank" class="link--orange">' + url + '</a>';
            });
        },
        // Auto-link Twitter usernames in a string
        parseUsername(string) {
            return string.replace(/[@]+[A-Za-z0-9-_]+/g, function( u ) {
                const username = u.replace('@','');
                return '<a href="https://twitter.com/' + username + '" target="_blank" class="link--orange">' + u + '</a>';
            });
        },
        // Auto-link Twitter hashtags in a string
        parseHashTag(string) {
            return string.replace(/[#]+[A-Za-z0-9-_]+/g, function( t ) {
                const tag = t.replace('#','');
                return '<a href="https://twitter.com/search?q=' + tag + '" target="_blank" class="link--orange">' + t + '</a>';
            });
        }
    }
};
