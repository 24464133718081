//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TextareaBlock from './elements/TextareaBlock.vue';
import ArrowRight from './assets/ArrowRight.vue';
export default {
    name: 'MitigatingRisks',
    components: {
        'arrow-right': ArrowRight,
        'textarea-block': TextareaBlock
    },
    computed: {
        prevSection(){
            if (this.$store.state.proposalCategorisation.isCompleted === false){
                return false;
            } else {
                return true;
            }
        },
        mitigatingRiskClimate: {
            get() {
                return this.$store.state.mitigating.climate_change;
            },
            set(value) {
                this.$store.commit('GLOBAL_RISKS_CLIMATE', value);
            }
        },
        mitigatingRiskPandemics: {
            get() {
                return this.$store.state.mitigating.pandemics;
            },
            set(value) {
                this.$store.commit('GLOBAL_RISKS_PANDEMICS', value);
            }
        },
        mitigatingRiskEcosystem: {
            get() {
                return this.$store.state.mitigating.eco_system_collapse;
            },
            set(value) {
                this.$store.commit('GLOBAL_RISKS_ECOSYSTEM', value);
            }
        },
        mitigatingRiskWeapons: {
            get() {
                return this.$store.state.mitigating.weapons;
            },
            set(value) {
                this.$store.commit('GLOBAL_RISKS_WEAPONS', value);
            }
        },
        mitigatingRiskTechnology: {
            get() {
                return this.$store.state.mitigating.emerging_technology;
            },
            set(value) {
                this.$store.commit('GLOBAL_RISKS_TECHNOLOGY', value);
            }
        },
        mitigatingRiskUnknown: {
            get() {
                return this.$store.state.mitigating.unknown_risks;
            },
            set(value) {
                this.$store.commit('GLOBAL_RISKS_UNKNOWN_RESPONSE', value);
            }
        },
        isDisabledMR(){
            let mitigatingRisk = this.$store.state.mitigating;
            if (mitigatingRisk.climate_change !== ''
                || mitigatingRisk.weapons !== ''
                || mitigatingRisk.eco_system_collapse !== ''
                || mitigatingRisk.pandemics !== ''
                || mitigatingRisk.emerging_technology !== ''
                || mitigatingRisk.unknown_risks !== ''){
                return false;
            } else {
                return true;
            }
        },
    },
    methods: {
        completeStarterSection(){
            if (this.isDisabledMR === false){
                this.$store.commit('GLOBAL_RISKS_COMPLETE', true);
                this.$router.push('/proposal/form/wider-implications');
            }
        },
    }
};
