export default class TruncateSummary {
    constructor() {
        this.init();
    }

    init() {
        // Truncate text, insert 'read more' and reinsert text on click

        let text = document.getElementById('truncate-summary');

        if (text){
            let originalText = text.innerText;
            let wordBoundary = [11, true];
            if (text.innerText.length > 280) {
                let subString = text.innerText.substr(0, 280-1);
                text.innerText = `${(wordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString)} ... `;

                let newElement = document.createElement('span');
                newElement.innerHTML = '<span class="read-more text--orange">Read more</span>';
                text.appendChild(newElement);
            }
            text.addEventListener('click', function(){
                text.innerText = originalText;
            });
        }
    }
}
