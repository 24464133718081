//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TextareaBlock from './../elements/TextareaBlock.vue';
import ArrowRight from './../assets/ArrowRight.vue';
export default {
    name: 'WiderImplicationsIncl',
    components: {
        'arrow-right': ArrowRight,
        'textarea-block': TextareaBlock
    },
    computed: {
        increasePoverty: {
            get() {
                return this.$store.state.wider_implications.increasing_poverty_and_inequality;
            },
            set(value) {
                this.$store.commit('WIDER_IMPLICATIONS_INCREASING_POVERTY', value);
            }
        },
        increaseConflict: {
            get() {
                return this.$store.state.wider_implications.increasing_conflict_and_political_violence;
            },
            set(value) {
                this.$store.commit('WIDER_IMPLICATIONS_INCREASE_CONFLICT', value);
            }
        },
        enhanceInclusivity: {
            get() {
                return this.$store.state.wider_implications.enhance_inclusivity_and_accountability_in_national;
            },
            set(value) {
                this.$store.commit('WIDER_IMPLICATIONS_ENHANCE_INCLUSIVITY', value);
            }
        },
        isDisabled(){
            let violence = this.$store.state.wider_implications;
            if (violence.reducing_inclusivity_and_accountability_in_national === ''){
                return true;
            } else if ( violence.increasing_conflict_and_political_violence === '') {
                return true;
            } else if ( violence.enhance_inclusivity_and_accountability_in_national === ''){
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        completeSection(){
            if (this.isDisabled === false){
                this.$store.commit('WIDER_IMPLICATIONS_COMPLETE', true);
                this.$router.push('/proposal/form/proposal-in-detail');
            }
        }
    }
};
