var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form__section" }, [
    _c("h3", { staticClass: "form__section__title main-heading" }, [
      _vm._v("\n        Proposal categorisation\n    ")
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "form__section__tip margin-b-74" }, [
      _vm._v(
        "\n        Please define the which risks and risk multipliers your proposal seeks to address, and the time frame within which you feel the proposal can be implemented.\n    "
      )
    ]),
    _vm._v(" "),
    _c("h3", { staticClass: "form__section__title" }, [
      _vm._v(
        "\n        What global catastrophic risks would your proposal mitigate?\n    "
      )
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "form__input__tip-text" }, [
      _vm._v("\n        Select all which apply\n    ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "risks__wrap margin-b-74" }, [
      _c("label", { staticClass: "risks__item" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.climateChange,
              expression: "climateChange"
            }
          ],
          attrs: { id: "climate-change", type: "checkbox" },
          domProps: {
            value: false,
            checked: Array.isArray(_vm.climateChange)
              ? _vm._i(_vm.climateChange, false) > -1
              : _vm.climateChange
          },
          on: {
            change: function($event) {
              var $$a = _vm.climateChange,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = false,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.climateChange = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.climateChange = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.climateChange = $$c
              }
            }
          }
        }),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "checkmark" },
          [
            _c("climate-icon"),
            _vm._v(" "),
            _c("span", { staticClass: "checkmark__text" }, [
              _vm._v("Climate change")
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("label", { staticClass: "risks__item" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.ecoCollapse,
              expression: "ecoCollapse"
            }
          ],
          attrs: { id: "ecosystem-collapse", type: "checkbox" },
          domProps: {
            value: false,
            checked: Array.isArray(_vm.ecoCollapse)
              ? _vm._i(_vm.ecoCollapse, false) > -1
              : _vm.ecoCollapse
          },
          on: {
            change: function($event) {
              var $$a = _vm.ecoCollapse,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = false,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.ecoCollapse = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.ecoCollapse = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.ecoCollapse = $$c
              }
            }
          }
        }),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "checkmark" },
          [
            _c("eco-system"),
            _vm._v(" "),
            _c("span", { staticClass: "checkmark__text" }, [
              _vm._v("Eco-system collapse")
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("label", { staticClass: "risks__item" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.pandemicsResistance,
              expression: "pandemicsResistance"
            }
          ],
          attrs: { id: "pandemics-form", type: "checkbox" },
          domProps: {
            value: false,
            checked: Array.isArray(_vm.pandemicsResistance)
              ? _vm._i(_vm.pandemicsResistance, false) > -1
              : _vm.pandemicsResistance
          },
          on: {
            change: function($event) {
              var $$a = _vm.pandemicsResistance,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = false,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.pandemicsResistance = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.pandemicsResistance = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.pandemicsResistance = $$c
              }
            }
          }
        }),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "checkmark" },
          [
            _c("pandemics"),
            _vm._v(" "),
            _c("span", { staticClass: "checkmark__text" }, [
              _vm._v("Pandemics and anti-microbial resistance")
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("label", { staticClass: "risks__item" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.massWeapons,
              expression: "massWeapons"
            }
          ],
          attrs: { id: "weapons-form", type: "checkbox" },
          domProps: {
            value: false,
            checked: Array.isArray(_vm.massWeapons)
              ? _vm._i(_vm.massWeapons, false) > -1
              : _vm.massWeapons
          },
          on: {
            change: function($event) {
              var $$a = _vm.massWeapons,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = false,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.massWeapons = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.massWeapons = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.massWeapons = $$c
              }
            }
          }
        }),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "checkmark" },
          [
            _c("weapons"),
            _vm._v(" "),
            _c("span", { staticClass: "checkmark__text" }, [
              _vm._v("Weapons of Mass Destruction")
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("label", { staticClass: "risks__item" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.technologyThreat,
              expression: "technologyThreat"
            }
          ],
          attrs: { id: "technology-form", type: "checkbox" },
          domProps: {
            value: false,
            checked: Array.isArray(_vm.technologyThreat)
              ? _vm._i(_vm.technologyThreat, false) > -1
              : _vm.technologyThreat
          },
          on: {
            change: function($event) {
              var $$a = _vm.technologyThreat,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = false,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.technologyThreat = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.technologyThreat = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.technologyThreat = $$c
              }
            }
          }
        }),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "checkmark" },
          [
            _c("techno-threat"),
            _vm._v(" "),
            _c("span", { staticClass: "checkmark__text" }, [
              _vm._v("The threat from new and emerging technology")
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("label", { staticClass: "risks__item" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.unknownRisks,
              expression: "unknownRisks"
            }
          ],
          attrs: { id: "unknown-risks", type: "checkbox" },
          domProps: {
            value: false,
            checked: Array.isArray(_vm.unknownRisks)
              ? _vm._i(_vm.unknownRisks, false) > -1
              : _vm.unknownRisks
          },
          on: {
            change: function($event) {
              var $$a = _vm.unknownRisks,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = false,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.unknownRisks = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.unknownRisks = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.unknownRisks = $$c
              }
            }
          }
        }),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "checkmark" },
          [
            _c("unknown-image"),
            _vm._v(" "),
            _c("span", { staticClass: "checkmark__text" }, [
              _vm._v("Unknown risks")
            ])
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("h3", { staticClass: "form__section__title" }, [
      _vm._v(
        "\n        What risk multipliers would your proposal help to manage?\n    "
      )
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "form__input__tip-text" }, [
      _vm._v("\n        Select all, if any, which apply\n    ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "risks__wrap margin-b-74" }, [
      _c("label", { staticClass: "risks__multipliers" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.multiplyConflict,
              expression: "multiplyConflict"
            }
          ],
          attrs: { id: "conflict-and-violence", type: "checkbox" },
          domProps: {
            value: false,
            checked: Array.isArray(_vm.multiplyConflict)
              ? _vm._i(_vm.multiplyConflict, false) > -1
              : _vm.multiplyConflict
          },
          on: {
            change: function($event) {
              var $$a = _vm.multiplyConflict,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = false,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.multiplyConflict = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.multiplyConflict = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.multiplyConflict = $$c
              }
            }
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "checkmark" }),
        _vm._v(" "),
        _c("span", { staticClass: "risk__multipliers__text" }, [
          _vm._v("Conflict and political violence")
        ])
      ]),
      _vm._v(" "),
      _c("label", { staticClass: "risks__multipliers" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.multiplyInstitutions,
              expression: "multiplyInstitutions"
            }
          ],
          attrs: { id: "lack-of-accountability", type: "checkbox" },
          domProps: {
            value: false,
            checked: Array.isArray(_vm.multiplyInstitutions)
              ? _vm._i(_vm.multiplyInstitutions, false) > -1
              : _vm.multiplyInstitutions
          },
          on: {
            change: function($event) {
              var $$a = _vm.multiplyInstitutions,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = false,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.multiplyInstitutions = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.multiplyInstitutions = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.multiplyInstitutions = $$c
              }
            }
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "checkmark" }),
        _vm._v(" "),
        _c("span", { staticClass: "risk__multipliers__text" }, [
          _vm._v("Institutions that lack inclusivity and accountability")
        ])
      ]),
      _vm._v(" "),
      _c("label", { staticClass: "risks__multipliers" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.multiplyPoverty,
              expression: "multiplyPoverty"
            }
          ],
          attrs: { id: "poverty-and-inequality", type: "checkbox" },
          domProps: {
            value: false,
            checked: Array.isArray(_vm.multiplyPoverty)
              ? _vm._i(_vm.multiplyPoverty, false) > -1
              : _vm.multiplyPoverty
          },
          on: {
            change: function($event) {
              var $$a = _vm.multiplyPoverty,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = false,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.multiplyPoverty = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.multiplyPoverty = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.multiplyPoverty = $$c
              }
            }
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "checkmark" }),
        _vm._v(" "),
        _c("span", { staticClass: "risk__multipliers__text" }, [
          _vm._v("Poverty and inequality")
        ])
      ])
    ]),
    _vm._v(" "),
    _c("h3", { staticClass: "form__section__title" }, [
      _vm._v(
        "\n        Over what timeframe do you envisage your proposal will be implemented?\n    "
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form__radio margin-b-74" }, [
      _c("div", { staticClass: "form__radio__item radio-proposal-timeframe" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.termImplementation,
              expression: "termImplementation"
            }
          ],
          attrs: {
            id: "short-term",
            type: "radio",
            name: "proposal-timeframe",
            value: "short",
            checked: "checked"
          },
          domProps: { checked: _vm._q(_vm.termImplementation, "short") },
          on: {
            change: function($event) {
              _vm.termImplementation = "short"
            }
          }
        }),
        _vm._v(" "),
        _vm._m(0)
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form__radio__item radio-proposal-timeframe" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.termImplementation,
              expression: "termImplementation"
            }
          ],
          attrs: {
            id: "medium-term",
            type: "radio",
            value: "medium",
            name: "proposal-timeframe"
          },
          domProps: { checked: _vm._q(_vm.termImplementation, "medium") },
          on: {
            change: function($event) {
              _vm.termImplementation = "medium"
            }
          }
        }),
        _vm._v(" "),
        _vm._m(1)
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form__radio__item radio-proposal-timeframe" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.termImplementation,
              expression: "termImplementation"
            }
          ],
          attrs: {
            id: "long-term",
            type: "radio",
            value: "long",
            name: "proposal-timeframe"
          },
          domProps: { checked: _vm._q(_vm.termImplementation, "long") },
          on: {
            change: function($event) {
              _vm.termImplementation = "long"
            }
          }
        }),
        _vm._v(" "),
        _vm._m(2)
      ])
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "form__section__button",
        class: { inactive: _vm.isDisabled },
        on: {
          click: function($event) {
            return _vm.completeProposal()
          }
        }
      },
      [_vm._v("\n        Continue"), _c("arrow-right")],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "short-term" } }, [
      _c("span", { staticClass: "bold-text" }, [_vm._v("Short term")]),
      _vm._v(" "),
      _c("span", { staticClass: "tip-text" }, [
        _vm._v(
          "The proposal could be announced at the UN's 75th anniversary year in 2020 and full implemented by 2025"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "medium-term" } }, [
      _c("span", { staticClass: "bold-text" }, [_vm._v("Medium term")]),
      _vm._v(" "),
      _c("span", { staticClass: "tip-text" }, [
        _vm._v(
          "The proposal could be fully implemented in the next ten years - the timeframe of the Sustainable Development Goals and Agenda 2030"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "long-term" } }, [
      _c("span", { staticClass: "bold-text" }, [_vm._v("Long term")]),
      _vm._v(" "),
      _c("span", { staticClass: "tip-text" }, [
        _vm._v(
          "The proposal addresses the type of world we'd like to see for the 100th anniversary of the UN in 2045 and beyond"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }