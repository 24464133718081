import { render, staticRenderFns } from "./BlogList.vue?vue&type=template&id=58f79ba9&"
import script from "./BlogList.vue?vue&type=script&lang=js&"
export * from "./BlogList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('58f79ba9')) {
      api.createRecord('58f79ba9', component.options)
    } else {
      api.reload('58f79ba9', component.options)
    }
    module.hot.accept("./BlogList.vue?vue&type=template&id=58f79ba9&", function () {
      api.rerender('58f79ba9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/app/components/BlogList.vue"
export default component.exports