import { render, staticRenderFns } from "./TheoryOfChange.vue?vue&type=template&id=6fabe25c&"
import script from "./TheoryOfChange.vue?vue&type=script&lang=js&"
export * from "./TheoryOfChange.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6fabe25c')) {
      api.createRecord('6fabe25c', component.options)
    } else {
      api.reload('6fabe25c', component.options)
    }
    module.hot.accept("./TheoryOfChange.vue?vue&type=template&id=6fabe25c&", function () {
      api.rerender('6fabe25c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/app/components/blocks/TheoryOfChange.vue"
export default component.exports