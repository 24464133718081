// Glide.js
// --------------------------------------------------------------------------
import Glide, { Controls, Breakpoints, Autoplay } from '@glidejs/glide/dist/glide.modular.esm';

class HeaderSlider {
    constructor() {
        //Header glide slider
        // --------------------------------------------------------------------------
        var header_glide = document.getElementById('options-autoplay-header');
        if (header_glide) {
            var list = header_glide.getElementsByClassName('glide__slide');
            if (list && list.length) {
                var glide_header = new Glide('#options-autoplay-header', {
                    type: 'slideshow',
                    rewind: true,
                    autoplay: 4500,
                    gap: 0,
                    hoverpause: false,
                    perView: 1
                });
                glide_header.mount({Controls, Breakpoints, Autoplay});
            }
        }
    }
}

export default HeaderSlider;
