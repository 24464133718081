//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Glide, {Autoplay} from '@glidejs/glide/dist/glide.modular.esm';
import domtoimage from 'dom-to-image';
import axios from 'axios';

Object.defineProperty(Array.prototype, 'message_line_wrap', {
    value: function(chunkSize) {
        let array = this;
        return [].concat.apply([],
            array.map(function(elem, i) {
                return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
            })
        );
    }
});

export default {
    name: 'ShoutBox',
    data() {
        return {
            shoutbox_slider: true,
            show_form: false,
            max_characters_description: 240,
            is_empty_text: false,
            is_empty_name: false,
            is_empty_location: false,
            text1: this.$store.state.shoutbox,
            form_submitted: false,
            shouts: [],
            shoutbox_response: null,
            loading: true,
            loading_failed: false,
            site_url: '',
            image_data_url: ''
        };
    },
    computed: {
        shoutbox: {
            get() {
                return this.$store.state.shoutbox;
            },
            set(value) {
                this.$store.commit('SHOUTBOX', value);
            },
        },
        shoutboxName: {
            get() {
                return this.$store.state.shoutbox_name;
            },
            set(value) {
                this.$store.commit('SHOUTBOX_NAME', value);
            },
        },
        shoutboxLocation: {
            get() {
                return this.$store.state.shoutbox_location;
            },
            set(value) {
                this.$store.commit('SHOUTBOX_LOCATION', value);
            },
        },
        textLength(){
            if (!this.shoutbox){
                return 0;
            }
            if (this.shoutbox.length > 0){
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.shoutbox = this.shoutbox.substring(0, 240);
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.is_empty_text = false;
            }
            return this.shoutbox.length;
        },
        nameLength(){
            if (this.shoutboxName.length > 0){
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.is_empty_name = false;
            }
            return this.shoutboxName.length;
        },
        locationLength(){
            if (this.shoutboxLocation.length > 0){
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.is_empty_location = false;
            }
            return this.shoutboxLocation.length;
        },
        isDisabled(){
            if (this.$store.state.shoutbox !== ''
            && this.$store.state.shoutbox_name !== ''
            && this.$store.state.shoutbox_location !== ''){
                return false;
            } else {
                return true;
            }
        }
    },
    created() {
        this.site_url = window.APP_URL;
    },
    mounted() {
        this.loadShouts();
    },
    methods: {
        loadShouts(){
            axios.get('/api/shoutboxes')
                .then(({ data }) => {
                    this.shouts.push(data.data.posts);
                    setTimeout(() => {
                        this.sliderInit();
                    }, 0);
                    this.loading = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        switchBlocks(form, slider, tweet){
            this.show_form = form;
            this.shoutbox_slider = slider;
            this.form_submitted = tweet;
            this.$store.commit('SHOUTBOX', '');
            this.$store.commit('SHOUTBOX_NAME', '');
            this.$store.commit('SHOUTBOX_LOCATION', '');
        },
        messageAnimation(mesageArray){
            let regex = /\s+/gi;
            let wordCount = mesageArray.trim().replace(regex, ' ').split(' ');
            let chunkedArray = wordCount.message_line_wrap(6);
            return chunkedArray.map(arr => `<div class="shoutbox__slider-item-wrap"><p class="shoutbox__slider-item-line">${arr.join(' ')}`).join('</p></div>');
        },
        onKeyDownText(evt){
            function OnInput() {
                this.style.height = 'auto';
                this.style.height = (this.scrollHeight) + 'px';
            }
            let tx = document.getElementsByTagName('textarea');
            for (let i = 0; i < tx.length; i++) {
                tx[i].setAttribute('style', 'height:' + (tx[i].scrollHeight) + 'px;overflow-y:hidden;');
                tx[i].addEventListener('input', OnInput, false);
            }
            if (this.textLength >= 240) {
                if (evt.keyCode >= 48 && evt.keyCode <= 90 || evt.keyCode === 32) {
                    evt.preventDefault();
                    return;
                }
            }
            return true;
        },
        onKeyDownName(evt) {
            if (this.nameLength >= 50) {
                if (evt.keyCode >= 48 && evt.keyCode <= 90 || evt.keyCode === 32) {
                    evt.preventDefault();
                    return;
                }
            }
            return true;
        },
        onKeyDownLocation(evt) {
            if (this.locationLength >= 30) {
                if (evt.keyCode >= 48 && evt.keyCode <= 90 || evt.keyCode === 32) {
                    evt.preventDefault();
                    return;
                }
            }
            return true;
        },
        onKeyDownSubmit(evt){
            if (this.isDisabled === false && evt.keyCode === 13){
                this.shoutSubmitRequest();
            }
        },
        shoutSubmitRequest(){
            document.querySelector('.shoutbox__wrap').classList.add('image-framed');

            const node = document.getElementById('shoutbox_id');

            domtoimage.toPng(node)
                .then((dataUrl) => {
                    let store = this.$store;
                    this.image_data_url = dataUrl;
                    document.querySelector('.shoutbox__wrap').classList.remove('image-framed');
                    this.loading = true;
                    axios.post('/api/shoutboxes', {
                        'message_new': store.state.shoutbox,
                        'name_new': store.state.shoutbox_name,
                        'location_new': store.state.shoutbox_location,
                        'confirmed_new': false,
                        'files_new': this.image_data_url,
                    })
                        .then((response) => {
                            if (response.status >= 200 && response.status < 300){
                                this.loading = false;
                                this.show_form = false;
                                this.form_submitted = true;
                                this.shoutbox_response = response.data.shoutbox.id;
                            } else {
                                this.loading_failed = true;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        submitShout(e){
            e.preventDefault();

            if (this.isDisabled === false){
                this.shoutSubmitRequest();
            } else {
                if (this.$store.state.shoutbox === ''){
                    this.is_empty_text = true;
                } else if (this.$store.state.shoutbox_name === ''){
                    this.is_empty_name = true;
                } else if (this.$store.state.shoutbox_location === ''){
                    this.is_empty_location = true;
                }
            }
        },
        sliderInit(){
            let shoutbox_glide = document.getElementById('shout-box-slider');
            if (shoutbox_glide) {
                let shoutbox_list = shoutbox_glide.getElementsByClassName('shoutbox__slider-item');
                if (shoutbox_list && shoutbox_list.length) {
                    let shoutbox_glide_slider = new Glide('#shout-box-slider', {
                        type: 'slideshow',
                        rewind: true,
                        autoplay: 4500,
                        gap: 0,
                        hoverpause: true,
                        perView: 1
                    });
                    shoutbox_glide_slider.mount({Autoplay});
                }
            }
        },
        refreshPage() {
            return window.location.reload();
        }
    },
};
