// Glide.js
//CMS section carousel slider
// --------------------------------------------------------------------------
import Glide, {Controls, Breakpoints, Autoplay} from '@glidejs/glide/dist/glide.modular.esm';

class Carousel {
    constructor() {
        //CMS section carousel slider
        // --------------------------------------------------------------------------

        let carouselEl = document.querySelectorAll('.carousel-section__wrap');

        if (carouselEl) {
            carouselEl.forEach(()=>{
                let sliders = document.querySelectorAll('.carousel-section__wrap');
                for (let i = 0; i < sliders.length; i++) {
                    let carousel_section = new Glide(sliders[i], {
                        type: 'slider',
                        perView: 3,
                        arrows: false,
                        rewind: true,
                        breakpoints: {
                            720: {
                                gap: 15,
                                perView: 1,
                                arrows: false,
                            },
                            991: {
                                perView: 2,
                                arrows: false,
                            },
                            1200: {
                                perView: 3,
                                arrows: false,
                            }
                        }
                    });
                    carousel_section.mount({Controls, Breakpoints, Autoplay});
                }
            });
        }

        //Featured proposal carousel slider
        // --------------------------------------------------------------------------

        if (document.querySelector('.featured-carousel__container')) {
            let featured_carousel = new Glide('.featured-carousel__container', {
                type: 'slider',
                perView: 1,
                arrows: false,
                autoplay: 4500
            });
            featured_carousel.mount({Controls, Breakpoints, Autoplay});
        }

    }
}

export default Carousel;
