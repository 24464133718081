//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BlogPost from './BlogPost.vue';
import axios from 'axios';

export default {

    components:{
        BlogPost
    },

    data() {
        return {
            currentPage: 1,
            articles: [],
            showMoredata: true,
            pagesTotal: 0
        };
    },

    created() {
        this.loadMore();
    },

    methods: {
        loadMore() {
            axios.get(`/api/blog?page=${this.currentPage}`)
                .then(({ data }) => {
                    const pagesTotal = Math.ceil(data.data.posts.total/data.data.posts.per_page);
                    this.articles = this.articles.concat(data.data.posts.data);
                    this.currentPage = this.currentPage + 1;
                    if (this.currentPage > pagesTotal) {
                        this.showMoredata = false;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }
};
