var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.regions, function(region, index) {
      return _c("div", { staticClass: "checkbox__wrap" }, [
        _c(
          "label",
          {
            staticClass: "checkbox__label",
            class: [{ "checkbox__label--checked": _vm.checked }],
            attrs: { for: region.name }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: region.checked,
                  expression: "region.checked"
                }
              ],
              staticClass: "checkbox__input",
              attrs: { id: region.name, type: "checkbox", name: "checkbox" },
              domProps: {
                value: index,
                checked: Array.isArray(region.checked)
                  ? _vm._i(region.checked, index) > -1
                  : region.checked
              },
              on: {
                change: function($event) {
                  var $$a = region.checked,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = index,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(region, "checked", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          region,
                          "checked",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(region, "checked", $$c)
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("p", { staticClass: "text text--medium text--bold" }, [
              _vm._v(_vm._s(region.region))
            ])
          ]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }