//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
export default {
    props: {
        article: {
            type: Object,
            required: true
        }
    }
};
