//Shoutbox
export const SHOUTBOX = (state, message) => {
    state.shoutbox = message;
};
export const SHOUTBOX_NAME = (state, message) => {
    state.shoutbox_name = message;
};
export const SHOUTBOX_LOCATION = (state, message) => {
    state.shoutbox_location = message;
};

// User email
export const USER_EMAIL = (state, message) => {
    state.email = message;
};

export const SAVE_AND_CLOSE = (state, message) => {
    state.isPopupVisible = message;
};

export const SUBMIT_FORM = (state, message) => {
    state.isFormSubmitted = message;
};

export const START_FORM_SUBMITTION = (state, message) => {
    state.start_submitting_form = message;
};


// ################ About you section
export const UPDATE_FIRST_NAME = (state, message) => {
    state.first_name = message;
};
export const UPDATE_MIDDLE_NAMES = (state, message) => {
    state.middle_name = message;
};
export const UPDATE_LAST_NAME = (state, message) => {
    state.last_name = message;
};
export const AUTHOR_CREDIT = (state, message) => {
    state.is_anonymous = message;
};
export const ON_BEHALF_STATE = (state, message) => {
    state.is_organisation = message;
};
export const ORGANISATION_NAME = (state, message) => {
    state.organisation_name = message;
};
export const ABOUT_YOU_COMPLETE = (state, message) => {
    state.aboutYou.isCompleted = message;
};


// ################ Proposal Section
export const PROPOSAL_COMPLETED = (state, message) => {
    state.proposal.isCompleted = message;
};
export const PROPOSAL_TITLE = (state, message) => {
    state.title = message;
};
export const PROPOSAL_DESCRIPTION = (state, message) => {
    state.description = message;
};


// ################ Proposal categorisation section
export const PROPOSAL_CAT_COMPLETED = (state, message) => {
    state.proposalCategorisation.isCompleted = message;
};
export const CLIMATE_CHANGE = (state, message) => {
    state.catastrophic_risks.climate_change = message;
};
export const ECO_COLLAPSE = (state, message) => {
    state.catastrophic_risks.eco_system_collapse = message;
};
export const PANDEMICS_RESISTANCE = (state, message) => {
    state.catastrophic_risks.pandemics = message;
};
export const MASS_WEAPONS = (state, message) => {
    state.catastrophic_risks.weapons = message;
};
export const TECHNOLOGY_THREAT = (state, message) => {
    state.catastrophic_risks.emerging_technology = message;
};
export const UNKNOWN_RISKS = (state, message) => {
    state.catastrophic_risks.unknown_risks = message;
};
export const MULTIPLY_CONFLICT = (state, message) => {
    state.multipliers_risks.conflict = message;
};
export const MULTIPLY_INSTITUTIONS = (state, message) => {
    state.multipliers_risks.institutions = message;
};
export const MULTIPLY_POVERTY = (state, message) => {
    state.multipliers_risks.poverty = message;
};
export const TERM = (state, message) => {
    state.term = message;
};

// ################ Theory of change section
export const THEORY_OF_CHANGE_COMPLETE = (state, message) => {
    state.theoryOfChange.isCompleted = message;
};
export const TOC_IMPLEMENTATION_STRATEGY = (state, message) => {
    state.theory_of_change.implementation_strategy = message;
};
export const TOC_POLITICAL_WILL = (state, message) => {
    state.theory_of_change.political_will = message;
};
export const TOC_POLITICAL_WILL__NOT = (state, message) => {
    state.theory_of_change.political_will_does_not_exist = message;
};
export const TOC_ROAD_MAPS = (state, message) => {
    state.theory_of_change.existing_road_maps = message;
};
export const TOC_DECISION_MAKERS = (state, message) => {
    state.theory_of_change.decision_makers = message;
};
export const TOC_WHY_LONG_TERM = (state, message) => {
    state.theory_of_change.why_long_terms = message;
};

// ################ Global risks mitigation
export const GLOBAL_RISKS_COMPLETE = (state, message) => {
    state.mitigatingRisks.isCompleted = message;
};
export const GLOBAL_RISKS_CLIMATE = (state, message) => {
    state.mitigating.climate_change = message;
};
export const GLOBAL_RISKS_PANDEMICS = (state, message) => {
    state.mitigating.pandemics = message;
};
export const GLOBAL_RISKS_ECOSYSTEM = (state, message) => {
    state.mitigating.eco_system_collapse = message;
};
export const GLOBAL_RISKS_WEAPONS = (state, message) => {
    state.mitigating.weapons = message;
};
export const GLOBAL_RISKS_TECHNOLOGY = (state, message) => {
    state.mitigating.emerging_technology = message;
};
export const GLOBAL_RISKS_UNKNOWN_RESPONSE = (state, message) => {
    state.mitigating.unknown_risks = message;
};


// ################ Wider implications
export const WIDER_IMPLICATIONS_COMPLETE = (state, message) => {
    state.widerImplications.isCompleted = message;
};
export const WIDER_IMPLICATIONS_ALLEVIATE = (state, message) => {
    state.wider_implications.alleviate_poverty_and_inequality = message;
};
export const WIDER_IMPLICATIONS_REDUCE_INCLUSIVITY = (state, message) => {
    state.wider_implications.reducing_inclusivity_and_accountability_in_national = message;
};
export const WIDER_IMPLICATIONS_INCREASE_CONFLICT = (state, message) => {
    state.wider_implications.increasing_conflict_and_political_violence = message;
};
export const WIDER_IMPLICATIONS_ENHANCE_INCLUSIVITY = (state, message) => {
    state.wider_implications.enhance_inclusivity_and_accountability_in_national = message;
};
export const WIDER_IMPLICATIONS_INCREASING_POVERTY = (state, message) => {
    state.wider_implications.increasing_poverty_and_inequality = message;
};
export const WIDER_IMPLICATIONS_REDUCE_CONFLICT = (state, message) => {
    state.wider_implications.reduce_conflict_and_political_violencey = message;
};


// ################ Your proposal in detail
export const IN_DETAIL_COMPLETE = (state, message) => {
    state.proposalInDetail.isCompleted = message;
};
export const IN_DETAIL_LEGAL_STATEMENT = (state, message) => {
    state.is_confirm = message;
};
export const IN_DETAIL_FULL_PROPOSAL = (state, message) => {
    state.details = message;
};
