//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    data() {
        return {
            checkbox_options:[],
            checkflag: false,
            regions: [
                {
                    region:'Western Europe, North America or Australasia',
                    checked: false
                },
                {
                    region:'Eastern Europe',
                    checked: false
                },
                {
                    region:'Africa',
                    checked: false
                },
                {
                    region:'Asia and Middle East',
                    checked: false
                },
                {
                    region:'Latin America or Caribbean',
                    checked: false
                }
            ]
        };
    }

};
