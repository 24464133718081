//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from 'vue-multiselect';
import axios from 'axios';

export default {
    name: 'ExploreFilters',
    components: {
        Multiselect
    },
    props: {
        categoryList: {
            type: Array,
            required: true,
        },
        timeList: {
            type: Array,
            required: true,
        },
        isSmall: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            initial: {
                category: '',
                time: ''
            },
            filter: {
                category: '',
                time: '',
            },
            resultTotal: null
        };
    },
    computed: {
        filterContent: function() {
            return {
                categories: [
                    {
                        label: 'All',
                        value: ''
                    },
                    ...this.categoryList
                ],
                times: [
                    {
                        label: 'All',
                        value: ''
                    },
                    ...this.timeList
                ]
            };
        },
        queryString: function() {
            let query = {};

            if (this.filter.category.value) {
                query.category = this.filter.category.value;
            } else {
                delete query.category;
            }

            if (this.filter.time.value) {
                query.time = this.filter.time.value;
            } else {
                delete query.time;
            }

            // build query string
            if (Object.keys(query).length > 0) {
                let queryString = '?';
                for (const [key, val] of Object.entries(query)) {
                    queryString += `${key}=${val}&`;
                }

                return queryString;
            }

            return '';
        }
    },
    mounted() {
        // If there is a query, populate the dropdowns
        if (this.$route.query) {

            const queryEntries = Object.entries(this.$route.query);

            for (const [key, val] of queryEntries) {
                if (key === 'category') {
                    let categoryVal = this.categoryList.find(category => category.value === val);

                    // Take a copy for the initial value fields
                    this.initial.category = categoryVal;

                    // Set the category value
                    this.filter.category = categoryVal;
                }

                if (key === 'time') {
                    let timeVal = this.timeList.find(time => time.value === val);

                    // Take a copy for the initial value fields
                    this.initial.time = timeVal;

                    // Set the category value
                    this.filter.time = timeVal;
                }
            }
        }

        // Get the total
        this.getTotal();
    },
    methods: {
        explore: function() {
            if (this.queryString.length > 0) {
                window.location = `/proposals${this.queryString}`;
            } else {
                window.location = '/proposals';
            }
        },
        clearFilter: function() {
            window.location = '/proposals';
        },
        getTotal: function() {
            axios.get(`/api/proposal${this.queryString}`)
                .then(res => {
                    this.resultTotal = `${res.data.total}`;
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }
};
