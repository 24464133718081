// Babel Polyfill
// Important that this is first
import '@babel/polyfill';
require('intersection-observer');

// CSS Imports 💄
// --------------------------------------------------------------------------
import '../sass/app.scss';

// Vendor Imports 📦
// --------------------------------------------------------------------------
// e.g.
// import Vue from 'vue';
import Vue from 'vue';
Vue.prototype.$bus = new Vue();
import store from './app/store/index';
import router from './app/router/router';
import './app/directives';
import VueObserveVisibility from 'vue-observe-visibility';
import AOS from 'aos';
import 'aos/dist/aos.css';
import lineClamp from 'vue-line-clamp';

AOS.init();

// Component Imports 🏗
// --------------------------------------------------------------------------
import OdometerCounter from './app/components/OdometerCounter.vue';
import CountDown from './app/components/CountDown.vue';
import BlogList from './app/components/BlogList.vue';
import BlogPost from './app/components/BlogPost.vue';
import ProposalForm from './app/components/ProposalForm.vue';
import OrganisationForm from './app/components/OrganisationForm.vue';
import CitizenForm from './app/components/CitizenForm.vue';
import ProposalIntro from './app/components/ProposalIntro.vue';
import NewslettersSignup from './app/components/NewslettersSignup.vue';
import GroupSelect from './app/components/GroupSelect.vue';
import TwitterFeed from './app/components/TwitterFeed.vue';
import ShoutBox from './app/components/ShoutBox';
import AlphabeticList from './app/components/AlphabeticList.vue';
import ExploreFilters from './app/components/ExploreFilters';
import SolutionsList from './app/components/Solutions/SolutionsList';
import EndorseBlock from './app/components/EndorseBlock';
import MobileExploreFilter from './app/components/MobileExploreFilter';
import SearchForm from './app/components/Search/SearchForm';
import SearchModal from './app/components/Search/SearchModal';

import { HasError, AlertError } from 'vform';

Vue.component('odometer-counter', OdometerCounter);
Vue.component('count-down', CountDown);
Vue.component('blog-list', BlogList);
Vue.component('blog-post', BlogPost);
Vue.component('organisation-form', OrganisationForm);
Vue.component('citizen-form', CitizenForm);
Vue.component('proposal-form', ProposalForm);
Vue.component('proposal-intro', ProposalIntro);
Vue.component('newsletters-signup', NewslettersSignup);
Vue.component('group-select', GroupSelect);
Vue.component(HasError.name, HasError);
Vue.component(AlertError.name, AlertError);
Vue.component('twitter-feed', TwitterFeed);
Vue.component('shout-box', ShoutBox);
Vue.component('alphabetic-list', AlphabeticList);
Vue.component('explore-filters', ExploreFilters);
Vue.component('solutions-list', SolutionsList);
Vue.component('endorse-block', EndorseBlock);
Vue.component('mobile-explore-filter', MobileExploreFilter);
Vue.component('search-form', SearchForm);
Vue.component('search-modal', SearchModal);

Vue.use(VueObserveVisibility);
Vue.use(lineClamp);

// Vue Social Sharing
import SocialSharing from 'vue-social-sharing';
Vue.use(SocialSharing);

// eslint-disable-next-line
const app = new Vue({
    el: '#app',
    store,
    router
});

// Vanilla
import StickyHeader from './app/StickyHeader.js';
import MainMenu from './app/MainMenu.js';
import Tabs from './app/Tabs.js';
import HeaderSlider from './app/HeaderSlider.js';
import TeamSlider from './app/TeamSlider.js';
import Carousel from './app/Carousel.js';
import PhotoGallery from './app/PhotoGallery.js';
import VideoBlock from './app/VideoBlock.js';
import Accordion from './app/Accordion';
import TruncateSummary from './app/TruncateSummary';
import LinkedProposalsSlider from './app/LinkedProposalsSlider';
import videoOverlay from './app/videoOverlay';

// App kickoff 🚀
// --------------------------------------------------------------------------

(function() {
    new StickyHeader();
    new MainMenu();
    new Tabs();
    new HeaderSlider();
    new TeamSlider();
    new Carousel();
    new PhotoGallery();
    new VideoBlock();
    new Accordion();
    new TruncateSummary();
    new LinkedProposalsSlider();
    videoOverlay();
})();
