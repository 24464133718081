var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form__section" }, [
    _c("h3", { staticClass: "form__section__title main-heading" }, [
      _vm._v("\n        Proposal description\n    ")
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "form__section__tip" }, [
      _vm._v(
        "\n        Briefly describe your proposal with a puchy title and tweet length\n        description.\n    "
      )
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "form__section__tip margin-b-74" }, [
      _vm._v(
        "\n        An opportunity is given to provide greater detail on your proposal within the following pages.\n    "
      )
    ]),
    _vm._v(" "),
    _c("h3", { staticClass: "form__section__title" }, [
      _vm._v("\n        What is the title of your proposal?\n    ")
    ]),
    _vm._v(" "),
    _c(
      "label",
      {
        staticClass: "form__label margin-b-74",
        attrs: { for: "proposal-title" }
      },
      [
        _c("span", { staticClass: "form__input__text" }, [
          _vm._v("Proposal title")
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.proposalTitle,
              expression: "proposalTitle"
            }
          ],
          staticClass: "form__input__value",
          class: {
            "max-limit": _vm.is_over_max_words,
            "invalid icon--form-attention": _vm.invalid_title === true
          },
          attrs: { id: "proposal-title", type: "text", name: "" },
          domProps: { value: _vm.proposalTitle },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.proposalTitle = $event.target.value
              },
              function($event) {
                return _vm.$emit("input", _vm.proposalTitle)
              }
            ]
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "form__input__count" }, [
          _vm._v("You have " + _vm._s(_vm.remainingWords) + " words remaining")
        ])
      ]
    ),
    _vm._v(" "),
    _c("h3", { staticClass: "form__section__title" }, [
      _vm._v("\n        Describe your global governance proposal\n    ")
    ]),
    _vm._v(" "),
    _c(
      "label",
      {
        staticClass: "form__label margin-b-74",
        attrs: { for: "proposal-description" }
      },
      [
        _c("span", { staticClass: "form__input__text" }, [
          _vm._v("Proposal description")
        ]),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.proposalDescription,
              expression: "proposalDescription"
            }
          ],
          staticClass: "form__textarea__value",
          class: { "max-limit": _vm.is_over_max_char },
          attrs: { id: "proposal-description", rows: "10" },
          domProps: { value: _vm.proposalDescription },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.proposalDescription = $event.target.value
              },
              function($event) {
                return _vm.$emit("input", _vm.proposalDescription)
              }
            ]
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "form__input__count" }, [
          _vm._v(
            "You have " +
              _vm._s(_vm.remainingCharacters) +
              " characters remaining"
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "form__section__button",
        class: { inactive: _vm.isDisabledForm },
        on: {
          click: function($event) {
            return _vm.continueToNext()
          }
        }
      },
      [_vm._v("\n        Continue "), _c("arrow-right")],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }