// Glide.js
// --------------------------------------------------------------------------
import Glide, { Controls, Breakpoints, Autoplay } from '@glidejs/glide/dist/glide.modular.esm';

class TeamSlider {
    constructor() {
        //Member-team slider
        // --------------------------------------------------------------------------
        var member_team = document.getElementById('member-team');
        if (member_team) {
            var list = member_team.getElementsByClassName('glide__slide');
            if (list && list.length) {
                var member_team = new Glide('#member-team', {
                    type: 'slider',
                    perView: 3,
                    breakpoints: {
                        720: {
                            gap: 15,
                            perView: 1
                        },
                        991: { perView: 2 },
                        1200: { perView: 3 }
                    }
                });
                member_team.mount({ Controls, Breakpoints, Autoplay });
            }
        }

    }
}

export default TeamSlider;
