//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'TextareaBlock',
    props: {
        rows: {
            type: Number,
            required: true
        },
        value: {
            type: String,
            required: true
        },
        maxWords: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            textareaValue: this.value
        };
    },
    computed: {
        isOverMax(){
            this.$emit('max-reached', this.remaining < 0);
            if (this.remaining < 0){
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.textareaValue = this.textareaValue.split(' ').splice(0, this.maxWords - 3).join(' ');
            }
            return this.remaining < 0;
        },
        remaining(){
            if (!this.textareaValue){
                return this.maxWords;
            }
            let value = this.textareaValue;
            let regex = /\s+/gi;
            let wordCount = value.trim().replace(regex, ' ').split(' ').length;
            return this.maxWords - wordCount;
        }
    },
    methods: {
        onKeyDown: function(evt){
            if (this.remaining <= 0) {
                if (evt.keyCode >= 48 && evt.keyCode <= 90) {
                    evt.preventDefault();
                    return;
                }
            }
            return true;
        },
    }
};
