var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    { staticClass: "search-component", on: { click: _vm.openModal } },
    [
      _c(
        "svg",
        {
          attrs: {
            width: "18px",
            height: "18px",
            viewBox: "0 0 18 18",
            version: "1.1",
            xmlns: "http://www.w3.org/2000/svg",
            "xmlns:xlink": "http://www.w3.org/1999/xlink"
          }
        },
        [
          _c("title", [_vm._v("Shape")]),
          _vm._v(" "),
          _c("desc", [_vm._v("Created with Sketch.")]),
          _vm._v(" "),
          _c(
            "g",
            {
              attrs: {
                id: "V3",
                stroke: "none",
                "stroke-width": "1",
                fill: "none",
                "fill-rule": "evenodd"
              }
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "search-icon-group",
                    transform: "translate(-1249.000000, -46.000000)",
                    fill: "#ffffff",
                    "fill-rule": "nonzero"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      id: "Shape",
                      d:
                        "M1261.75442,57.4148421 L1266.72242,62.3828421 L1265.38279,63.7224684 L1260.41479,58.7544684 C1259.2195,59.6685368 1257.72626,60.2106684 1256.10536,60.2106684 C1252.18079,60.2106684 1249.00009,57.0299737 1249.00009,53.1054053 C1249.00009,49.1808368 1252.18079,46.0001421 1256.10536,46.0001421 C1260.02993,46.0001421 1263.21062,49.1808368 1263.21062,53.1054053 C1263.21062,54.7263053 1262.66849,56.2195 1261.75442,57.4148421 L1261.75442,57.4148421 Z M1256.10526,58.3159316 C1258.98252,58.3159316 1261.31579,55.9826579 1261.31579,53.1054053 C1261.31579,50.2281526 1258.98252,47.8948789 1256.10526,47.8948789 C1253.22801,47.8948789 1250.89474,50.2281526 1250.89474,53.1054053 C1250.89474,55.9826579 1253.22801,58.3159316 1256.10526,58.3159316 Z"
                    }
                  })
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }