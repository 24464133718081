import Vue from 'vue';
import $ from 'jquery';

/**
 * Vue directive adding loader to any block (v-loading="true/false")
 */
Vue.directive('loading', {
    bind(el) {
        el.__$el = $(el);
        el.__originalPosition = el.__$el.css('position');
        el.__$loaderEl = $(`
            <div class="loading">
                <div class="loading--inner">
                    <span></span><span></span><span></span>
                </div>
            </div>
        `);
    },
    update(el, binding) {
        if (binding.value !== binding.oldValue) {
            if (binding.value) {
                el.__$el.css('position', 'relative');
                el.__$loaderEl.addClass('loading--trans-bg').prependTo(el.__$el);
            } else {
                el.__$el.css('position', el.__originalPosition);
                el.__$el.find('.loading').remove();
            }
        }
    },
    unbind(el) {
        delete el.__$el;
        delete el.__originalPosition;
        delete el.__$loaderEl;
    },
});
