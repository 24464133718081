//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios';
export default {
    name: 'SaveAndClosePopup',
    data(){
        return {
            submit_in_progress: false
        };
    },
    computed: {
        userEmail() {
            return this.$store.state.email;
        },
    },
    mounted() {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                this.$store.commit('SAVE_AND_CLOSE', false);
            }
        });
    },
    methods: {
        savePopup() {
            let obj = this.$store.state;
            let store = this.$store;
            this.submit_in_progress = true;
            axios.post('/api/proposal', {
                'is_form_complete': false,
                'email': obj.email,
                'is_anonymous': obj.is_anonymous,
                'first_name': obj.first_name,
                'last_name': obj.last_name,
                'middle_name': obj.middle_name,
                'is_organisation': obj.is_organisation,
                'organisation_name': obj.organisation_name,
                'title': obj.title,
                'description': obj.description,
                'catastrophic_risks': {
                    'climate_change': obj.catastrophic_risks.climate_change,
                    'eco_system_collapse': obj.catastrophic_risks.eco_system_collapse,
                    'pandemics': obj.catastrophic_risks.pandemics,
                    'weapons': obj.catastrophic_risks.weapons,
                    'emerging_technology': obj.catastrophic_risks.emerging_technology,
                    'unknown_risks': obj.catastrophic_risks.unknown_risks
                },
                'multipliers_risks': {
                    'conflict': obj.multipliers_risks.conflict,
                    'institutions': obj.multipliers_risks.institutions,
                    'poverty': obj.multipliers_risks.poverty,
                },
                'term': obj.term,
                'theory_of_change': {
                    'implementation_strategy': obj.theory_of_change.implementation_strategy,
                    'political_will':obj.theory_of_change.political_will,
                    'political_will_does_not_exist':obj.theory_of_change.political_will_does_not_exist,
                    'existing_road_maps': obj.theory_of_change.existing_road_maps,
                    'decision_makers':obj.theory_of_change.decision_makers,
                    'why_long_terms': obj.theory_of_change.why_long_terms
                },
                'mitigating':{
                    'pandemics': obj.mitigating.pandemics,
                    'weapons': obj.mitigating.weapons,
                    'climate_change': obj.mitigating.climate_change,
                    'eco_system_collapse': obj.mitigating.eco_system_collapse,
                    'emerging_technology': obj.mitigating.emerging_technology,
                    'unknown_risks': obj.mitigating.unknown_risks
                },
                'wider_implications': {
                    'alleviate_poverty_and_inequality':obj.wider_implications.alleviate_poverty_and_inequality,
                    'reducing_inclusivity_and_accountability_in_national':obj.wider_implications.reducing_inclusivity_and_accountability_in_national,
                    'increasing_conflict_and_political_violence':obj.wider_implications.increasing_conflict_and_political_violence,
                    'enhance_inclusivity_and_accountability_in_national':obj.wider_implications.enhance_inclusivity_and_accountability_in_national,
                    'increasing_poverty_and_inequality': obj.wider_implications.increasing_poverty_and_inequality,
                    'reduce_conflict_and_political_violencey': obj.wider_implications.reduce_conflict_and_political_violencey
                },
                'details': obj.details,
                'is_confirm': obj.is_confirm,
            })
                .then(function(response) {
                    if (response.status >= 200 && response.status < 300){
                        store.commit('SAVE_AND_CLOSE', false);
                        window.location.replace('/');
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
    }
};
