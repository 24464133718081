var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal-fade" } }, [
    _c("div", { staticClass: "modal-backdrop", on: { click: _vm.close } }, [
      _c(
        "div",
        {
          staticClass: "modal-backdrop__container",
          on: {
            click: function($event) {
              $event.stopPropagation()
            }
          }
        },
        [_vm._t("default")],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }