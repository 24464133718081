var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form-primary pl-lg-5",
      attrs: { action: "#", method: "post" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.save($event)
        },
        keydown: function($event) {
          return _vm.form.onKeydown($event)
        }
      }
    },
    [
      _c("div", { staticClass: "container-fluid pt-lg-2" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 col-xs-12 u-p0" }, [
            _c(
              "div",
              { staticClass: "form-primary__block" },
              [
                _c(
                  "label",
                  {
                    staticClass: "form-primary__label mb-2",
                    attrs: { for: "organisation_name" }
                  },
                  [_vm._v("Organisation name")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-primary__control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.organisation_name,
                        expression: "form.organisation_name"
                      }
                    ],
                    staticClass: "form-primary__field",
                    attrs: {
                      id: "organisation_name",
                      type: "text",
                      name: "organisation_name",
                      required: "required"
                    },
                    domProps: { value: _vm.form.organisation_name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.form,
                          "organisation_name",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("has-error", {
                  attrs: { form: _vm.form, field: "organisation_name" }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 col-xs-12 u-p0" }, [
            _c(
              "div",
              { staticClass: "form-primary__block" },
              [
                _c(
                  "label",
                  {
                    staticClass: "form-primary__label mb-2",
                    attrs: { for: "first_name" }
                  },
                  [_vm._v("First name")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-primary__control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.first_name,
                        expression: "form.first_name"
                      }
                    ],
                    staticClass: "form-primary__field",
                    attrs: {
                      id: "first_name",
                      type: "text",
                      name: "first_name",
                      required: "required"
                    },
                    domProps: { value: _vm.form.first_name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "first_name", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("has-error", {
                  attrs: { form: _vm.form, field: "first_name" }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 col-xs-12 u-p0" }, [
            _c(
              "div",
              { staticClass: "form-primary__block" },
              [
                _c(
                  "label",
                  {
                    staticClass: "form-primary__label mb-2",
                    attrs: { for: "last_name" }
                  },
                  [_vm._v("Last name")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-primary__control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.last_name,
                        expression: "form.last_name"
                      }
                    ],
                    staticClass: "form-primary__field",
                    attrs: {
                      id: "last_name",
                      type: "text",
                      name: "last_name",
                      required: "required"
                    },
                    domProps: { value: _vm.form.last_name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "last_name", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("has-error", {
                  attrs: { form: _vm.form, field: "first_name" }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 col-xs-12 u-p0" }, [
            _c("div", { staticClass: "form-primary__block" }, [
              _c(
                "label",
                {
                  staticClass: "form-primary__label mb-2",
                  attrs: { for: "email" }
                },
                [_vm._v("Email")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-primary__control" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.email,
                        expression: "form.email"
                      }
                    ],
                    staticClass: "form-primary__field",
                    attrs: {
                      id: "email",
                      type: "email",
                      name: "email",
                      required: "required"
                    },
                    domProps: { value: _vm.form.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "email", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("has-error", { attrs: { form: _vm.form, field: "email" } })
                ],
                1
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 col-xs-12 u-p0" }, [
            _c("div", { staticClass: "form-primary__block" }, [
              _c("label", { staticClass: "form-primary__label mb-2" }, [
                _vm._v(
                  "In what region is the headquarters of your organisation?"
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-primary__control" },
                [
                  _c("multiselect", {
                    attrs: {
                      options: _vm.options,
                      multiple: false,
                      placeholder: "Select a region",
                      "track-by": "name",
                      label: "name"
                    },
                    model: {
                      value: _vm.form.headquarter_location,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "headquarter_location", $$v)
                      },
                      expression: "form.headquarter_location"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 col-xs-12 u-p0" }, [
            _c("div", { staticClass: "form-primary__block mb-lg-5 mb-xs-3" }, [
              _c("p", { staticClass: "text text--medium mb-1" }, [
                _vm._v(
                  "\n                        In what region(s) does your organisation carry out its work?\n                    "
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text text--small text--grey mb-3" }, [
                _vm._v(
                  "\n                        Please select all which apply\n                    "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "checkbox__collections" }, [
                _c("div", { staticClass: "checkbox__collections-wrap row" }, [
                  _c("div", { staticClass: "checkbox__wrap col-lg-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "checkbox__label",
                        class: [
                          {
                            "checkbox__label--checked":
                              _vm.form.regions.western_europe_north_america
                          }
                        ]
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.form.regions.western_europe_north_america,
                              expression:
                                "form.regions.western_europe_north_america"
                            }
                          ],
                          staticClass: "checkbox__input",
                          attrs: { type: "checkbox", name: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.form.regions.western_europe_north_america
                            )
                              ? _vm._i(
                                  _vm.form.regions.western_europe_north_america,
                                  null
                                ) > -1
                              : _vm.form.regions.western_europe_north_america
                          },
                          on: {
                            change: function($event) {
                              var $$a =
                                  _vm.form.regions.western_europe_north_america,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form.regions,
                                      "western_europe_north_america",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form.regions,
                                      "western_europe_north_america",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.form.regions,
                                  "western_europe_north_america",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "text text--medium text--bold" },
                          [
                            _vm._v(
                              "Western Europe, North America or Australasia"
                            )
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "checkbox__wrap col-lg-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "checkbox__label",
                        class: [
                          {
                            "checkbox__label--checked":
                              _vm.form.regions.eastern_europe
                          }
                        ]
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.regions.eastern_europe,
                              expression: "form.regions.eastern_europe"
                            }
                          ],
                          staticClass: "checkbox__input",
                          attrs: { type: "checkbox", name: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.form.regions.eastern_europe
                            )
                              ? _vm._i(_vm.form.regions.eastern_europe, null) >
                                -1
                              : _vm.form.regions.eastern_europe
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.form.regions.eastern_europe,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form.regions,
                                      "eastern_europe",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form.regions,
                                      "eastern_europe",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.form.regions,
                                  "eastern_europe",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "text text--medium text--bold" },
                          [_vm._v("Eastern Europe")]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "checkbox__wrap col-lg-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "checkbox__label",
                        class: [
                          {
                            "checkbox__label--checked": _vm.form.regions.africa
                          }
                        ]
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.regions.africa,
                              expression: "form.regions.africa"
                            }
                          ],
                          staticClass: "checkbox__input",
                          attrs: { type: "checkbox", name: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.form.regions.africa)
                              ? _vm._i(_vm.form.regions.africa, null) > -1
                              : _vm.form.regions.africa
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.form.regions.africa,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form.regions,
                                      "africa",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form.regions,
                                      "africa",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.form.regions, "africa", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "text text--medium text--bold" },
                          [_vm._v("Africa")]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "checkbox__wrap col-lg-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "checkbox__label",
                        class: [
                          {
                            "checkbox__label--checked":
                              _vm.form.regions.asia_middle_east
                          }
                        ]
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.regions.asia_middle_east,
                              expression: "form.regions.asia_middle_east"
                            }
                          ],
                          staticClass: "checkbox__input",
                          attrs: { type: "checkbox", name: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.form.regions.asia_middle_east
                            )
                              ? _vm._i(
                                  _vm.form.regions.asia_middle_east,
                                  null
                                ) > -1
                              : _vm.form.regions.asia_middle_east
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.form.regions.asia_middle_east,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form.regions,
                                      "asia_middle_east",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form.regions,
                                      "asia_middle_east",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.form.regions,
                                  "asia_middle_east",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "text text--medium text--bold" },
                          [_vm._v("Asia and Middle East")]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "checkbox__wrap col-lg-6" }, [
                    _c(
                      "label",
                      {
                        staticClass: "checkbox__label",
                        class: [
                          {
                            "checkbox__label--checked":
                              _vm.form.regions.latin_america
                          }
                        ]
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.regions.latin_america,
                              expression: "form.regions.latin_america"
                            }
                          ],
                          staticClass: "checkbox__input",
                          attrs: { type: "checkbox", name: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.form.regions.latin_america
                            )
                              ? _vm._i(_vm.form.regions.latin_america, null) >
                                -1
                              : _vm.form.regions.latin_america
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.form.regions.latin_america,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form.regions,
                                      "latin_america",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form.regions,
                                      "latin_america",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.form.regions, "latin_america", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "text text--medium text--bold" },
                          [_vm._v("Latin America or Caribbean")]
                        )
                      ]
                    )
                  ])
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 col-xs-12 u-p0" }, [
            _c(
              "div",
              { staticClass: "form-primary__block" },
              [
                _c(
                  "label",
                  {
                    staticClass: "form-primary__label mb-2",
                    attrs: { for: "hear_about" }
                  },
                  [_vm._v("Where did you hear about this campaign?")]
                ),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.hear_about,
                      expression: "form.hear_about"
                    }
                  ],
                  staticClass: "form-primary__field",
                  class: { "has-error": _vm.form.errors.has("hear_about") },
                  attrs: {
                    id: "hear_about",
                    name: "hear_info",
                    cols: "30",
                    rows: "4"
                  },
                  domProps: { value: _vm.form.hear_about },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "hear_about", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("has-error", {
                  attrs: { form: _vm.form, field: "hear_about" }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 col-xs-12 u-p0" }, [
            _c(
              "div",
              { staticClass: "form-primary__block" },
              [
                _c(
                  "label",
                  {
                    staticClass: "form-primary__label mb-2",
                    attrs: { for: "want_to_sing_up" }
                  },
                  [_vm._v("What made your organisation want to sign up?")]
                ),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.want_to_sing_up,
                      expression: "form.want_to_sing_up"
                    }
                  ],
                  staticClass: "form-primary__field",
                  class: {
                    "has-error": _vm.form.errors.has("want_to_sing_up")
                  },
                  attrs: {
                    id: "want_to_sing_up",
                    name: "organisation_info",
                    cols: "30",
                    rows: "4"
                  },
                  domProps: { value: _vm.form.want_to_sing_up },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "want_to_sing_up", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("has-error", {
                  attrs: { form: _vm.form, field: "want_to_sing_up" }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 col-xs-12 u-p0" }, [
            _c("div", { staticClass: "form-primary__block" }, [
              _c(
                "label",
                {
                  staticClass: "form-primary__label mb-2",
                  attrs: { for: "twitter" }
                },
                [
                  _vm._v(
                    "\n                        Organisation twitter handle\n                    "
                  )
                ]
              ),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.twitter,
                    expression: "form.twitter"
                  }
                ],
                staticClass: "form-primary__field",
                class: { "has-error": _vm.form.errors.has("twitter") },
                attrs: { id: "twitter", name: "info", cols: "30", rows: "1" },
                domProps: { value: _vm.form.twitter },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "twitter", $event.target.value)
                  }
                }
              })
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 col-xs-12 u-p0" }, [
            _c("div", { staticClass: "form-primary__block" }, [
              _c(
                "label",
                {
                  staticClass: "form-primary__label mb-2",
                  attrs: { for: "website" }
                },
                [_vm._v("Organisation website")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.website,
                    expression: "form.website"
                  }
                ],
                staticClass: "form-primary__field",
                attrs: { id: "website", type: "text" },
                domProps: { value: _vm.form.website },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "website", $event.target.value)
                  }
                }
              })
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 col-xs-12 u-p0" }, [
            _c(
              "div",
              { staticClass: "checkbox__wrap checkbox__wrap--modified" },
              [
                _c(
                  "label",
                  {
                    staticClass: "checkbox__label",
                    class: [
                      { "checkbox__label--checked-input": _vm.form.terms }
                    ],
                    attrs: { for: "terms" }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.terms,
                          expression: "form.terms"
                        }
                      ],
                      staticClass: "checkbox__input input--small",
                      attrs: {
                        id: "terms",
                        type: "checkbox",
                        name: "checkbox"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.form.terms)
                          ? _vm._i(_vm.form.terms, null) > -1
                          : _vm.form.terms
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.form.terms,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(_vm.form, "terms", $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.form,
                                  "terms",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.form, "terms", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _c("has-error", {
                      attrs: { form: _vm.form, field: "terms" }
                    })
                  ],
                  1
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row justify-content-end" }, [
          _c("div", { staticClass: "mt-lg-4 mt-3 mb-4" }, [
            _c(
              "button",
              {
                staticClass: "btn btn--orange",
                class: _vm.form.busy ? "btn--disabled" : "",
                attrs: { disabled: _vm.form.busy, type: "submit" }
              },
              [_vm._v("\n                    Sign me up!\n                ")]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text" }, [
      _vm._v(
        "\n                            Tick this box to show that your organisation "
      ),
      _c(
        "a",
        {
          staticClass: "text--black link--underline",
          attrs: {
            href: "pdf/Together-First-values-statement.pdf",
            download: ""
          }
        },
        [_vm._v("supports the values")]
      ),
      _vm._v(
        " of the Together First campaign and is happy to be listed\n                            as a supporter of the campaign in Together First communications, including on the website.  Please note, becoming a supporter\n                            does not mean your organisation has to endorse or take a position on any specific reforms or proposals identified by\n                            Together First - this caveat will be clearly stated on our supporter page. The Together First secretariat will never sell\n                            or share your email address.\n                        "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }