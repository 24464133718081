var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form__section" },
    [
      _c("h3", { staticClass: "form__section__title main-heading" }, [
        _vm._v("\n        Wider implications of your proposal\n    ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.prevSection === false,
              expression: "prevSection === false"
            }
          ],
          staticClass: "form__section__empty margin-b-74"
        },
        [
          _c(
            "p",
            { staticClass: "form__section__tip" },
            [
              _vm._v(
                "\n            These questions are dependent upon your answers to the questions within the "
              ),
              _c(
                "router-link",
                {
                  staticClass: "form-link",
                  attrs: { to: "/proposal/form/proposal-categorisation" }
                },
                [
                  _vm._v(
                    "\n                Proposal categorisation\n            "
                  )
                ]
              ),
              _vm._v(" section.\n        ")
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0)
        ]
      ),
      _vm._v(" "),
      _vm.risks.poverty === true &&
      _vm.risks.institutions === true &&
      _vm.risks.conflict === true
        ? _c("wider-implications-pov-incl-violence")
        : _vm.risks.poverty === true && _vm.risks.institutions === true
        ? _c("wider-implications-pov-incl")
        : _vm.risks.poverty === true && _vm.risks.conflict === true
        ? _c("wider-implications-pov-violence")
        : _vm.risks.institutions === true && _vm.risks.conflict === true
        ? _c("wider-implications-incl-violence")
        : _vm.risks.poverty === true
        ? _c("wider-implications-pov")
        : _vm.risks.institutions === true
        ? _c("wider-implications-incl")
        : _vm.risks.conflict === true
        ? _c("wider-implications-violence")
        : _vm._e(),
      _vm._v(" "),
      _vm.risks.poverty === false &&
      _vm.risks.institutions === false &&
      _vm.risks.conflict === false &&
      _vm.prevSection === true
        ? _c("wider-implications-none")
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "form__section__tip--attention" }, [
      _c("span", { staticClass: "icon--form-attention" }),
      _vm._v(
        "\n            Please complete all questions within Proposal categorisation\n        "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }