//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: 'SubmitSuccess',
    methods: {
        savePopup() {
            window.location.replace('/');
        }
    }
};
