//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
export default {
    props: {
        initialDate: {
            type: String,
            required: true,
        },
        strokeWidth: {
            type: Number,
            default: 5
        },
        daysStrokeColor: {
            type: String,
            default: '#000000'
        },
        secondsStrokeColor: {
            type: String,
            default: '#acdb28'
        },
        minutesStrokeColor: {
            type: String,
            default: '#729adb'
        },
        hoursStrokeColor: {
            type: String,
            default: '#db47a0'
        },
        underneathStrokeColor: {
            type: String,
            default: '#eee'
        },
        daysFillColor: {
            type: String,
            default: 'none'
        },
        secondsFillColor: {
            type: String,
            default: 'none'
        },
        minutesFillColor: {
            type: String,
            default: 'none'
        },
        hoursFillColor: {
            type: String,
            default: 'none'
        },
        size: {
            type: Number,
            default: 0
        },
        padding: {
            type: Number,
            default: 5
        },
        dayLabel: {
            type: String,
            default: 'days'
        },
        hourLabel: {
            type: String,
            default: 'hours'
        },
        minuteLabel: {
            type: String,
            default: 'minutes'
        },
        secondLabel: {
            type: String,
            default: 'seconds'
        },
        showSecond: {
            type: Boolean,
            default: true
        },
        showMinute: {
            type: Boolean,
            default: true
        },
        showHour: {
            type: Boolean,
            default: true
        },
        showDay: {
            type: Boolean,
            default: true
        },
        showNegatives: {
            type: Boolean,
            default: false
        },
        steps: {
            type: Number,
            default: undefined
        },
        paused: {
            type: Boolean,
            default: false
        },
        notifyEvery: {
            type: String,
            default: 'second',
            validator: (val) => ['second', 'minute', 'hour','day', 'none'].includes(val)
        }
    },
    data() {
        return {
            isMounted: false,
            value: 0,
            labelFontRatio: 0.15,
            numberFontRatio: 0.2,
            baseTime: 0,
            isVisible: false
        };
    },
    computed: {
        seconds_step() {
            return this.is_single ? this.steps ? (Math.max(this.steps, this.value)) : this.value : 60;
        },
        is_single() {
            return this.steps !== undefined || (!this.showHour && !this.showMinute);
        },
        days() {
            return Math.floor(Math.abs(this.value) / (24*60*60));
        },
        hours() {
            return Math.floor((Math.abs(this.value) - this.days * 24 * 60 * 60) / (60*60));
        },
        minutes() {
            return Math.floor((Math.abs(this.value) - this.days * 24* 60 * 60 - this.hours * 3600) / 60);
        },
        seconds() {
            return this.is_single ? this.value : Math.abs(this.value) % 60;
        },
        factor() {
            return this.value >= 0 ? 1 : -1;
        },
        circle_length() {
            return 2 * Math.PI * this.r;
        },
        day_step_length() {
            return this.circle_length / 365;
        },
        hour_step_length() {
            return this.circle_length / 24;
        },
        minute_step_length() {
            return this.circle_length / 60;
        },
        second_step_length() {
            return this.circle_length / this.seconds_step;
        },
        stroke_dasharray_day() {
            return this.circle_length;
        },
        stroke_dasharray_hour() {
            return this.circle_length;
        },
        stroke_dasharray_minute() {
            return this.circle_length;
        },
        stroke_dasharray_second() {
            return this.circle_length;
        },
        stroke_dashoffset_day(){
            return this.circle_length - this.days * this.day_step_length;
        },
        stroke_dashoffset_hour(){
            return this.circle_length - this.hours * this.hour_step_length;
        },
        stroke_dashoffset_minute(){
            return this.circle_length - this.minutes * this.minute_step_length;
        },
        stroke_dashoffset_second(){
            return this.circle_length - this.seconds * this.second_step_length;
        },
        cx(){
            return this.inner_size/2;
        },
        cy(){
            return this.inner_size/2;
        },
        r(){
            return (this.inner_size - this.strokeWidth)/2;
        },
        inner_size(){
            return this.circle_size - this.padding*2;
        },
        circle_size(){
            const size = this.size === 0 && this.isMounted ? this.$refs.wrapper.parentElement.clientHeight : this.size;
            return this.has_label ? size - size * this.labelFontRatio : size;
        },
        has_label(){
            return this.dayLabel !== '' || this.hourLabel !== '' || this.minuteLabel !== '' || this.secondLabel !== '' ;
        }
    },
    watch: {
        seconds(){
            if (this.notifyEvery === 'second'){
                this.notifyChange();
            }
        },
        minutes(){
            if (this.notifyEvery === 'minute' && !this.is_single){
                this.notifyChange();
            }
        },
        hours(){
            if (this.notifyEvery === 'hour' && !this.is_single){
                this.notifyChange();
            }
        },
        days(){
            if (this.notifyEvery === 'day' && !this.is_single){
                this.notifyChange();
            }
        }
    },
    created() {
        const b = moment();
        const a = moment(this.initialDate, 'DD-MM-YYYY');
        this.value =  a.diff(b, 'seconds');
    },
    mounted: function() {
        this.$nextTick(() => {
            this.isMounted = true;
            if (this.value){
                const interval = setInterval(function() {
                    if (this.paused){
                        return;
                    }
                    const delta = 1;
                    this.value -= delta;
                    if (this.value === 0){
                        this.$emit('finish');
                    }
                    if (this.value <= 0 && !this.showNegatives){
                        this.value = 0;
                        clearInterval(interval);
                    }
                }.bind(this), 1000);
            }
        });
    },
    methods: {
        notifyChange() {
            let output = {value: this.value};
            this.$emit('update', output);
        },
        updateTime(seconds){
            if (this.value)
                this.value+=seconds;
            if (this.value<0){
                this.$emit('finish');
            }
        },
        countDownAnimation(isVisible, entry){
            this.isVisible = isVisible;
            if (entry.isIntersecting){
                let circles = document.querySelectorAll('.circle_animation');
                for (let i = 0; i < circles.length; ++i) {
                    circles[i].classList.add('animation_start');
                }
            }
        }
    },
};
