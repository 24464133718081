var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form__section__implications" }, [
    _c("h4", { staticClass: "form__section__title" }, [
      _vm._v(
        "\n        How will this proposal alleviate poverty and inequality?\n    "
      )
    ]),
    _vm._v(" "),
    _c(
      "label",
      {
        staticClass: "form__label margin-b-74",
        attrs: { for: "wider-implications-pov-incl-one" }
      },
      [
        _c("textarea-block", {
          attrs: {
            id: "wider-implications-pov-incl-one",
            "max-words": 300,
            rows: 10
          },
          model: {
            value: _vm.alleviatePovertyAndInequality,
            callback: function($$v) {
              _vm.alleviatePovertyAndInequality = $$v
            },
            expression: "alleviatePovertyAndInequality"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("h4", { staticClass: "form__section__title" }, [
      _vm._v(
        "\n        How will this proposal enhance inclusivity and accountability in national and global governance?\n    "
      )
    ]),
    _vm._v(" "),
    _c(
      "label",
      {
        staticClass: "form__label margin-b-74",
        attrs: { for: "wider-implications-pov-incl-two" }
      },
      [
        _c("textarea-block", {
          attrs: {
            id: "wider-implications-pov-incl-two",
            "max-words": 300,
            rows: 10
          },
          model: {
            value: _vm.enhanceInclusivity,
            callback: function($$v) {
              _vm.enhanceInclusivity = $$v
            },
            expression: "enhanceInclusivity"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("h4", { staticClass: "form__section__title" }, [
      _vm._v(
        "\n        Could aspects of this proposal have the converse effect of increasing conflict and political violence?\n    "
      )
    ]),
    _vm._v(" "),
    _c(
      "label",
      {
        staticClass: "form__label margin-b-74",
        attrs: { for: "wider-implications-pov-incl-three" }
      },
      [
        _c("textarea-block", {
          attrs: {
            id: "wider-implications-pov-incl-three",
            "max-words": 150,
            rows: 10
          },
          model: {
            value: _vm.increaseConflict,
            callback: function($$v) {
              _vm.increaseConflict = $$v
            },
            expression: "increaseConflict"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "form__section__button",
        class: { inactive: _vm.isDisabled },
        on: {
          click: function($event) {
            return _vm.completeSection()
          }
        }
      },
      [_vm._v("\n        Continue "), _c("arrow-right")],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }