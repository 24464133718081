export default () => {
    let overlay = document.querySelector('#video-overlay');
    let body = document.querySelector('body');
    let closeBtn = document.querySelector('#js-close-video-overlay');
    let iframe = overlay.querySelector('iframe');

    if (overlay) {
        body.style.overflow = 'hidden';

        closeBtn.addEventListener('click', function() {
            overlay.classList.remove('is-open');

            body.style.overflow = 'auto';

            iframe.setAttribute('src', '');
        });
    }
};
