var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "blog__row" },
      _vm._l(_vm.articles, function(article) {
        return _c("blog-post", {
          key: article.slug,
          attrs: { article: article }
        })
      }),
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-lg-12 mt-5 pt-lg-5" }, [
      _c("div", { staticClass: "text-center" }, [
        _vm.showMoredata
          ? _c(
              "button",
              {
                staticClass: "btn btn--orange btn--large",
                on: { click: _vm.loadMore }
              },
              [_vm._v("\n                Load more\n            ")]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }