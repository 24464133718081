var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "solution-item" }, [
    _c("div", { staticClass: "solution-item__meta" }, [
      _c("span", [_vm._v(_vm._s(_vm.item.author))]),
      _vm._v(" "),
      _c("span", [_vm._v("•")]),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.item.catastrophic_risks_list))])
    ]),
    _vm._v(" "),
    _c(
      "a",
      { staticClass: "solution-item__title", attrs: { href: _vm.item.url } },
      [_vm._v("\n        " + _vm._s(_vm.item.title) + "\n    ")]
    ),
    _vm._v(" "),
    _c(
      "p",
      {
        directives: [
          {
            name: "line-clamp",
            rawName: "v-line-clamp",
            value: 3,
            expression: "3"
          }
        ],
        staticClass: "solution-item__excerpt"
      },
      [_vm._v("\n        " + _vm._s(_vm.item.description) + "\n    ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }