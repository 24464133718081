//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'SolutionItem',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
};
