//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArrowRight from './assets/ArrowRight.vue';
export default {
    name: 'Proposal',
    components: {
        'arrow-right': ArrowRight
    },
    data() {
        return {
            max_words_title: 12,
            max_characters_description: 280,
            is_over_max_char: false,
            is_over_max_words: false,
            invalid_title: false
        };
    },
    computed:{
        proposalDescription: {
            get() {
                return this.$store.state.description;
            },
            set(value) {
                this.$store.commit('PROPOSAL_DESCRIPTION', value);
            },
        },
        proposalTitle: {
            get() {
                return this.$store.state.title;
            },
            set(value) {
                this.$store.commit('PROPOSAL_TITLE', value);
            },
        },
        remainingCharacters(){
            if (!this.proposalDescription){
                return this.max_characters_description;
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.is_over_max_char = (this.max_characters_description - this.proposalDescription.length) < 0;
            return this.max_characters_description - this.proposalDescription.length;
        },
        remainingWords(){
            if (!this.proposalTitle){
                return this.max_words_title;
            }
            let value = this.proposalTitle;
            let regex = /\s+/gi;
            let wordCount = value.trim().replace(regex, ' ').split(' ').length;

            this.$store.commit('PROPOSAL_TITLE', value);

            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.is_over_max_words = (this.max_words_title - wordCount) < 0;
            return this.max_words_title - wordCount;
        },
        isDisabledForm(){
            let proposalState = this.$store.state;
            if (proposalState.title === '' || this.remainingWords < 0 || this.remainingCharacters < 0){
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        continueToNext(){
            if (this.isDisabledForm === false){
                this.$store.commit('PROPOSAL_COMPLETED', true);
                this.invalid_title = false;
                this.$router.push('/proposal/form/proposal-categorisation');
            } else {
                this.invalid_title = true;
            }
        }
    }
};
