//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SaveAndClosePopup from './blocks/elements/SaveAndClosePopup.vue';
import SubmitSuccess from './blocks/elements/SubmitSuccess.vue';
import AboutYou from './blocks/AboutYou.vue';
import Proposal from './blocks/Proposal.vue';
import ProposalCategorisation from './blocks/ProposalCategorisation.vue';
import TheoryOfChange from './blocks/TheoryOfChange.vue';
import MitigatingRisks from './blocks/MitigatingRisks.vue';
import WiderImplications from './blocks/WiderImplications.vue';
import ProposalInDetail from './blocks/ProposalInDetail.vue';
import ArrowRight from './blocks/assets/ArrowRight.vue';
import Check from './blocks/assets/Check.vue';

export default {
    components: {
        'save-and-close-popup': SaveAndClosePopup,
        'about-you': AboutYou,
        'proposal': Proposal,
        'proposal-categorisation': ProposalCategorisation,
        'theory-of-change': TheoryOfChange,
        'arrow-right': ArrowRight,
        'mitigating-risks': MitigatingRisks,
        'wider-implications': WiderImplications,
        'proposal-in-detail': ProposalInDetail,
        'check': Check,
        'submit-success': SubmitSuccess
    },
    data(){
        return {
            state: this.$store.state,
            empty_state: this.$store.state.proposalCategorisation.isCompleted,
            drop_down: false,
        };
    },
    computed: {
        routerName() {
            return this.$route.name;
        },
    },
    methods: {
        saveAndClose(){
            this.$store.commit('SAVE_AND_CLOSE', true);
        },
        dropdownMenu: function(){
            this.drop_down = !this.drop_down;
        }
    }
};
