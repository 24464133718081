var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.is_submit_visible,
          expression: "is_submit_visible"
        }
      ],
      staticClass: "form__section"
    },
    [
      _c("h3", { staticClass: "form__section__title main-heading" }, [
        _vm._v("\n        Describe your proposal in detail\n    ")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "form__section__tip margin-b-74" }, [
        _vm._v(
          "\n        This space is provided to allow you to further describe your proposal in detail in additional to any other\n        further details which you have not had chance to mention.\n    "
        )
      ]),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "form__label", attrs: { for: "proposal-in-detail" } },
        [
          _c("span", { staticClass: "form__input__text" }, [
            _vm._v(
              "Please include any html links to additional information which would support\n            your proposal"
            )
          ]),
          _vm._v(" "),
          _c("textarea-block", {
            attrs: { id: "proposal-in-detail", "max-words": 1000, rows: 20 },
            on: {
              "max-reached": function($event) {
                _vm.invalid_pid = $event
              }
            },
            model: {
              value: _vm.proposalInDetailFull,
              callback: function($$v) {
                _vm.proposalInDetailFull = $$v
              },
              expression: "proposalInDetailFull"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { staticClass: "form__section__tip" }, [
        _vm._v("\n        Attach a PDF file to your proposal (optional)\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form__upload-file margin-b-74" }, [
        _c("input", {
          ref: "files",
          staticClass: "inputfile inputfile-5",
          attrs: {
            id: "input-file-pdf",
            type: "file",
            name: "file",
            "data-multiple-caption": "{count} files selected",
            multiple: "",
            accept: ".pdf"
          }
        }),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c(
          "button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.id_upload_empty,
                expression: "id_upload_empty"
              }
            ],
            staticClass: "remove-file-upload",
            on: {
              click: function($event) {
                return _vm.removeUpload()
              }
            }
          },
          [_vm._v("\n            Remove\n        ")]
        )
      ]),
      _vm._v(" "),
      _c("label", { staticClass: "form__statement-check" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.legalStatementState,
              expression: "legalStatementState"
            }
          ],
          attrs: { id: "statement-check", type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.legalStatementState)
              ? _vm._i(_vm.legalStatementState, null) > -1
              : _vm.legalStatementState
          },
          on: {
            change: function($event) {
              var $$a = _vm.legalStatementState,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.legalStatementState = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.legalStatementState = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.legalStatementState = $$c
              }
            }
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "checkmark" }),
        _vm._v(" "),
        _vm._m(1)
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "proposal__submit",
          class: { inactive: _vm.isDisabled || _vm.inactive_submit },
          on: {
            click: function($event) {
              return _vm.submitProposal()
            }
          }
        },
        [_vm._v("\n        Submit proposal "), _c("arrow-right")],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "input-file-pdf" } }, [
      _c("span", { staticClass: "choose-file-text icon--form-pdf" }, [
        _vm._v("Choose a file")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "choose-file-name" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "statement-check-text" }, [
      _vm._v("I confirm that I have read the\n            "),
      _c(
        "a",
        {
          attrs: {
            href: "pdf/Together-First-values-statement.pdf",
            target: "_blank"
          }
        },
        [_vm._v("Together First Statement")]
      ),
      _vm._v(
        " of values and\n            that this idea is compatible with those values"
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }