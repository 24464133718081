//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios';
import Modal from './Modal';

export default {
    components: {
        Modal
    },
    props: {
        endorseRoute: {
            type: String,
            required: true,
        },
        endorsements: {
            type: Array,
            required: false,
            default: () => []
        },
        endorsementsCount: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            is_modal_open: false,
            sending: false,
            endorsement: {
                name: null,
                from: null,
                email: null,
                sent: false
            },
            endorsement_verified: false
        };
    },
    computed: {
        isEnabled: function() {
            return (this.endorsement.name && this.endorsement.from && this.endorsement.email && !this.sending);
        },
        fillStyle: function() {
            if (this.endorsement.sent) {
                return '#fa6400';
            }

            return '#ffffff';
        },
        endorseString: function() {
            if (this.sending) {
                return 'Sending';
            }

            return 'Endorse';
        }
    },
    watch: {
        '$route': {
            handler: function() {
                if (this.$route.query.endorse_success) {
                    this.is_modal_open = true;
                    this.endorsement_verified = true;

                    let query = Object.assign({}, this.$route.query);
                    delete query.endorse_success;
                    this.$router.replace({ query });
                }
            },
            immediate: true
        }
    },
    methods: {
        openModal() {
            if (!this.endorsement.sent) {
                this.is_modal_open = true;
            }
        },
        closeModal() {
            this.is_modal_open = false;

            setTimeout(() => {
                this.endorsement_verified = false;
            }, 300);
        },
        endorse: function() {
            this.sending = true;
            axios.post(this.endorseRoute, {
                name: this.endorsement.name,
                from: this.endorsement.from,
                email: this.endorsement.email
            })
                .then(res => {
                    this.sending = false;

                    if (res.status === 201) {
                        this.endorsement.sent = true;
                    }
                })
                .catch(err => {
                    this.sending = false;
                    console.log(err);
                });
        }
    }
};
