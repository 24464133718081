var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shoutbox__wrap", attrs: { id: "shoutbox_id" } },
    [
      _vm.show_form
        ? _c("div", { staticClass: "shoutbox__count" }, [
            _vm._v("\n        " + _vm._s(_vm.textLength) + "/240\n    ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm.shoutbox_slider
        ? _c(
            "div",
            {
              staticClass: "shoutbox__present glide",
              attrs: { id: "shout-box-slider" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "glide__track",
                  attrs: { "data-glide-el": "track" }
                },
                [
                  _c(
                    "ul",
                    { staticClass: "glide__slides" },
                    _vm._l(_vm.shouts[0], function(item) {
                      return _c(
                        "li",
                        {
                          key: item.id,
                          staticClass: "glide__slide shoutbox__slider-item"
                        },
                        [
                          _c("div", {
                            staticClass: "shoutbox__slider-item-text",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.messageAnimation(item.message_new)
                              )
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "shoutbox__slider-item-name" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.name_new) +
                                  "\n                    "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "shoutbox__slider-item-location" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.location_new) +
                                  "\n                    "
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.loading
                    ? _c("div", { staticClass: "shoutbox__loading" }, [
                        _c("img", {
                          attrs: { src: "images/loading.gif", alt: "" }
                        })
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "shoutbox__start-form",
                  on: {
                    click: function($event) {
                      return _vm.switchBlocks(true, false, false)
                    }
                  }
                },
                [_vm._v("\n            Tell something to the world\n        ")]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.show_form
        ? _c(
            "form",
            {
              ref: "shoutBoxForm",
              staticClass: "shoutbox__form",
              attrs: { action: "", method: "post" },
              on: { submit: _vm.submitShout }
            },
            [
              _c("span", { staticClass: "shoutbox__form-text" }, [
                _vm._v(
                  "What is the single most important reform needed to improve global governance? Have your say\n            in 240 characters and it will be included (anonymously) in our consultation to the UN75 team. GO!"
                )
              ]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.shoutbox,
                    expression: "shoutbox"
                  }
                ],
                staticClass: "shoutbox__text",
                class: { "required-notice": _vm.is_empty_text },
                attrs: { rows: "2", placeholder: "Start typing..." },
                domProps: { value: _vm.shoutbox },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.shoutbox = $event.target.value
                    },
                    function($event) {
                      return _vm.$emit("input", _vm.shoutbox)
                    }
                  ],
                  keydown: _vm.onKeyDownText
                }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.shoutboxName,
                    expression: "shoutboxName"
                  }
                ],
                staticClass: "shoutbox__name",
                class: { "required-notice": _vm.is_empty_name },
                attrs: { type: "text", placeholder: "My name is" },
                domProps: { value: _vm.shoutboxName },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.shoutboxName = $event.target.value
                    },
                    function($event) {
                      return _vm.$emit("input", _vm.shoutboxName)
                    }
                  ],
                  keydown: _vm.onKeyDownName
                }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.shoutboxLocation,
                    expression: "shoutboxLocation"
                  }
                ],
                staticClass: "shoutbox__location",
                class: { "required-notice": _vm.is_empty_location },
                attrs: { type: "text", placeholder: "and I am from" },
                domProps: { value: _vm.shoutboxLocation },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.shoutboxLocation = $event.target.value
                    },
                    function($event) {
                      return _vm.$emit("input", _vm.shoutboxLocation)
                    }
                  ],
                  keydown: _vm.onKeyDownLocation
                }
              }),
              _vm._v(" "),
              _c("input", {
                staticClass: "shoutbox__submit",
                class: { disabled: _vm.isDisabled },
                attrs: { type: "submit", value: "Say it" },
                on: { keydown: _vm.onKeyDownSubmit }
              }),
              _vm._v(" "),
              _vm.is_empty_location
                ? _c("span", { staticClass: "required-fields" }, [
                    _vm._v("\n            Location is required")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.is_empty_name
                ? _c("span", { staticClass: "required-fields" }, [
                    _vm._v("\n            Name is required")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.is_empty_text
                ? _c("span", { staticClass: "required-fields" }, [
                    _vm._v("\n            Message is required")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "required-fields" }, [
                _vm._v(
                  "\n            Please tell us your idea, name and location"
                )
              ]),
              _vm._v(" "),
              _vm.loading
                ? _c("div", { staticClass: "shoutbox__loading" }, [
                    _c("img", { attrs: { src: "images/loading.gif", alt: "" } })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.loading_failed
                ? _c("div", { staticClass: "shoutbox__loading" }, [
                    _c("p", { staticClass: "required-fields" }, [
                      _vm._v(
                        "\n                Oops, something went wrong. Please try again later\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "shoutbox__start-form",
                        on: {
                          click: function($event) {
                            return _vm.refreshPage()
                          }
                        }
                      },
                      [_vm._v("\n                Refresh page\n            ")]
                    )
                  ])
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.form_submitted
        ? _c("div", { staticClass: "shoutbox__submitted" }, [
            _c("p", { staticClass: "shoutbox__submitted-message" }, [
              _vm._v(
                '\n            "' +
                  _vm._s(_vm.$store.state.shoutbox) +
                  '"\n        '
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "shoutbox__submitted-name" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$store.state.shoutbox_name) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "shoutbox__submitted-location" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$store.state.shoutbox_location) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "shoutbox__submitted-actions" }, [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "shoutbox__submitted-tweet",
                  attrs: {
                    target: "_blank",
                    href: _vm.image_data_url,
                    download: ""
                  }
                },
                [
                  _c("img", {
                    attrs: { src: "images/icons/icon-arrow-down.svg", alt: "" }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Download")])
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "shoutbox__submitted-tweet",
                  attrs: {
                    target: "_blank",
                    href:
                      "https://twitter.com/intent/tweet?url=" +
                      _vm.site_url +
                      "/shoutbox/" +
                      _vm.shoutbox_response
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: "images/icons/icon-twitter-white.svg",
                      alt: ""
                    }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Tweet this")])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "shoutbox__submitted-more",
                  on: {
                    click: function($event) {
                      return _vm.switchBlocks(true, false, false)
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: { src: "images/icons/icon-sync.svg", alt: "" }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Tell us more")])
                ]
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shoutbox__icon" }, [
      _c("img", { attrs: { src: "/images/shoutbox.png", alt: "" } }),
      _vm._v(" "),
      _c("p", { staticClass: "shoutbox__tell" }, [
        _vm._v("\n            Share your idea ...\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shoutbox__submitted-thanks" }, [
      _c("img", {
        staticClass: "shoutbox__submitted-thanks-icon",
        attrs: { src: "images/icons/icon-thumbs-up.svg", alt: "" }
      }),
      _vm._v(" "),
      _c("span", [_vm._v("Thanks for posting")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }