var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "9.415",
        height: "17.579",
        viewBox: "0 0 9.415 17.579"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "Path",
          d:
            "M-8.662.007a.945.945,0,0,1,.518.254l7.813,7.5A.935.935,0,0,1,0,8.426a.937.937,0,0,1-.255.7.936.936,0,0,1-.684.292.931.931,0,0,1-.681-.3L-8.79,2.233l-7.168,6.885a.931.931,0,0,1-.681.3.936.936,0,0,1-.684-.292.937.937,0,0,1-.255-.7.935.935,0,0,1,.331-.665l7.812-7.5A.938.938,0,0,1-8.663.008Z",
          transform: "translate(9.415 17.579) rotate(90)",
          fill: "#fff"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }