var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "twitter-widget" },
    _vm._l(_vm.feed, function(tweet) {
      return _c("li", { key: tweet.id, staticClass: "twitter-widget__post" }, [
        _vm._m(0, true),
        _vm._v(" "),
        _c("div", {
          domProps: { innerHTML: _vm._s(_vm.parseText(tweet.full_text)) }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "mt-4" }, [
          _c(
            "a",
            {
              staticClass: "text--grey text--small twitter-widget__post-link",
              attrs: {
                href:
                  "https://twitter.com/" +
                  tweet.user.id +
                  "/statuses/" +
                  tweet.id_str,
                target: "_blank"
              }
            },
            [
              _vm._v(
                "\n                @" +
                  _vm._s(tweet.user.screen_name) +
                  "\n            "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text--grey text--exsmall" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.parseDate(tweet.created_at)) +
              "\n        "
          )
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-3" }, [
      _c("span", { staticClass: "icon icon--twitter--blue" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }