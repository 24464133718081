var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", { staticClass: "news-events__article" }, [
    _c("div", { staticClass: "news-events__article-card" }, [
      _c("div", {
        staticClass: "news-events__cover",
        style: { backgroundImage: "url(storage/" + _vm.article.image + ")" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "news-events__copy" }, [
        _c("h4", { staticClass: "pb-lg-3 pb-1" }, [
          _vm._v(_vm._s(_vm.article.title))
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "news-events__author" }, [
          _vm._v(
            "By " +
              _vm._s(_vm.article.author) +
              " on " +
              _vm._s(_vm.article.published_at)
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "news-events__excerpt" }, [
          _vm._v(_vm._s(_vm.article.excerpt))
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "link link--orange",
            attrs: { href: "/blog/" + _vm.article.slug }
          },
          [_vm._v("Read more")]
        )
      ]),
      _vm._v(" "),
      _c("a", {
        staticClass: "news-events__article-link",
        attrs: { href: "/blog/" + _vm.article.slug }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }