//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArrowRight from './blocks/assets/ArrowRight.vue';
import axios from 'axios';
export default {
    name: 'ProposalIntro',
    components: {
        'arrow-right': ArrowRight,
    },
    data(){
        return {
            reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            invalidEmail: false,
            getHash: null,
        };
    },
    computed: {
        proposalIntroEmail: {
            get() {
                return this.$store.state.email;
            },
            set(value) {
                this.$store.commit('USER_EMAIL', value);
            },
        },
        isDisabled(){
            let start = this.$store.state.email;
            if (start === false){
                return true;
            } else if (start === ''){
                return true;
            } else {
                return !this.reg.test(this.$store.state.email);
            }
        },
    },
    mounted() {
        this.getHash = this.findGetParameter('hash');
        this.returnState();
    },
    methods: {
        startForm(){
            if (this.$store.state.email === ''){
                this.invalidEmail = true;
            }
            if (this.isDisabled === false){
                this.$store.commit('START_FORM_SUBMITTION', true);
                this.$router.push('/proposal/form/about-you');
            }
        },
        findGetParameter(parameterName) {
            var result = null,
                tmp = [];
            location.search
                .substr(1)
                .split('?')
                .forEach(function(item) {
                    tmp = item.split('=');
                    if (tmp[0] === parameterName) {
                        result = tmp[1];
                    }
                });
            return result;
        },
        returnState() {
            if (this.getHash !== null){
                axios.get(`/api/proposal/${this.getHash}`)
                    .then(({ data }) => {
                        const eco_system = data.data.catastrophic_risks.find(function(slug) {
                            return slug.slug === 'eco_system_collapse';
                        });
                        function ecosystemValue(){
                            if (eco_system !== undefined){
                                return true;
                            } else {
                                return false;
                            }
                        }
                        const climate_change = data.data.catastrophic_risks.find(function(slug) {
                            return slug.slug === 'climate_change';
                        });
                        function climateChange(){
                            if (climate_change !== undefined){
                                return true;
                            } else {
                                return false;
                            }
                        }
                        const pandemics = data.data.catastrophic_risks.find(function(slug) {
                            return slug.slug === 'pandemics';
                        });
                        function pandemicsValue(){
                            if (pandemics !== undefined){
                                return true;
                            } else {
                                return false;
                            }
                        }
                        const weapons = data.data.catastrophic_risks.find(function(slug) {
                            return slug.slug === 'weapons';
                        });
                        function weaponsValue(){
                            if (weapons !== undefined){
                                return true;
                            } else {
                                return false;
                            }
                        }
                        const emerging_technology = data.data.catastrophic_risks.find(function(slug) {
                            return slug.slug === 'emerging_technology';
                        });
                        function emergingTechnology(){
                            if (emerging_technology !== undefined){
                                return true;
                            } else {
                                return false;
                            }
                        }
                        const unknown_risks = data.data.catastrophic_risks.find(function(slug) {
                            return slug.slug === 'unknown_risks';
                        });
                        function unknownRisks(){
                            if (unknown_risks !== undefined){
                                return true;
                            } else {
                                return false;
                            }
                        }
                        const conflict = data.data.multipliers_risks.find(function(slug) {
                            return slug.slug === 'conflict';
                        });
                        function conflictValue(){
                            if (conflict !== undefined){
                                return true;
                            } else {
                                return false;
                            }
                        }
                        const institutions = data.data.multipliers_risks.find(function(slug) {
                            return slug.slug === 'institutions';
                        });
                        function institutionsValue(){
                            if (institutions !== undefined){
                                return true;
                            } else {
                                return false;
                            }
                        }
                        const poverty = data.data.multipliers_risks.find(function(slug) {
                            return slug.slug === 'poverty';
                        });
                        function povertyValue(){
                            if (poverty !== undefined){
                                return true;
                            } else {
                                return false;
                            }
                        }
                        const mitigating_climate = data.data.mitigating.find(function(slug) {
                            return slug.title === 'climate_change';
                        });
                        function mitigatingClimate(){
                            if (mitigating_climate !== undefined){
                                return mitigating_climate.description;
                            } else {
                                return '';
                            }
                        }
                        const mitigating_pandemics = data.data.mitigating.find(function(slug) {
                            return slug.title === 'pandemics';
                        });
                        function mitigatingPandemics(){
                            if (mitigating_pandemics !== undefined){
                                return mitigating_pandemics.description;
                            } else {
                                return '';
                            }
                        }
                        const mitigating_eco_system = data.data.mitigating.find(function(slug) {
                            return slug.title === 'eco_system_collapse';
                        });
                        function mitigatingEcoSystem(){
                            if (mitigating_eco_system !== undefined){
                                return mitigating_eco_system.description;
                            } else {
                                return '';
                            }
                        }
                        const mitigating_weapons = data.data.mitigating.find(function(slug) {
                            return slug.title === 'weapons';
                        });
                        function mitigatingWeapons(){
                            if (mitigating_weapons !== undefined){
                                return mitigating_weapons.description;
                            } else {
                                return '';
                            }
                        }
                        const mitigating_technology = data.data.mitigating.find(function(slug) {
                            return slug.title === 'emerging_technology';
                        });
                        function mitigatingTechnology(){
                            if (mitigating_technology !== undefined){
                                return mitigating_technology.description;
                            } else {
                                return '';
                            }
                        }
                        const mitigating_unknown_risks = data.data.mitigating.find(function(slug) {
                            return slug.title === 'unknown_risks';
                        });
                        function mitigatingUnknown(){
                            if (mitigating_unknown_risks !== undefined){
                                return mitigating_unknown_risks.description;
                            } else {
                                return '';
                            }
                        }
                        const wider_implications_alleviate = data.data.wider_implications.find(function(slug) {
                            return slug.title === 'alleviate_poverty_and_inequality';
                        });
                        function widerImplicationsAlleviate(){
                            if (wider_implications_alleviate !== undefined){
                                return wider_implications_alleviate.description;
                            } else {
                                return '';
                            }
                        }
                        const wider_implications_reducing = data.data.wider_implications.find(function(slug) {
                            return slug.title === 'reducing_inclusivity_and_accountability_in_national';
                        });
                        function widerImplicationsReducing(){
                            if (wider_implications_reducing !== undefined){
                                return wider_implications_reducing.description;
                            } else {
                                return '';
                            }
                        }
                        const wider_implications_increasing = data.data.wider_implications.find(function(slug) {
                            return slug.title === 'increasing_conflict_and_political_violence';
                        });
                        function widerImplicationsIncreasing(){
                            if (wider_implications_increasing !== undefined){
                                return wider_implications_increasing.description;
                            } else {
                                return '';
                            }
                        }
                        const wider_implications_enhance = data.data.wider_implications.find(function(slug) {
                            return slug.title === 'enhance_inclusivity_and_accountability_in_national';
                        });
                        function widerImplicationsEnhance(){
                            if (wider_implications_enhance !== undefined){
                                return wider_implications_enhance.description;
                            } else {
                                return '';
                            }
                        }
                        const wider_implications_inequality = data.data.wider_implications.find(function(slug) {
                            return slug.title === 'increasing_poverty_and_inequality';
                        });
                        function widerImplicationsInequality(){
                            if (wider_implications_inequality !== undefined){
                                return wider_implications_inequality.description;
                            } else {
                                return '';
                            }
                        }
                        const wider_implications_violence = data.data.wider_implications.find(function(slug) {
                            return slug.title === 'reduce_conflict_and_political_violencey';
                        });
                        function widerImplicationsViolence(){
                            if (wider_implications_violence !== undefined){
                                return wider_implications_violence.description;
                            } else {
                                return '';
                            }
                        }

                        this.$store.commit('USER_EMAIL', data.data.email); //email
                        this.$store.commit('UPDATE_FIRST_NAME', data.data.first_name); //first name
                        this.$store.commit('UPDATE_MIDDLE_NAMES', data.data.middle_name); // middle name
                        this.$store.commit('UPDATE_LAST_NAME', data.data.last_name); //last name
                        this.$store.commit('ON_BEHALF_STATE', data.data.is_organisation); //is_organisation
                        this.$store.commit('AUTHOR_CREDIT', data.data.is_anonymous); //is_anonymous
                        this.$store.commit('ORGANISATION_NAME', data.data.organisation_name); //organisation_name
                        this.$store.commit('PROPOSAL_TITLE', data.data.title); //title
                        this.$store.commit('PROPOSAL_DESCRIPTION', data.data.description); //description
                        this.$store.commit('CLIMATE_CHANGE', climateChange()); //catastrophic_risks.climate_change
                        this.$store.commit('ECO_COLLAPSE', ecosystemValue()); //catastrophic_risks.eco_system_collapse
                        this.$store.commit('PANDEMICS_RESISTANCE', pandemicsValue()); //catastrophic_risks.pandemics
                        this.$store.commit('MASS_WEAPONS', weaponsValue()); //catastrophic_risks.weapons
                        this.$store.commit('TECHNOLOGY_THREAT', emergingTechnology()); //catastrophic_risks.emerging_technology
                        this.$store.commit('UNKNOWN_RISKS', unknownRisks()); //catastrophic_risks.unknown_risks
                        this.$store.commit('MULTIPLY_CONFLICT', conflictValue()); //multipliers_risks.conflict
                        this.$store.commit('MULTIPLY_INSTITUTIONS', institutionsValue()); //multipliers_risks.institutions
                        this.$store.commit('MULTIPLY_POVERTY', povertyValue()); //multipliers_risks.poverty
                        this.$store.commit('TERM', data.data.term); //term
                        this.$store.commit('TOC_IMPLEMENTATION_STRATEGY', data.data.theory_of_change.implementation_strategy); //theory_of_change.implementation_strategy
                        this.$store.commit('TOC_POLITICAL_WILL', data.data.theory_of_change.political_will); //theory_of_change.political_will
                        this.$store.commit('TOC_POLITICAL_WILL__NOT', data.data.theory_of_change.political_will_does_not_exist); //theory_of_change.political_will_does_not_exist
                        this.$store.commit('TOC_ROAD_MAPS', data.data.theory_of_change.existing_road_maps); //theory_of_change.existing_road_maps
                        this.$store.commit('TOC_DECISION_MAKERS', data.data.theory_of_change.decision_makers); //theory_of_change.decision_makers
                        this.$store.commit('TOC_WHY_LONG_TERM', data.data.theory_of_change.why_long_terms); //theory_of_change.why_long_terms
                        this.$store.commit('GLOBAL_RISKS_CLIMATE', mitigatingClimate()); //mitigating.climate_change
                        this.$store.commit('GLOBAL_RISKS_PANDEMICS', mitigatingPandemics()); //mitigating.pandemics
                        this.$store.commit('GLOBAL_RISKS_ECOSYSTEM', mitigatingEcoSystem()); //mitigating.eco_system_collapse
                        this.$store.commit('GLOBAL_RISKS_WEAPONS', mitigatingWeapons()); //mitigating.weapons
                        this.$store.commit('GLOBAL_RISKS_TECHNOLOGY', mitigatingTechnology()); //mitigating.emerging_technology
                        this.$store.commit('GLOBAL_RISKS_UNKNOWN_RESPONSE', mitigatingUnknown()); //mitigating.unknown_risks
                        this.$store.commit('WIDER_IMPLICATIONS_ALLEVIATE', widerImplicationsAlleviate()); //wider_implications.alleviate_poverty_and_inequality
                        this.$store.commit('WIDER_IMPLICATIONS_REDUCE_INCLUSIVITY', widerImplicationsReducing()); //wider_implications.reducing_inclusivity_and_accountability_in_national
                        this.$store.commit('WIDER_IMPLICATIONS_INCREASE_CONFLICT', widerImplicationsIncreasing()); //wider_implications.increasing_conflict_and_political_violence
                        this.$store.commit('WIDER_IMPLICATIONS_ENHANCE_INCLUSIVITY', widerImplicationsEnhance()); //wider_implications.enhance_inclusivity_and_accountability_in_national
                        this.$store.commit('WIDER_IMPLICATIONS_INCREASING_POVERTY', widerImplicationsInequality()); //wider_implications.increasing_poverty_and_inequality
                        this.$store.commit('WIDER_IMPLICATIONS_REDUCE_CONFLICT', widerImplicationsViolence()); //wider_implications.reduce_conflict_and_political_violencey
                        this.$store.commit('IN_DETAIL_FULL_PROPOSAL', data.data.details); //details
                        this.$store.commit('IN_DETAIL_LEGAL_STATEMENT', data.data.is_confirm); //is_confirm

                        if ((this.$store.state.first_name !== '' && this.$store.state.last_name !== '')
                            && (this.$store.state.is_anonymous === false)){
                            this.$store.commit('ABOUT_YOU_COMPLETE', true);
                        } else if (this.$store.state.is_anonymous === true) {
                            this.$store.commit('ABOUT_YOU_COMPLETE', true);
                        } else {
                            this.$store.commit('ABOUT_YOU_COMPLETE', false);
                        }
                        if ((this.$store.state.title === '' || this.$store.state.title === null)){
                            this.$store.commit('PROPOSAL_COMPLETED', false);
                        } else {
                            this.$store.commit('PROPOSAL_COMPLETED', true);
                        }

                        let time = this.$store.state.term;
                        let multipliers = this.$store.state.multipliers_risks;
                        let catastrophic_risks = this.$store.state.catastrophic_risks;
                        if ((time === 'short' || time === 'medium' || time === 'long')
                            && (multipliers.poverty === true || multipliers.institutions === true || multipliers.conflict === true)
                            && (catastrophic_risks.unknown_risks === true || catastrophic_risks.emerging_technology === true || catastrophic_risks.weapons === true
                                || catastrophic_risks.pandemics === true || catastrophic_risks.eco_system_collapse === true || catastrophic_risks.climate_change === true)){
                            this.$store.commit('PROPOSAL_CAT_COMPLETED', true);
                        } else {
                            this.$store.commit('PROPOSAL_CAT_COMPLETED', false);
                        }

                        let terms = this.$store.state.theory_of_change;
                        if ((terms.implementation_strategy === '') && (terms.why_long_terms === '') && (terms.decision_makers === '')
                            || (terms.implementation_strategy === '') && (terms.political_will === '') && (terms.political_will_does_not_exist === '')
                            || (terms.implementation_strategy === '') && (terms.existing_road_maps === '') && (terms.decision_makers === '')){
                            this.$store.commit('THEORY_OF_CHANGE_COMPLETE', false);
                        } else {
                            this.$store.commit('THEORY_OF_CHANGE_COMPLETE', true);
                        }

                        let mitigatingRisk = this.$store.state.mitigating;
                        if (mitigatingRisk.climate_change !== ''
                            || mitigatingRisk.weapons !== ''
                            || mitigatingRisk.eco_system_collapse !== ''
                            || mitigatingRisk.pandemics !== ''
                            || mitigatingRisk.emerging_technology !== ''){
                            this.$store.commit('GLOBAL_RISKS_COMPLETE', true);
                        } else {
                            this.$store.commit('GLOBAL_RISKS_COMPLETE', false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    }
};
