//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ArrowRight'
};
