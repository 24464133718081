var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "65.396",
        height: "62.088",
        viewBox: "0 0 65.396 62.088"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Group_4",
            "data-name": "Group 4",
            transform: "translate(1.041 1.107)"
          }
        },
        [
          _c("path", {
            attrs: {
              id: "Stroke_294",
              "data-name": "Stroke 294",
              d:
                "M61.358,48.477l-.033-.011a13.986,13.986,0,0,0-12.936-19.5c-.371,0-.73.022-1.1.054a13.755,13.755,0,0,0-3.189.635,13.968,13.968,0,0,0-7.771,6.3.011.011,0,0,0-.009,0s0,0,0,.006v.014a13.9,13.9,0,0,0-1.594,9.725,14.315,14.315,0,0,0,1.069,3.241c.131.275.273.556.426.831a14,14,0,0,0,22.838,2.367l.042.036c-5.763,7.584-16.047,10.063-24.081,5.436a16.95,16.95,0,0,1-3.361-2.546,16.686,16.686,0,0,1-3.36,2.546C20.269,62.243,10,59.764,4.223,52.2l.043-.041A14.018,14.018,0,0,0,27.1,49.778c.144-.273.285-.556.416-.831a14.319,14.319,0,0,0,1.071-3.241,13.847,13.847,0,0,0-1.6-9.725v-.014a.009.009,0,0,0,0-.006.008.008,0,0,0-.007,0,13.971,13.971,0,0,0-7.773-6.3,13.659,13.659,0,0,0-3.189-.633c-.371-.033-.719-.056-1.1-.056a14.265,14.265,0,0,0-3.625.448A14.023,14.023,0,0,0,1.985,48.489l-.023.011C-2.326,39.5.6,28.818,8.908,24.025a16.3,16.3,0,0,1,3.876-1.638,16.581,16.581,0,0,1-.524-4.19C12.26,8.766,19.8.992,29.5,0V.043a14,14,0,0,0-11.853,13.84,13.686,13.686,0,0,0,1.889,7,9.6,9.6,0,0,0,.611.972,13.527,13.527,0,0,0,2.118,2.413,13.972,13.972,0,0,0,18.775,0,13.46,13.46,0,0,0,2.117-2.413,9.354,9.354,0,0,0,.612-.972,13.7,13.7,0,0,0,1.89-7A14,14,0,0,0,33.808.043L33.817,0c9.705,1,17.236,8.766,17.236,18.2a16.649,16.649,0,0,1-.523,4.19,16.528,16.528,0,0,1,3.888,1.638c8.306,4.793,11.209,15.456,6.942,24.452Z",
              transform: "translate(0)",
              fill: "none",
              stroke: "#000",
              "stroke-miterlimit": "10",
              "stroke-width": "2"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_296",
              "data-name": "Fill 296",
              d:
                "M12.072,2a17.21,17.21,0,0,1-10.566,15.9,2.945,2.945,0,0,1-.207-.34c-.122-.229-.242-.469-.35-.677a11.792,11.792,0,0,1-.908-2.76A2.169,2.169,0,0,1,0,13.842,12.912,12.912,0,0,0,7.761,2,11.312,11.312,0,0,0,7.685.666c.091-.024.183-.053.271-.086A11.443,11.443,0,0,1,10.665.043c.306-.019.6-.042.972-.042a2.457,2.457,0,0,1,.315.011A14.406,14.406,0,0,1,12.071,2Z",
              transform: "translate(36.819 31.153)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_298",
              "data-name": "Fill 298",
              d:
                "M20.874,3.514c-.066.141-.153.272-.229.416a7.671,7.671,0,0,1-.5.774,11.052,11.052,0,0,1-1.791,2.04.96.96,0,0,1-.175.155,12.884,12.884,0,0,0-15.489,0c-.065-.056-.12-.109-.186-.163A11.508,11.508,0,0,1,.711,4.673,6.683,6.683,0,0,1,.2,3.864c-.066-.12-.13-.231-.2-.35a17.255,17.255,0,0,1,20.874,0",
              transform: "translate(21.218 15.905)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_300",
              "data-name": "Fill 300",
              d:
                "M12.084,13.841c-.023.1-.035.2-.054.3a11.961,11.961,0,0,1-.9,2.706c-.122.262-.252.523-.361.719-.064.112-.122.222-.195.33A17.2,17.2,0,0,1,0,2,14.869,14.869,0,0,1,.119.012,1.709,1.709,0,0,1,.381,0H.5c.317,0,.6.023.927.043A11.593,11.593,0,0,1,4.116.579c.086.033.185.066.272.1A10.862,10.862,0,0,0,4.311,2a12.9,12.9,0,0,0,7.773,11.844",
              transform: "translate(14.419 31.154)"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }