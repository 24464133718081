var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "proposal__popup",
      on: {
        click: function($event) {
          return _vm.savePopup()
        }
      }
    },
    [
      _c("div", { staticClass: "proposal__popup__content-submit" }, [
        _c("div", { staticClass: "success-checkmark icon--form-success" }),
        _vm._v(" "),
        _c("h4", { staticClass: "proposal__popup__submit-heading" }, [
          _vm._v("\n            Thank you!\n        ")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "proposal__popup__text" }, [
          _vm._v(
            "\n            Your proposal has been successfully submitted\n        "
          )
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "proposal__popup__save",
            on: { click: _vm.savePopup }
          },
          [_vm._v("\n            Close\n        ")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }