var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form__section" }, [
    _c("h3", { staticClass: "form__section__title main-heading" }, [
      _vm._v("\n        Theory of change\n    ")
    ]),
    _vm._v(" "),
    _c(
      "p",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.prevSection === true,
            expression: "prevSection === true"
          }
        ],
        staticClass: "form__section__tip margin-b-74"
      },
      [
        _vm._v(
          "\n        These questions allow you to explain how your proposal is viable and what would be required to turn it into reality.\n    "
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.prevSection === false,
            expression: "prevSection === false"
          }
        ],
        staticClass: "form__section__empty margin-b-74"
      },
      [
        _c(
          "p",
          { staticClass: "form__section__tip" },
          [
            _vm._v(
              "\n            These questions are dependent upon your answers to the questions within the "
            ),
            _c(
              "router-link",
              {
                staticClass: "form-link",
                attrs: { to: "/proposal/form/proposal-categorisation" }
              },
              [
                _vm._v(
                  "\n                Proposal categorisation\n            "
                )
              ]
            ),
            _vm._v(" section.\n        ")
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0)
      ]
    ),
    _vm._v(" "),
    _vm.timeFrameShort === true
      ? _c("div", { staticClass: "form__section__short-term" }, [
          _c("h4", { staticClass: "form__section__title" }, [
            _vm._v(
              "\n            Describe your proposed implementation strategy\n        "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "form__section__tip" }, [
            _vm._v(
              "\n            How would your proposal take effect? Describe the obstricals, for example political, institutional, financial or other, that stand in the way\n            of your proposal and explain how each of these obstacles can be overcome.\n        "
            )
          ]),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "form__label margin-b-74",
              attrs: { for: "short-term-implementation" }
            },
            [
              _c("span", { staticClass: "form__input__text" }, [
                _vm._v("Implementation strategy")
              ]),
              _vm._v(" "),
              _c("textarea-block", {
                class: {
                  "invalid icon--form-attention": _vm.invalid_ims === true
                },
                attrs: {
                  id: "short-term-implementation",
                  "max-words": 500,
                  rows: 10
                },
                model: {
                  value: _vm.implementationStrategy,
                  callback: function($$v) {
                    _vm.implementationStrategy = $$v
                  },
                  expression: "implementationStrategy"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("h4", { staticClass: "form__section__title" }, [
            _vm._v(
              "\n            To what extent does political will currently exists to realise your proposal?\n        "
            )
          ]),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "form__label margin-b-74",
              attrs: { for: "short-term-extent" }
            },
            [
              _c("textarea-block", {
                class: {
                  "invalid icon--form-attention": _vm.invalid_pw === true
                },
                attrs: { id: "short-term-extent", "max-words": 300, rows: 10 },
                model: {
                  value: _vm.politicalWill,
                  callback: function($$v) {
                    _vm.politicalWill = $$v
                  },
                  expression: "politicalWill"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("h4", { staticClass: "form__section__title" }, [
            _vm._v(
              "\n            To the extent that political will does not yet exist, how will you create it and how long do expect it to take?\n        "
            )
          ]),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "form__label margin-b-74",
              attrs: { for: "short-term-extent-not" }
            },
            [
              _c("textarea-block", {
                class: {
                  "invalid icon--form-attention": _vm.invalid_pwn === true
                },
                attrs: {
                  id: "short-term-extent-not",
                  "max-words": 300,
                  rows: 10
                },
                model: {
                  value: _vm.politicalWillNot,
                  callback: function($$v) {
                    _vm.politicalWillNot = $$v
                  },
                  expression: "politicalWillNot"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "form__section__button",
              class: { inactive: _vm.isDisabledShortTerm },
              on: {
                click: function($event) {
                  return _vm.completeTheoryOfChange()
                }
              }
            },
            [_vm._v("\n            Continue "), _c("arrow-right")],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.timeFrameMedium === true
      ? _c("div", { staticClass: "form__section__medium-term" }, [
          _c("h4", { staticClass: "form__section__title" }, [
            _vm._v(
              "\n            Describe your proposed implementation strategy\n        "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "form__section__tip" }, [
            _vm._v(
              "\n            How would your proposal take effect? Describe the obstricals, for example political, institutional, financial or other, that stand in the way\n            of your proposal and explain how each of these obstacles can be overcome.\n        "
            )
          ]),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "form__label margin-b-74",
              attrs: { for: "medium-term-implementation" }
            },
            [
              _c("span", { staticClass: "form__input__text" }, [
                _vm._v("Implementation strategy")
              ]),
              _vm._v(" "),
              _c("textarea-block", {
                class: {
                  "invalid icon--form-attention": _vm.invalid_ims === true
                },
                attrs: {
                  id: "medium-term-implementation",
                  "max-words": 500,
                  rows: 10
                },
                model: {
                  value: _vm.implementationStrategy,
                  callback: function($$v) {
                    _vm.implementationStrategy = $$v
                  },
                  expression: "implementationStrategy"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("h4", { staticClass: "form__section__title" }, [
            _vm._v(
              "\n            Can the proposal be realised by implementing or making adjustments to existing roadmaps\n            and procesess such as Agenda 2030 or sustainable development?\n        "
            )
          ]),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "form__label margin-b-74",
              attrs: { for: "medium-term-time" }
            },
            [
              _c("span", { staticClass: "form__input__text" }, [
                _vm._v("If so, explain how, if not, explain why not")
              ]),
              _vm._v(" "),
              _c("textarea-block", {
                class: {
                  "invalid icon--form-attention": _vm.invalid_rm === true
                },
                attrs: { id: "medium-term-time", "max-words": 300, rows: 10 },
                model: {
                  value: _vm.roadMaps,
                  callback: function($$v) {
                    _vm.roadMaps = $$v
                  },
                  expression: "roadMaps"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("h4", { staticClass: "form__section__title" }, [
            _vm._v(
              "\n            Who are the decision makers and implementers with respect to your proposal?\n        "
            )
          ]),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "form__label margin-b-74",
              attrs: { for: "medium-term-decision" }
            },
            [
              _c("span", { staticClass: "form__input__text" }, [
                _vm._v(
                  "How many of them already support your proposal (list them) and how will you persuade the remainder to support your proposal?"
                )
              ]),
              _vm._v(" "),
              _c("textarea-block", {
                class: {
                  "invalid icon--form-attention": _vm.invalid_dm === true
                },
                attrs: {
                  id: "medium-term-decision",
                  "max-words": 300,
                  rows: 10
                },
                model: {
                  value: _vm.decisionMakers,
                  callback: function($$v) {
                    _vm.decisionMakers = $$v
                  },
                  expression: "decisionMakers"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "form__section__button",
              class: { inactive: _vm.isDisabledMidTerm },
              on: {
                click: function($event) {
                  return _vm.completeTheoryOfChange()
                }
              }
            },
            [_vm._v("\n            Continue "), _c("arrow-right")],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.timeFrameLong === true
      ? _c("div", { staticClass: "form__section__long-term" }, [
          _c("h4", { staticClass: "form__section__title" }, [
            _vm._v(
              "\n            Describe your proposed implementation strategy\n        "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "form__section__tip" }, [
            _vm._v(
              "\n            How would your proposal take effect? Describe the obstricals, for example political, institutional, financial or other, that stand in the way\n            of your proposal and explain how each of these obstacles can be overcome.\n        "
            )
          ]),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "form__label margin-b-74",
              attrs: { for: "long-term-implementation" }
            },
            [
              _c("span", { staticClass: "form__input__text" }, [
                _vm._v("Implementation strategy")
              ]),
              _vm._v(" "),
              _c("textarea-block", {
                class: {
                  "invalid icon--form-attention": _vm.invalid_ims === true
                },
                attrs: {
                  id: "long-term-implementation",
                  "max-words": 500,
                  rows: 10
                },
                model: {
                  value: _vm.implementationStrategy,
                  callback: function($$v) {
                    _vm.implementationStrategy = $$v
                  },
                  expression: "implementationStrategy"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("h4", { staticClass: "form__section__title" }, [
            _vm._v("\n            Why is this a long term proposal?\n        ")
          ]),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "form__label margin-b-74",
              attrs: { for: "long-term-reason" }
            },
            [
              _c("span", { staticClass: "form__input__text" }, [
                _vm._v(
                  "Why is the proposal not viable now and what change in the international context would provide a path to viability?"
                )
              ]),
              _vm._v(" "),
              _c("textarea-block", {
                class: {
                  "invalid icon--form-attention": _vm.invalid_wlt === true
                },
                attrs: { id: "long-term-reason", "max-words": 300, rows: 10 },
                model: {
                  value: _vm.whyLongTerms,
                  callback: function($$v) {
                    _vm.whyLongTerms = $$v
                  },
                  expression: "whyLongTerms"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("h4", { staticClass: "form__section__title" }, [
            _vm._v(
              "\n            Who are the decision makers and implementers with respect to your proposal?\n        "
            )
          ]),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "form__label margin-b-74",
              attrs: { for: "long-term-decision-makers" }
            },
            [
              _c("span", { staticClass: "form__input__text" }, [
                _vm._v(
                  "How many of them already support your proposal (list them) and how will you persuade the remainder to support your proposal?"
                )
              ]),
              _vm._v(" "),
              _c("textarea-block", {
                class: {
                  "invalid icon--form-attention": _vm.invalid_dm === true
                },
                attrs: {
                  id: "long-term-decision-makers",
                  "max-words": 300,
                  rows: 10
                },
                model: {
                  value: _vm.decisionMakers,
                  callback: function($$v) {
                    _vm.decisionMakers = $$v
                  },
                  expression: "decisionMakers"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "form__section__button",
              class: { inactive: _vm.isDisabledLongTerm },
              on: {
                click: function($event) {
                  return _vm.completeTheoryOfChange()
                }
              }
            },
            [_vm._v("\n            Continue "), _c("arrow-right")],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "form__section__tip--attention" }, [
      _c("span", { staticClass: "icon--form-attention" }),
      _vm._v(
        "\n            Please complete all questions within Proposal categorisation\n        "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }