var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form__section" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.about_you_step_one,
            expression: "about_you_step_one"
          }
        ],
        staticClass: "form__section__about"
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.authorCredit === false,
                expression: "authorCredit === false"
              }
            ],
            staticClass: "form__subsection"
          },
          [
            _c("h3", { staticClass: "form__section__title main-heading" }, [
              _vm._v("\n                What is your full name?\n            ")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "form__section__tip" }, [
              _vm._v(
                "\n                Enter your name in full, do not use initials.\n            "
              )
            ]),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "form__label", attrs: { for: "first-name" } },
              [
                _c("span", { staticClass: "form__input__text" }, [
                  _vm._v("First name")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.firstName,
                      expression: "firstName"
                    }
                  ],
                  staticClass: "form__input__value",
                  class: {
                    "invalid icon--form-attention":
                      _vm.invalid_first_name === true
                  },
                  attrs: {
                    id: "first-name",
                    type: "text",
                    required: "",
                    name: "name-field"
                  },
                  domProps: { value: _vm.firstName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.firstName = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "invalid-field-text" }, [
                  _vm._v("Please enter your first name")
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "form__label", attrs: { for: "middle-names" } },
              [
                _c("span", { staticClass: "form__input__text" }, [
                  _vm._v("Middle names")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.middleNames,
                      expression: "middleNames"
                    }
                  ],
                  staticClass: "form__input__value",
                  attrs: {
                    id: "middle-names",
                    type: "text",
                    name: "name-field"
                  },
                  domProps: { value: _vm.middleNames },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.middleNames = $event.target.value
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "form__label margin-b-74",
                attrs: { for: "last-name" }
              },
              [
                _c("span", { staticClass: "form__input__text" }, [
                  _vm._v("Last name")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.lastName,
                      expression: "lastName"
                    }
                  ],
                  staticClass: "form__input__value",
                  class: {
                    "invalid icon--form-attention":
                      _vm.invalid_last_name === true
                  },
                  attrs: {
                    id: "last-name",
                    type: "text",
                    required: "",
                    name: "name-field"
                  },
                  domProps: { value: _vm.lastName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.lastName = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "invalid-field-text" }, [
                  _vm._v("Please enter your last name")
                ])
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "form__section__title" }, [
          _vm._v(
            "\n            Do you wish to be credited as an author to your proposal?\n        "
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "form__section__tip" }, [
          _vm._v(
            "\n            By being credited as an author you will be informed when your\n            submission is published in addition to Together First's campaining actions.\n        "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form__radio margin-b-74" }, [
          _c("div", { staticClass: "form__radio__item radio-about" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.authorCredit,
                  expression: "authorCredit"
                }
              ],
              attrs: {
                id: "author-credit",
                type: "radio",
                name: "author-credit-name"
              },
              domProps: {
                value: false,
                checked: _vm._q(_vm.authorCredit, false)
              },
              on: {
                change: function($event) {
                  _vm.authorCredit = false
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "author-credit" } }, [
              _vm._v("Yes, I wish to be credited as an author")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form__radio__item radio-about" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.authorCredit,
                  expression: "authorCredit"
                }
              ],
              attrs: {
                id: "author-anonym",
                type: "radio",
                name: "author-credit-name",
                checked: ""
              },
              domProps: {
                value: true,
                checked: _vm._q(_vm.authorCredit, true)
              },
              on: {
                change: function($event) {
                  _vm.authorCredit = true
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "author-anonym" } }, [
              _vm._v("No, I wish to submit my proposal anonymously")
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "form__section__button",
            class: { inactive: _vm.isDisabledOne },
            on: {
              click: function($event) {
                return _vm.continueAbout()
              }
            }
          },
          [_vm._v("\n            Continue "), _c("arrow-right")],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.about_you_step_two,
            expression: "about_you_step_two"
          }
        ],
        staticClass: "form__section__about"
      },
      [
        _c("h3", { staticClass: "form__section__title main-heading" }, [
          _vm._v(
            "\n            Are you submitting on behalf of an organisation?\n        "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form__radio margin-b-74" }, [
          _c("div", { staticClass: "form__radio__item radio-behalf" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.onBehalfValue,
                  expression: "onBehalfValue"
                }
              ],
              attrs: {
                id: "behalf-organisation",
                type: "radio",
                name: "behalf-organisation-name",
                checked: ""
              },
              domProps: {
                value: true,
                checked: _vm._q(_vm.onBehalfValue, true)
              },
              on: {
                change: function($event) {
                  _vm.onBehalfValue = true
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "behalf-organisation" } }, [
              _vm._v("Yes, I am submitting on behalf of an organisation")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form__radio__item radio-behalf" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.onBehalfValue,
                  expression: "onBehalfValue"
                }
              ],
              attrs: {
                id: "behalf-person",
                type: "radio",
                name: "behalf-organisation-name"
              },
              domProps: {
                value: false,
                checked: _vm._q(_vm.onBehalfValue, false)
              },
              on: {
                change: function($event) {
                  _vm.onBehalfValue = false
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "behalf-person" } }, [
              _vm._v("No, I am submitting my proposal personally")
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.state.is_organisation === true,
                expression: "$store.state.is_organisation === true"
              }
            ],
            staticClass: "form__subsection margin-b-74"
          },
          [
            _c("h4", { staticClass: "form__section__title" }, [
              _vm._v(
                "\n                What is the name of the organisation?\n            "
              )
            ]),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "form__label",
                attrs: { for: "organisation-name" }
              },
              [
                _c("span", { staticClass: "form__input__text" }, [
                  _vm._v("Organisation name")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.organisationName,
                      expression: "organisationName"
                    }
                  ],
                  staticClass: "form__input__value",
                  class: {
                    "invalid icon--form-attention":
                      _vm.invalid_organisation_name === true
                  },
                  attrs: { id: "organisation-name", type: "text", name: "" },
                  domProps: { value: _vm.organisationName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.organisationName = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "invalid-field-text" }, [
                  _vm._v("Please enter the name of your organisation")
                ])
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "form__section__button",
            class: { inactive: _vm.isDisabledTwo },
            on: {
              click: function($event) {
                return _vm.completeAbout()
              }
            }
          },
          [_vm._v("\n            Continue "), _c("arrow-right")],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }