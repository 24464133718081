var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "59.777",
        height: "59.777",
        viewBox: "0 0 59.777 59.777"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Group_3",
            "data-name": "Group 3",
            transform: "translate(0.2 0.2)"
          }
        },
        [
          _c("path", {
            attrs: {
              id: "Fill_209",
              "data-name": "Fill 209",
              d:
                "M1.781,3.562A1.781,1.781,0,1,1,3.562,1.781,1.781,1.781,0,0,1,1.781,3.562",
              transform: "translate(46.907 46.908)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_210",
              "data-name": "Fill 210",
              d:
                "M.593,5.937a.594.594,0,0,1,0-1.188A4.163,4.163,0,0,0,4.751.593a.593.593,0,1,1,1.186,0A5.352,5.352,0,0,1,.593,5.937",
              transform: "translate(47.501 47.502)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_211",
              "data-name": "Fill 211",
              d:
                "M.595,5.937A.594.594,0,0,1,0,5.344,5.351,5.351,0,0,1,5.345,0a.593.593,0,0,1,0,1.186A4.163,4.163,0,0,0,1.189,5.344a.594.594,0,0,1-.595.593",
              transform: "translate(43.938 43.939)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_212",
              "data-name": "Fill 212",
              d:
                "M.595,8.313a.594.594,0,1,1,0-1.188A6.537,6.537,0,0,0,7.125.595a.594.594,0,1,1,1.188,0A7.727,7.727,0,0,1,.595,8.313",
              transform: "translate(48.094 48.095)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_213",
              "data-name": "Fill 213",
              d:
                "M.595,8.313A.594.594,0,0,1,0,7.718,7.728,7.728,0,0,1,7.72,0a.594.594,0,0,1,0,1.188,6.537,6.537,0,0,0-6.53,6.53.594.594,0,0,1-.595.595",
              transform: "translate(40.969 40.97)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_214",
              "data-name": "Fill 214",
              d:
                "M48.688,59.377a10.682,10.682,0,0,1-9.461-15.652c-3.415-2.233-6.535-3.319-9.54-3.319s-6.123,1.086-9.538,3.319a10.561,10.561,0,0,1,1.228,4.963A10.688,10.688,0,1,1,10.688,38a10.588,10.588,0,0,1,4.964,1.227c2.233-3.418,3.319-6.539,3.319-9.538s-1.086-6.123-3.319-9.54A10.684,10.684,0,0,1,0,10.688,10.688,10.688,0,0,1,18.246,3.131a10.673,10.673,0,0,1,1.9,12.521c3.416,2.233,6.536,3.319,9.538,3.319s6.123-1.086,9.54-3.319a10.7,10.7,0,1,1,4.5,4.5c-2.235,3.417-3.322,6.538-3.322,9.54s1.087,6.118,3.322,9.538A10.589,10.589,0,0,1,48.688,38a10.688,10.688,0,1,1,0,21.376Zm0-20.189A9.37,9.37,0,0,0,44.4,40.223l-1.032.6a9.524,9.524,0,0,0-2.547,2.545l-.6,1.03a9.382,9.382,0,0,0-1.035,4.291,9.5,9.5,0,1,0,9.5-9.5Zm-38,0a9.5,9.5,0,1,0,9.5,9.5A9.393,9.393,0,0,0,19.154,44.4l-.6-1.03a9.513,9.513,0,0,0-2.543-2.545l-1.034-.6A9.35,9.35,0,0,0,10.688,39.188Zm19,.031c3.226,0,6.546,1.139,10.147,3.481A10.652,10.652,0,0,1,42.7,39.836c-2.345-3.605-3.485-6.924-3.485-10.147s1.14-6.544,3.485-10.148a10.658,10.658,0,0,1-2.866-2.865c-3.6,2.343-6.922,3.481-10.147,3.481s-6.545-1.139-10.147-3.481a10.656,10.656,0,0,1-2.865,2.865c2.343,3.6,3.481,6.921,3.481,10.148s-1.139,6.545-3.481,10.147A10.65,10.65,0,0,1,19.541,42.7C23.144,40.357,26.463,39.218,29.687,39.218Zm-19-38.03a9.5,9.5,0,0,0,0,19,9.364,9.364,0,0,0,4.289-1.035l1.034-.6a9.614,9.614,0,0,0,2.546-2.546l.6-1.031a9.366,9.366,0,0,0,1.035-4.289A9.512,9.512,0,0,0,10.687,1.188Zm38,0a9.511,9.511,0,0,0-9.5,9.5,9.355,9.355,0,0,0,1.035,4.289l.6,1.03a9.555,9.555,0,0,0,2.547,2.548l1.032.6a9.381,9.381,0,0,0,4.289,1.033,9.5,9.5,0,1,0,0-19Z",
              transform: "translate(0 0)",
              stroke: "#000",
              "stroke-miterlimit": "10",
              "stroke-width": "0.4"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_215",
              "data-name": "Fill 215",
              d:
                "M1.781,3.562A1.781,1.781,0,1,1,3.562,1.781,1.781,1.781,0,0,1,1.781,3.562",
              transform: "translate(8.906 8.907)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_216",
              "data-name": "Fill 216",
              d:
                "M.595,5.937a.594.594,0,1,1,0-1.188A4.161,4.161,0,0,0,4.751.593a.594.594,0,0,1,1.188,0A5.352,5.352,0,0,1,.595,5.937",
              transform: "translate(9.499 9.501)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_217",
              "data-name": "Fill 217",
              d:
                "M.595,5.937A.594.594,0,0,1,0,5.343,5.35,5.35,0,0,1,5.345,0a.593.593,0,0,1,0,1.186A4.163,4.163,0,0,0,1.188,5.343a.593.593,0,0,1-.593.595",
              transform: "translate(5.937 5.938)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_218",
              "data-name": "Fill 218",
              d:
                "M.593,8.311a.594.594,0,0,1,0-1.188A6.538,6.538,0,0,0,7.125.593a.593.593,0,1,1,1.186,0A7.727,7.727,0,0,1,.593,8.311",
              transform: "translate(10.094 10.095)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_219",
              "data-name": "Fill 219",
              d:
                "M.593,8.313A.593.593,0,0,1,0,7.72,7.727,7.727,0,0,1,7.718,0a.594.594,0,0,1,0,1.188A6.538,6.538,0,0,0,1.188,7.72a.594.594,0,0,1-.595.593",
              transform: "translate(2.969 2.969)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_220",
              "data-name": "Fill 220",
              d:
                "M1.781,3.562A1.781,1.781,0,1,1,3.562,1.781,1.781,1.781,0,0,1,1.781,3.562",
              transform: "translate(46.907 8.907)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_221",
              "data-name": "Fill 221",
              d:
                "M.593,5.937a.594.594,0,0,1,0-1.188A4.163,4.163,0,0,0,4.751.593a.593.593,0,1,1,1.186,0A5.352,5.352,0,0,1,.593,5.937",
              transform: "translate(47.501 9.501)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_222",
              "data-name": "Fill 222",
              d:
                "M.595,5.937A.594.594,0,0,1,0,5.343,5.35,5.35,0,0,1,5.345,0a.593.593,0,0,1,0,1.186A4.161,4.161,0,0,0,1.189,5.343a.594.594,0,0,1-.595.595",
              transform: "translate(43.938 5.938)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_223",
              "data-name": "Fill 223",
              d:
                "M.595,8.311a.594.594,0,1,1,0-1.188A6.537,6.537,0,0,0,7.125.593a.594.594,0,0,1,1.188,0A7.727,7.727,0,0,1,.595,8.311",
              transform: "translate(48.094 10.095)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_224",
              "data-name": "Fill 224",
              d:
                "M.595,8.313A.594.594,0,0,1,0,7.72,7.728,7.728,0,0,1,7.72,0a.594.594,0,0,1,0,1.188A6.538,6.538,0,0,0,1.189,7.72a.594.594,0,0,1-.595.593",
              transform: "translate(40.969 2.969)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_225",
              "data-name": "Fill 225",
              d:
                "M1.781,3.562A1.781,1.781,0,1,1,3.562,1.781,1.781,1.781,0,0,1,1.781,3.562",
              transform: "translate(8.906 46.908)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_226",
              "data-name": "Fill 226",
              d:
                "M.595,5.937a.594.594,0,1,1,0-1.188A4.161,4.161,0,0,0,4.751.593a.594.594,0,0,1,1.188,0A5.352,5.352,0,0,1,.595,5.937",
              transform: "translate(9.499 47.502)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_227",
              "data-name": "Fill 227",
              d:
                "M.595,5.937A.594.594,0,0,1,0,5.344,5.351,5.351,0,0,1,5.345,0a.593.593,0,0,1,0,1.186A4.164,4.164,0,0,0,1.188,5.344a.593.593,0,0,1-.593.593",
              transform: "translate(5.937 43.939)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_228",
              "data-name": "Fill 228",
              d:
                "M.593,8.313a.594.594,0,0,1,0-1.188A6.538,6.538,0,0,0,7.125.595a.593.593,0,1,1,1.186,0A7.727,7.727,0,0,1,.593,8.313",
              transform: "translate(10.094 48.095)"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_229",
              "data-name": "Fill 229",
              d:
                "M.593,8.313A.593.593,0,0,1,0,7.718,7.727,7.727,0,0,1,7.718,0a.594.594,0,0,1,0,1.188,6.536,6.536,0,0,0-6.53,6.53.594.594,0,0,1-.595.595",
              transform: "translate(2.969 40.97)"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }