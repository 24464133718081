var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "69.357",
        height: "60.894",
        viewBox: "0 0 69.357 60.894"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Group_6",
            "data-name": "Group 6",
            transform: "translate(1.031 1)"
          }
        },
        [
          _c("path", {
            attrs: {
              id: "Fill_87",
              "data-name": "Fill 87",
              d:
                "M31.644,1.157.313,55.424a2.314,2.314,0,0,0,2,3.47H64.98a2.312,2.312,0,0,0,2-3.47L35.651,1.157a2.313,2.313,0,0,0-4.006,0",
              transform: "translate(0 0)",
              fill: "#fff"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Stroke_88",
              "data-name": "Stroke 88",
              d:
                "M31.644,1.157.313,55.424a2.314,2.314,0,0,0,2,3.47H64.98a2.312,2.312,0,0,0,2-3.47L35.651,1.157A2.313,2.313,0,0,0,31.644,1.157Z",
              transform: "translate(0 0)",
              fill: "none",
              stroke: "#000",
              "stroke-miterlimit": "10",
              "stroke-width": "2"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Fill_89",
              "data-name": "Fill 89",
              d:
                "M2.425,30.082a2.453,2.453,0,0,1-1.7-.659A2.13,2.13,0,0,1,0,27.784a2.34,2.34,0,0,1,.724-1.7,2.364,2.364,0,0,1,3.4,0,2.344,2.344,0,0,1,.723,1.7,2.134,2.134,0,0,1-.723,1.639A2.46,2.46,0,0,1,2.425,30.082Zm.426-7.658H2q-.13-1.508-.269-3.148l-.092-1.084c-.142-1.655-.261-3.041-.383-4.425-.127-1.45-.241-2.86-.34-4.191C.83,8.437.731,7.12.64,5.98Q.51,4.383.427,3.341C.372,2.7.341,2.239.341,2.085A1.883,1.883,0,0,1,.979.469,2.394,2.394,0,0,1,2.425,0,2.4,2.4,0,0,1,3.873.469a1.883,1.883,0,0,1,.638,1.616c0,.221-.054.992-.17,2.425-.119,1.462-.255,3.166-.4,5.063-.16,2.025-.339,4.179-.533,6.4-.171,1.972-.347,4.02-.553,6.445Z",
              transform: "translate(31.222 20.473)"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }