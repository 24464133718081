var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "observe-visibility",
          rawName: "v-observe-visibility",
          value: {
            callback: _vm.visibilityChanged,
            intersection: {
              threshold: 1.0
            }
          },
          expression:
            "{\n        callback:visibilityChanged,\n        intersection:{\n            threshold: 1.0\n        }\n    }"
        }
      ],
      staticClass: "number-counter"
    },
    [
      _c("div", { staticClass: "odometer", attrs: { id: "odometer" } }, [
        _vm._v("\n        0\n    ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }