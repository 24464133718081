class VideoBlock {
    constructor() {
        let videoButton = document.querySelector('.video-block__overlay');
        if (videoButton){
            videoButton.addEventListener('click', function(e){
                e.preventDefault();
                let embedded_url = this.getAttribute('data-src');
                let width = this.clientWidth;
                let height = this.clientHeight;
                this.parentElement.innerHTML = `<iframe width="${width}" height="${height}" src="${embedded_url}?wmode=transparent&autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
            });
        }
    }
}

export default VideoBlock;
