var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "proposal__wrap" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "proposal__column-two" }, [
      _c("div", { staticClass: "proposal__intro" }, [
        _c("h3", { staticClass: "proposal__intro__main-heading" }, [
          _vm._v("\n                Submit a proposal\n            ")
        ]),
        _vm._v(" "),
        _c("h4", { staticClass: "proposal__intro__heading" }, [
          _vm._v(
            "\n                Use this form to submit a proposal to Together First on how a reform to our global system could be implemented.\n            "
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "proposal__intro__text" }, [
          _vm._v(
            "\n                Should you wish to leave the form at any time, for example to obtain additional information to support your proposal, you can return to your answers by saving and using the link sent to your email address.\n            "
          )
        ]),
        _vm._v(" "),
        _c("h4", { staticClass: "proposal__intro__heading--bold" }, [
          _vm._v("\n                Get Started\n            ")
        ]),
        _vm._v(" "),
        _c(
          "label",
          { staticClass: "form__label", attrs: { for: "get-started" } },
          [
            _c("span", { staticClass: "form__input__text" }, [
              _vm._v("Please enter your email address")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.proposalIntroEmail,
                  expression: "proposalIntroEmail"
                }
              ],
              staticClass: "form__input__value",
              class: { "invalid icon--form-attention": _vm.invalidEmail },
              attrs: {
                id: "get-started",
                type: "email",
                required: "",
                name: "name-field",
                placeholder: "Your email address"
              },
              domProps: { value: _vm.proposalIntroEmail },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.proposalIntroEmail = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "invalid-field-text" }, [
              _vm._v("Please enter your valid email address")
            ])
          ]
        ),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "proposal__start",
            class: { inactive: _vm.isDisabled },
            on: { click: _vm.startForm }
          },
          [_vm._v("\n                Start now "), _c("arrow-right")],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "proposal__column-one not-fixed" }, [
      _c("div", { staticClass: "proposal__intro" }, [
        _c("a", { staticClass: "proposal__intro-logo", attrs: { href: "/" } }, [
          _c("span", { staticClass: "icon--form-logo" })
        ]),
        _vm._v(" "),
        _c("h4", { staticClass: "proposal__intro__heading" }, [
          _vm._v("\n                Before you start\n            ")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "proposal__intro__text" }, [
          _vm._v("\n                You may wish to read:\n            ")
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "proposal__intro__list" }, [
          _c("li", { staticClass: "proposal__intro__list-item" }, [
            _vm._v("\n                    This "),
            _c(
              "a",
              {
                staticClass: "proposal__link nobr",
                attrs: {
                  href: "pdf/Autumn_guidance_note.pdf",
                  target: "_blank"
                }
              },
              [
                _vm._v("guidance note "),
                _c("i", { staticClass: "icon--form-link" })
              ]
            ),
            _vm._v(
              " which explains the proposal submission process in more detail\n                "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "proposal__intro__list-item" }, [
            _vm._v("\n                    Together First's "),
            _c(
              "a",
              {
                staticClass: "proposal__link nobr",
                attrs: {
                  href: "pdf/Together-First-values-statement.pdf",
                  target: "_blank"
                }
              },
              [
                _vm._v("Statement of Values "),
                _c("i", { staticClass: "icon--form-link" })
              ]
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "proposal__intro__list-item" }, [
            _c(
              "a",
              {
                staticClass: "proposal__link nobr",
                attrs: {
                  href:
                    "pdf/Together_First_Report_V7_(How to save the world).pdf",
                  target: "_blank"
                }
              },
              [
                _vm._v('"How to save the world" '),
                _c("i", { staticClass: "icon--form-link" })
              ]
            ),
            _vm._v(
              " a report commisioned by Together First on barriers to effective reform\n                "
            )
          ])
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "proposal__intro__text" }, [
          _vm._v(
            "\n                For ideas for the sort of reforms your proposal could champion, please see:\n            "
          )
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "proposal__intro__list" }, [
          _c("li", { staticClass: "proposal__intro__list-item" }, [
            _vm._v("\n                    Our non-exhaustive "),
            _c(
              "a",
              {
                staticClass: "proposal__link nobr",
                attrs: {
                  href: "pdf/Together First consultation - reference doc.pdf",
                  target: "_blank"
                }
              },
              [
                _vm._v("\n                        online reference list "),
                _c("i", { staticClass: "icon--form-link" })
              ]
            ),
            _vm._v(" of previous reform recommendations\n                ")
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "proposal__intro__list-item" }, [
            _vm._v("\n                    The Global Challenges Foundation's "),
            _c(
              "a",
              {
                staticClass: "proposal__link nobr",
                attrs: {
                  href: "https://globalchallenges.org/new-shape-library?page=1",
                  target: "_blank"
                }
              },
              [
                _vm._v('"New Shape Library" '),
                _c("i", { staticClass: "icon--form-link" })
              ]
            ),
            _vm._v(" of global governance reforms\n                ")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "proposal__intro__tip" }, [
      _c("span", [_vm._v("i")]),
      _vm._v(
        "You can choose to submit your proposal anonymously, for example if you wish\n                to not be credited as an author, when entering your personal information\n            "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }