var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "filters-section__filters" }, [
      _c("div", { staticClass: "container" }, [
        _c("form", { staticClass: "filter-form", attrs: { action: "#" } }, [
          _c(
            "div",
            { staticClass: "filter-field filter-selectbox" },
            [
              _c("multiselect", {
                attrs: {
                  options: _vm.filterContent.categories,
                  multiple: false,
                  placeholder: "Category",
                  "track-by": "value",
                  label: "label"
                },
                model: {
                  value: _vm.filter.category,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "category", $$v)
                  },
                  expression: "filter.category"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-field filter-selectbox" },
            [
              _c("multiselect", {
                attrs: {
                  options: _vm.filterContent.times,
                  multiple: false,
                  placeholder: "Time",
                  "track-by": "value",
                  label: "label"
                },
                model: {
                  value: _vm.filter.time,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "time", $$v)
                  },
                  expression: "filter.time"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "filter-field filter-submit" }, [
            _c(
              "button",
              {
                staticClass: "btn btn--orange btn--full-width",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.explore($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n                        Explore solutions\n                    "
                )
              ]
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.isSmall
      ? _c("div", { staticClass: "filter-form__controls" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "filter-form__controls-inner" }, [
              _vm.resultTotal
                ? _c("div", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.resultTotal) +
                        " results found for "
                    ),
                    _c("span", { staticClass: "text--black text--lowercase" }, [
                      _vm._v(
                        _vm._s(_vm.initial.category.label || "all categories")
                      )
                    ]),
                    _vm._v(" from "),
                    _c("span", { staticClass: "text--black text--lowercase" }, [
                      _vm._v(_vm._s(_vm.initial.time.label || "all"))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "filter-form__clear",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.clearFilter($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                    Clear filters\n                "
                  )
                ]
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }