import Vue from 'vue';
import Router from 'vue-router';
import ProposalIntro from '../components/ProposalIntro.vue';
import ProposalForm from '../components/ProposalForm.vue';
import AboutYou from '../components/blocks/AboutYou.vue';
import Proposal from '../components/blocks/Proposal.vue';
import ProposalCategorisation from '../components/blocks/ProposalCategorisation.vue';
import ProposalInDetail from '../components/blocks/ProposalInDetail.vue';
import WiderImplications from '../components/blocks/WiderImplications.vue';
import MitigatingRisks from '../components/blocks/MitigatingRisks.vue';
import TheoryOfChange from '../components/blocks/TheoryOfChange.vue';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.APP_URL,
    routes: [
        {
            path: '/proposal',
            name: 'introduction',
            component: ProposalIntro
        },
        {
            path: '/proposal/form',
            name: 'form',
            component: ProposalForm,
            children: [
                {
                    path: 'about-you',
                    name: 'about-you',
                    component: AboutYou
                },
                {
                    path: 'proposal',
                    name: 'proposal',
                    component: Proposal
                },
                {
                    path: 'proposal-categorisation',
                    name: 'proposal-categorisation',
                    component: ProposalCategorisation
                },
                {
                    path: 'proposal-in-detail',
                    name: 'proposal-in-detail',
                    component: ProposalInDetail
                },
                {
                    path: 'wider-implications',
                    name: 'wider-implications',
                    component: WiderImplications
                },
                {
                    path: 'mitigating-risks',
                    name: 'mitigating-risks',
                    component: MitigatingRisks
                },
                {
                    path: 'theory-of-change',
                    name: 'theory-of-change',
                    component: TheoryOfChange
                },
            ]
        },
    ],
});

router.afterEach((to, from) => {
    window.scrollTo(0, 0);
});

export default router;
