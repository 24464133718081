//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArrowRight from './assets/ArrowRight.vue';
import TextareaBlock from './elements/TextareaBlock.vue';

export default {
    name: 'TheoryOfChange',
    components: {
        'arrow-right': ArrowRight,
        'textarea-block': TextareaBlock
    },
    data(){
        return {
            invalid_ims: false,
            invalid_pw: false,
            invalid_pwn: false,
            invalid_rm: false,
            invalid_wlt: false,
            invalid_dm: false,
        };
    },
    computed:{
        timeFrameShort() {
            let stateShort = this.$store.state.term;
            if (stateShort === 'short'){
                return true;
            } else {
                return false;
            }
        },
        timeFrameMedium() {
            let stateMedium = this.$store.state.term;
            if (stateMedium === 'medium'){
                return true;
            } else {
                return false;
            }
        },
        timeFrameLong() {
            let stateLong = this.$store.state.term;
            if (stateLong === 'long'){
                return true;
            } else {
                return false;
            }
        },
        prevSection(){
            if (this.$store.state.proposalCategorisation.isCompleted === false){
                return false;
            } else {
                return true;
            }
        },
        implementationStrategy: {
            get() {
                return this.$store.state.theory_of_change.implementation_strategy;
            },
            set(value) {
                this.$store.commit('TOC_IMPLEMENTATION_STRATEGY', value);
            }
        },
        politicalWill: {
            get() {
                return this.$store.state.theory_of_change.political_will;
            },
            set(value) {
                this.$store.commit('TOC_POLITICAL_WILL', value);
            }
        },
        politicalWillNot: {
            get() {
                return this.$store.state.theory_of_change.political_will_does_not_exist;
            },
            set(value) {
                this.$store.commit('TOC_POLITICAL_WILL__NOT', value);
            }
        },
        roadMaps: {
            get() {
                return this.$store.state.theory_of_change.existing_road_maps;
            },
            set(value) {
                this.$store.commit('TOC_ROAD_MAPS', value);
            }
        },
        decisionMakers: {
            get() {
                return this.$store.state.theory_of_change.decision_makers;
            },
            set(value) {
                this.$store.commit('TOC_DECISION_MAKERS', value);
            }
        },
        whyLongTerms: {
            get() {
                return this.$store.state.theory_of_change.why_long_terms;
            },
            set(value) {
                this.$store.commit('TOC_WHY_LONG_TERM', value);
            }
        },
        isDisabledShortTerm(){
            let shortTermState = this.$store.state.theory_of_change;
            if (shortTermState.implementation_strategy === ''){
                return true;
            } else if ( shortTermState.political_will === '') {
                return true;
            } else if ( shortTermState.political_will_does_not_exist === ''){
                return true;
            } else {
                return false;
            }
        },
        isDisabledMidTerm(){
            let midTermState = this.$store.state.theory_of_change;
            if (midTermState.implementation_strategy === ''){
                return true;
            } else if ( midTermState.existing_road_maps === '') {
                return true;
            } else if ( midTermState.decision_makers === ''){
                return true;
            } else {
                return false;
            }
        },
        isDisabledLongTerm() {
            let longTermState = this.$store.state.theory_of_change;
            if (longTermState.implementation_strategy === ''){
                return true;
            } else if ( longTermState.why_long_terms === '') {
                return true;
            } else if ( longTermState.decision_makers === ''){
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        completeTheoryOfChange(){
            if (this.isDisabledShortTerm === false){
                this.$store.commit('THEORY_OF_CHANGE_COMPLETE', true);
                this.$router.push('/proposal/form/mitigating-risks');
            }
            if (this.isDisabledMidTerm === false){
                this.$store.commit('THEORY_OF_CHANGE_COMPLETE', true);
                this.$router.push('/proposal/form/mitigating-risks');
            }
            if (this.isDisabledLongTerm === false){
                this.$store.commit('THEORY_OF_CHANGE_COMPLETE', true);
                this.$router.push('/proposal/form/mitigating-risks');
            }
        },
    }
};
