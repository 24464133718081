var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "proposal__wrap" },
    [
      _c(
        "div",
        {
          staticClass: "proposal__column-one dropdown-mobile",
          class: { "dropdown-active": _vm.drop_down }
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mobile-only", on: { click: _vm.dropdownMenu } },
            [_c("span", { staticClass: "icon--arrow--down" })]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "proposal__progress-bar" },
            [
              _c(
                "router-link",
                {
                  staticClass: "proposal__progress-bar-item",
                  class: {
                    done: _vm.state.aboutYou.isCompleted
                  },
                  attrs: { to: "/proposal/form/about-you" }
                },
                [
                  _c(
                    "span",
                    { staticClass: "progress-check" },
                    [_c("check")],
                    1
                  ),
                  _vm._v("About you\n            ")
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "proposal__progress-bar-item",
                  class: {
                    done: _vm.state.proposal.isCompleted
                  },
                  attrs: { to: "/proposal/form/proposal" }
                },
                [
                  _c(
                    "span",
                    { staticClass: "progress-check" },
                    [_c("check")],
                    1
                  ),
                  _vm._v("\n                Your proposal\n            ")
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "proposal__progress-bar-item",
                  class: {
                    error: _vm.empty_state === false,
                    done: _vm.state.proposalCategorisation.isCompleted
                  },
                  attrs: { to: "/proposal/form/proposal-categorisation" }
                },
                [
                  _c(
                    "span",
                    { staticClass: "progress-check" },
                    [_c("check")],
                    1
                  ),
                  _vm._v(
                    "\n                Proposal categorisation\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "proposal__progress-bar-item",
                  class: {
                    error: _vm.empty_state === false,
                    done: _vm.state.theoryOfChange.isCompleted
                  },
                  attrs: { to: "/proposal/form/theory-of-change" }
                },
                [
                  _c(
                    "span",
                    { staticClass: "progress-check" },
                    [_c("check")],
                    1
                  ),
                  _vm._v("\n                Theory of change\n            ")
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "proposal__progress-bar-item",
                  class: {
                    error: _vm.empty_state === false,
                    done: _vm.state.mitigatingRisks.isCompleted
                  },
                  attrs: { to: "/proposal/form/mitigating-risks" }
                },
                [
                  _c(
                    "span",
                    { staticClass: "progress-check" },
                    [_c("check")],
                    1
                  ),
                  _vm._v(
                    "\n                Mitigating global catastrophic risks\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "proposal__progress-bar-item",
                  class: [
                    _vm.state.widerImplications.isCompleted ? "done" : "error"
                  ],
                  attrs: { to: "/proposal/form/wider-implications" }
                },
                [
                  _c(
                    "span",
                    { staticClass: "progress-check" },
                    [_c("check")],
                    1
                  ),
                  _vm._v("\n                Wider implications\n            ")
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "proposal__progress-bar-item",
                  class: {
                    done: _vm.state.proposalInDetail.isCompleted
                  },
                  attrs: { to: "/proposal/form/proposal-in-detail" }
                },
                [
                  _c(
                    "span",
                    { staticClass: "progress-check" },
                    [_c("check")],
                    1
                  ),
                  _vm._v(
                    "\n                Your proposal in detail\n            "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "proposal__save" }, [
            _c(
              "button",
              {
                staticClass: "proposal__save__btn",
                on: {
                  click: function($event) {
                    return _vm.saveAndClose()
                  }
                }
              },
              [
                _c("arrow-right"),
                _vm._v("\n                Save and close\n            ")
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "proposal__column-two" },
        [_c("keep-alive", [_c(_vm.routerName, { tag: "component" })], 1)],
        1
      ),
      _vm._v(" "),
      _c("save-and-close-popup", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.$store.state.isPopupVisible,
            expression: "$store.state.isPopupVisible"
          }
        ]
      }),
      _vm._v(" "),
      _c("submit-success", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.$store.state.isFormSubmitted,
            expression: "$store.state.isFormSubmitted"
          }
        ]
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "proposal__form-logo", attrs: { href: "/" } },
      [_c("span", { staticClass: "icon--form-logo" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }