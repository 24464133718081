var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "proposal__popup" }, [
    _c("div", { staticClass: "proposal__popup__content" }, [
      _c("h4", { staticClass: "proposal__popup__heading" }, [
        _vm._v("\n            Saving your submission\n        ")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "proposal__popup__text" }, [
        _vm._v(
          "\n            Your submission is linked to the following email address\n        "
        )
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "proposal__popup__email",
        attrs: { id: "popup-email-change", type: "text" },
        domProps: { value: _vm.userEmail }
      }),
      _vm._v(" "),
      _c("p", { staticClass: "proposal__popup__text" }, [
        _vm._v(
          "\n            You will need this email in order to return to where you left.\n        "
        )
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "proposal__popup__save",
          on: {
            click: function($event) {
              return _vm.savePopup()
            }
          }
        },
        [_vm._v("\n            Save and close\n        ")]
      ),
      _vm._v(" "),
      _vm._m(0)
    ]),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.submit_in_progress,
          expression: "submit_in_progress"
        }
      ],
      staticClass: "overlay"
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "proposal__popup__text" }, [
      _vm._v("\n            or "),
      _c(
        "label",
        { staticClass: "link-like", attrs: { for: "popup-email-change" } },
        [_vm._v("change")]
      ),
      _vm._v(" email address\n        ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }