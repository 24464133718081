//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArrowRight from './assets/ArrowRight.vue';
export default {
    name: 'AboutYou',
    components: {
        'arrow-right': ArrowRight,
    },
    data(){
        return {
            about_you_step_one: true,
            about_you_step_two: false,
            invalid_first_name: false,
            invalid_last_name: false,
            invalid_organisation_name: false
        };
    },
    computed: {
        firstName: {
            get() {
                return this.$store.state.first_name;
            },
            set(value) {
                this.$store.commit('UPDATE_FIRST_NAME', value);
            }
        },
        middleNames: {
            get() {
                return this.$store.state.middle_name;
            },
            set(value) {
                this.$store.commit('UPDATE_MIDDLE_NAMES', value);
            }
        },
        lastName: {
            get() {
                return this.$store.state.last_name;
            },
            set(value) {
                this.$store.commit('UPDATE_LAST_NAME', value);
            }
        },
        authorCredit: {
            get() {
                return this.$store.state.is_anonymous;
            },
            set(value) {
                this.$store.commit('AUTHOR_CREDIT', value);
            }
        },
        onBehalfValue: {
            get() {
                return this.$store.state.is_organisation;
            },
            set(value) {
                this.$store.commit('ON_BEHALF_STATE', value);
            }
        },
        organisationName: {
            get() {
                return this.$store.state.organisation_name;
            },
            set(value) {
                this.$store.commit('ORGANISATION_NAME', value);
            }
        },
        isDisabledOne() {
            if ((this.$store.state.first_name !== '' && this.$store.state.last_name !== '')
            && (this.$store.state.is_anonymous === false)){
                return false;
            } else if (this.$store.state.is_anonymous === true) {
                return false;
            } else {
                return true;
            }
        },
        isDisabledTwo() {
            if (this.$store.state.is_organisation === false){
                return false;
            } else if (this.$store.state.is_organisation === true && this.$store.state.organisation_name === ''){
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        continueAbout() {
            if (this.isDisabledOne === false){
                this.about_you_step_one = false;
                this.about_you_step_two = true;
            } else {
                if (this.$store.state.first_name === ''){
                    this.invalid_first_name = true;
                }
                if (this.$store.state.last_name === ''){
                    this.invalid_last_name = true;
                }
            }
        },
        completeAbout(){
            if (this.isDisabledTwo === false){
                this.$store.commit('ABOUT_YOU_COMPLETE', true);
                this.$router.push('/proposal/form/proposal');
            } else {
                if (this.$store.state.organisation_name === ''){
                    this.invalid_organisation_name = true;
                }
            }
        }
    },
};
