var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "10.308",
        height: "7.465"
      }
    },
    [
      _c("rect", {
        attrs: {
          id: "backgroundrect",
          width: "100%",
          height: "100%",
          x: "0",
          y: "0",
          fill: "none",
          stroke: "none"
        }
      }),
      _vm._v(" "),
      _c("g", { staticClass: "currentLayer" }, [
        _c("title", [_vm._v("Layer 1")]),
        _c("path", {
          staticClass: "selected",
          attrs: {
            id: "Path_Copy_5",
            "data-name": "Path Copy 5",
            d:
              "M.262,3.024a.926.926,0,0,1,1.325,0L3.773,5.209,8.7.279a.926.926,0,0,1,1.325,0,.926.926,0,0,1,0,1.325L4.447,7.186a.9.9,0,0,1-1.3,0L.262,4.349a.97.97,0,0,1,0-1.325Z",
            fill: "#ffffff",
            "fill-opacity": "1"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }