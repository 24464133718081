//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios';

export default {
    name: 'AlphabeticList',
    data() {
        return {
            transformed_array: []
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData(){
            axios.get('/api/join-us')
                .then(({ data }) => {
                    let dataArray = [];
                    for (let i in data.data.organisations) {
                        dataArray.push(data.data.organisations[i].organisation_name);
                    }
                    this.sortArray(dataArray);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        sortArray(raw_array){
            if (raw_array.length === 0) {
                return [];
            }
            let array_in_order = Object.values(
                raw_array.reduce((acc, word) => {
                    let firstLetter = word[0].toLocaleUpperCase();
                    if (!acc[firstLetter]) {
                        acc[firstLetter] = {title: firstLetter, data: [word]};
                    } else {
                        acc[firstLetter].data.push(word);
                    }
                    return acc;
                }, {})
            );
            array_in_order.forEach((item)=>{
                this.transformed_array.push(item);
            });
            this.transformed_array.sort((a,b)=>(a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));
        }
    },
};
