//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'SearchForm',
    methods: {
        openModal() {
            this.$bus.$emit('open-search');
        }
    }
};
