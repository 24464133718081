var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      _vm._l(_vm.local_proposals, function(item, id) {
        return _c("solution-item", { key: id, attrs: { item: item } })
      }),
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "text-center mt-sm-5" }, [
      _vm.hasMore
        ? _c(
            "button",
            {
              staticClass: "btn btn--orange",
              attrs: { type: "button" },
              on: { click: _vm.loadMore }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.loading ? "loading" : "Show more") +
                  "\n        "
              )
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }